import { React, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreaters } from '../State';

import SiteHeader from '../components/SiteHeader';
import Sitefooter from '../components/footer';

import TopIndicator1 from '../images/donation/indicator-1.svg';
import '../App.css';
import useDocumentTitle from '../components/useDocumentTitle';

export default function MySubscriptionPlan() {
    useDocumentTitle('My Subscription Plan');

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { sub_amount, sub_period, sub_mode, sub_o_amount } = useSelector(state => state.subscription);
    const { SetSubscriptionAmount, SetSubscriptionPeriod, SetSubscriptionMode } = bindActionCreators(actionCreaters, dispatch)

    console.log("sub_o_amount:", sub_o_amount);
    const [isoneoff, setIsoneoff] = useState(false);

    useEffect(() => {
        if(sub_o_amount > 0 && sub_o_amount != sub_amount){
            SetSubscriptionAmount(sub_o_amount);
        }
    }, [sub_o_amount]);

    function handleAmount(amount) {
        SetSubscriptionAmount(amount);
    }

    function handleInputAmount(event) {
        handleAmount(event.target.value);
        // if (event.target.value < 3) {
        //     handleAmount(3);
        //     event.preventDefault();
        //     alert("select Amount minimum £3")
        // }
    }

    useEffect(() => {
        if (isoneoff) {
            SetSubscriptionPeriod("One-Off");
        } else {
            SetSubscriptionPeriod("Monthly");
        }
    }, [isoneoff]);

    return (
        <>
            <SiteHeader />
            <section className="hero-section herosec-img-support font-face-sh" style={{ marginTop: '-160px', padding: '170px 0 40px' }}>
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-sm-12 col-md-5 text-center mx-auto'>
                            <img className='w-100' src={TopIndicator1} alt="top-Indicator" />
                        </div>
                        <div className='row text-center align-items-center justify-content-center mx-0 my-4 px-0'>
                            <div className='col-lg-5 col-md-9 col-12 bg-light rounded-3 p-4 text-center'>
                                <div className={'tab-1'}>
                                    <h2 className='font-semibold fs-4' >{sub_mode == "new" ? 'Select' : "Edit"} Your Plan</h2>
                                    <div className="tab-content text-center px-md-4">
                                        <h2 id="big-number " className='branding-4 font-regular'>
                                            <span>
                                                <span className="pound-sign position-relative" style={{ bottom: 30 }}>£</span>
                                                <span id="number" className='display-1'>{sub_amount}</span>
                                            </span>
                                        </h2>
                                        <div className="tab-pane active show" id="monthly" role="tabpanel">
                                            <p><strong>{isoneoff ? "Paid One-Off" : "Paid Monthly"}</strong></p>
                                        </div>
                                        <fieldset id="selected-amount">
                                            <div className="row mb-3 mt-4">
                                                <div className="col-4 pr-1">
                                                    <button id="ticket_option_3" className="w-100 border-0 ticket_option py-2 fs-3 font-regular text-center" style={sub_amount == '3' ? { backgroundColor: '#FFA300', color: '#fff' } : {}} onClick={() => handleAmount(3)}>
                                                        <div>
                                                            <span>£</span>3<span className='fs-6'>{isoneoff ? "" : "/month"}</span>
                                                        </div>
                                                    </button>
                                                </div>
                                                <div className="col-4 px-2">
                                                    <button id="ticket_option_6" className="w-100 border-0 ticket_option py-2 fs-3 font-regular text-center" style={sub_amount == '6' ? { backgroundColor: '#FFA300', color: '#fff' } : {}} onClick={() => handleAmount(6)}>
                                                        <span>£</span>6<span className='fs-6'>{isoneoff ? "" : "/month"}</span>
                                                    </button>
                                                </div>
                                                <div className="col-4 pl-1">
                                                    <button id="ticket_option_12" className="w-100 border-0 ticket_option py-2 fs-3 font-regular text-center" style={sub_amount == '12' ? { backgroundColor: '#FFA300', color: '#fff' } : {}} onClick={() => handleAmount(12)}><span>£</span>12<span className='fs-6'>{isoneoff ? "" : "/month"}</span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="row mb-5 justify-content-end">
                                                <div className="col-4 pr-2">
                                                    <button id="ticket_option_10" className="w-100 border-0 ticket_option py-2 fs-3 font-regular text-center" style={sub_amount == '20' ? { backgroundColor: '#FFA300', color: '#fff' } : {}} onClick={() => handleAmount(20)}><span>£</span>20<span className='fs-6'>{isoneoff ? "" : "/month"}</span></button>
                                                </div>
                                                <div className="col-8 dark-grey pl-2 position-relative " id="custom-input">
                                                    <input onChange={handleInputAmount} type="number" min='3' id="amount-input" className='w-100 ps-5 py-1 fs-2 font-regular' name="custom-amount" value={sub_amount} />
                                                    <p className='position-absolute fs-4 font-regular' style={{ top: 13, left: 33 }}>£</p>
                                                </div>
                                                <div className="col-12 pl-3 form-errors error d-none">
                                                    <p className="error-msg text-start">Please enter an amount between 3 and 100,000</p>
                                                </div>
                                            </div>
                                            {
                                                sub_mode != "edit" &&
                                                <div className="row mb-5 justify-content-end">
                                                    <div className="col-12">
                                                        <div className="form-check form-switch d-flex justify-content-center">
                                                            <input checked={isoneoff} onChange={() => setIsoneoff(!isoneoff)} className="form-check-input me-2" type="checkbox" id="flexSwitchCheckDefault" />
                                                            <label className="form-check-label text-black" htmlFor="flexSwitchCheckDefault">{!isoneoff ? "Tick to make a one-off entry into the next draw" : "untick to make a Subscription entry into the next draw"}</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 pl-3 form-errors error d-none">
                                                        <p className="error-msg text-start">Please enter an amount between 3 and 100,000</p>
                                                    </div>
                                                </div>
                                            }

                                            {
                                                sub_mode == "edit" && sub_o_amount == sub_amount ?
                                                    <>
                                                        <a href='#' className='disabled d-block w-100 text-white text-decoration-none fs-6 font-regular site-button-primary py-3 px-5 text-decoration-none rounded-3'
                                                            style={{ background: "gray" }}> {sub_mode == "edit" ? "Update" : ""} {isoneoff ? "Pay" : "Subscribe"} now</a>
                                                    </> :
                                                    <Link onClick={(event)=>{
                                                        event.preventDefault();
                                                        console.log("set sub_amount:", sub_amount);
                                                        if(sub_amount < 3){
                                                            alert("Please enter an amount between 3 and 100,000");
                                                            return;
                                                        }
                                                        navigate('/account/my-subscription-register');
                                                    }
                                                    } className='d-block w-100 text-white text-decoration-none fs-6 font-regular site-button-primary py-3 px-5 text-decoration-none rounded-3'> {sub_mode == "edit" ? "Update" : ""} {isoneoff ? "Pay" : "Subscribe"} now</Link>
                                            }
                                            {
                                                sub_mode == "edit" && sub_o_amount < sub_amount &&
                                                <div className="col-12 text-center">
                                                    <p className="error-msg">Update subscribe will take effect immediately (increase)</p>
                                                </div>
                                            }
                                            {
                                                sub_mode == "edit" && sub_o_amount > sub_amount &&
                                                <div className="col-12 text-center">
                                                    <p className="error-msg">Update subscribe will take effect in the next billing(decrease)</p>
                                                </div>
                                            }
                                            {
                                                sub_mode == "edit" && sub_o_amount == sub_amount &&
                                                <div className="col-12 text-center">
                                                    <p className="error-msg">Please edit your plan amount. </p>
                                                </div>
                                            }

                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Sitefooter />
        </>
    )
}

