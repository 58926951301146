import React from 'react'
import { Link } from 'react-router-dom'
import '../App.css'
import { useEffect } from 'react'
import SiteHeader from '../components/SiteHeader';
import Sitefooter from '../components/footer';
import useDocumentTitle from '../components/useDocumentTitle';

export default function TermsConditions() {
    useDocumentTitle('Terms & Conditions');
    useEffect(() => {
        document.body.classList.add('bg-branding-2');
        return () => {
            document.body.classList.remove('bg-branding-2')
        }
    }, [])
    return (
        <>
            <SiteHeader />
            <section className="hero-section tc bg-branding-2 font-face-sh" style={{ marginTop: '-110px', padding: '120px 0 20px' }}>

                <div className="container">
                    <h1 className="mb-5" style={{ textAlign: 'left', fontSize: '2.1rem', lineHeight: 1, color: 'white' }}><span><strong>Terms of Service</strong></span></h1>
                    <h4 style={{ color: 'white' }}><strong>How your donation is applied</strong></h4>
                    <p><span style={{ fontWeight: 400 }}>As parkrun continues to expand at an unprecedented rate, so too do the costs involved. We face a constant challenge to ensure that parkrun has the financial security to maintain our commitment to delivering free, weekly events for anyone who wants to take part – forever. Your monthly donations will create a new and sustainable revenue stream that will help support the continued successful operation of parkrun. </span><strong>&nbsp;<br /><br /></strong></p>
                    <h4 className="text-white"><strong>Gift Aid terms</strong></h4>
                    <p><span style={{ fontWeight: 400 }}>Gift Aid allows parkrun Global (a registered charity) to reclaim the basic tax rate on donations received from UK taxpayers. For your donation to qualify for Gift Aid, you must be a registered UK taxpayer.</span></p>
                    <p><span style={{ fontWeight: 400 }}>You must pay enough Income Tax or Capital Gains Tax in the same tax year to at least equal the amount of tax that parkrun will reclaim from Her Majesty’s Revenue and Customs (HMRC) in respect of your donations.</span></p>
                    <p><span style={{ fontWeight: 400 }}>A single gift aid declaration can cover multiple monthly donations from you in the same year. You can declare for Gift Aid when you register, or from your account section at a later date if you’re not sure if you qualify upon registration.</span></p>
                    <p><span style={{ fontWeight: 400 }}>For example, parkrun will claim 25p for every £1 donated through Gift Aid. If you donate £100 pounds, parkrun can claim back an extra £25. What’s important is that the total amount of tax you pay in the tax year, is at least equal to the amount of tax parkrun will claim from your Gift Aid declaration. So, if you were to donate £100 throughout the year, you must also have paid at least £25 in tax for your donation to qualify for Gift Aid.</span></p>
                    <p><span style={{ fontWeight: 400 }}>If you pay less Income Tax / or Capital Gains tax in the current tax year than the amount of Gift Aid claimed on all of your donations it is your responsibility to pay any difference.<br /><br /> </span></p>
                    <h4 className="text-white"><strong>Donations</strong></h4>
                    <p><span style={{ fontWeight: 400 }}>If you believe a donation has been fraudulently applied against your payment card, please contact customer service by emailing hello@playfundwin.com. Upon providing acceptable proof a fraudulent payment, your payment card will be refunded. All donations greater than £50 may be considered eligible for refunds, please contact customer support if you wish to dispute a donation. Donations made by those who have chosen to enter the Thank You Draw online are not eligible for refunds, irrespective of donation amount.</span></p>
                    <p>&nbsp;</p>
                    <h4 className="text-white"><strong>User accounts</strong></h4>
                    <p><span style={{ fontWeight: 400 }}>As parkrun Forever supporters, you are responsible for maintaining the confidentiality of your password to prevent others gaining access to your personal information. This includes any taxpayer details you may have saved in your account in order to claim Gift Aid. Having multiple parkrun Forever accounts goes against these Terms of Service, and claiming Gift Aid using someone else’s taxpayer information is unlawful.<br /><br /></span></p>
                    <h4 className="text-white"><strong>Professional fundraising, technology support &amp; fees</strong></h4>
                    <p><span style={{ fontWeight: 400 }}>parkrun Global (Registered charity no. 1175062) has enlisted the professional fundraising services of PFW Holdings Ltd. to help deliver and support the continued operation of the parkrun Forever platform. In accordance with providing fundraising services, and depending what donation method is used, PFW Holdings Ltd. will receive roughly 10% of aggregate donations after payment processing fees &amp; the costs incurred to host the parkrun forever platform.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{ fontWeight: 400 }}>The fundraising services provided are as follows:</span></p>
                    <ul>
                        <li><span style={{ fontWeight: 400 }}> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Customer support and Thank You Draw administration</span></li>
                        <li><span style={{ fontWeight: 400 }}> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Technology build of parkrun Forever platform, constant improvement &amp; maintenance</span></li>
                        <li><span style={{ fontWeight: 400 }}> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Marketing and distribution support for parkrun Forever</span></li>
                        <li><span style={{ fontWeight: 400 }}> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Donation processing, administration and record keeping</span></li>
                    </ul>
                    <h4 className="text-white"><strong>Copyright</strong></h4>
                    <p><span style={{ fontWeight: 400 }}>All content contained on the parkrun Forever website, and associated materials like emails and marketing materials is owned by parkrun and our partners. Any copying and/or distribution of any content found on the parkrun Forever website or associated materials of any kind is forbidden.<br /><br /></span></p>
                    <h4 className="text-white"><strong>Liability</strong></h4>
                    <p><span style={{ fontWeight: 400 }}>You agree that your use of the parkrun Forever website and its associated services is on an "as is" and "as available" basis and that your use of the parkrun Forever website and its associated services is at your sole risk. parkrun Forever does not guarantee continuous uninterrupted or secure access to our services and operation of the website and associated services may be interfered with by numerous factors outside of our control.</span></p>
                    <p><span style={{ fontWeight: 400 }}>On that basis, except as expressly set out in these Terms of Service and except for the rights, guarantees and remedies which cannot be excluded, parkrun and its partners do not provide other conditions, guarantees, warranties or terms in relation to the website or its associated services, to the extent permissible by law.</span></p>
                    <p><span style={{ fontWeight: 400 }}>Any liability parkrun may have to you shall not include losses relating to any business of yours or your own losses such as lost or corrupt data, loss of profits, loss of contracts, loss of business opportunity, loss of sales, loss of revenue, loss of goodwill, loss of any software or data, loss of bargain, loss of opportunity, loss of use of computer equipment, loss of or waste of management or other staff time</span></p>
                    <p><strong>&nbsp;</strong></p>
                    <h3 className="text-white"><span><strong>Thank You Draw rules</strong></span></h3>
                    <p><span style={{ fontWeight: 400 }}>The parkrun Forever Thank You Draw is a no purchase necessary prize draw, and our way of saying thank you for not only donating, but being part of the parkrun community.<br /><br /></span></p>
                    <h4 className="text-white"><strong>How it works</strong></h4>
                    <p><span style={{ fontWeight: 400 }}>The Thank You Draw is a monthly prize draw where parkrunners have the chance to win outstanding parkrun related prizes. These prizes range from parkrun kit, to partner merchandise, to once-in-a-lifetime parkrun Forever travel experiences.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{ fontWeight: 400 }}>Prizes vary from month to month, so make sure to check the parkrun Forever homepage for the latest prizes up for grabs.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{ fontWeight: 400 }}>All of those wishing to enter will be given three (3) entries, regardless of donation size, to show our gratitude for supporting the parkrun Forever mission. &nbsp;<br /><br /></span></p>
                    <h4 className="text-white"><strong>Draw frequency</strong></h4>
                    <p><span style={{ fontWeight: 400 }}>Prize winners are chosen on the last day of every month, so keep your eye on your inbox, as we will email all the Thank You Draw winners. &nbsp;</span></p>
                    <p><span style={{ fontWeight: 400 }}>If you apply to enter between the closing of a draw, and the opening of a new draw, your entries will count towards the next pending draw.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{ fontWeight: 400 }}>Online entry numbers will be issued on the first day of the month.<br /><br /></span></p>
                    <h4 className="text-white"><strong>How to enter</strong></h4>
                    <p><strong><em>Online</em></strong></p>
                    <p><span style={{ fontWeight: 400 }}>Convenient, automatic entry to the monthly Thank You Draw is provided alongside your recurring donation. Please contact customer support at (hello@playfundwin.com) if you wish to opt-out of either the Thank You Draw, or your donation plan.</span></p>
                    <p>&nbsp;</p>
                    <p><span style={{ fontWeight: 400 }}>You can continue to donate if you elect not to entered into the Thank You Draw.<br /><br /></span></p>
                    <p><strong><em>Post</em></strong></p>
                    <p><span style={{ fontWeight: 400 }}>If you wish to enter by post please see required entry information&nbsp;<Link to={"/support/"}>here.</Link></span></p>
                    <p><span style={{ fontWeight: 400 }}>All entry routes are given equal weighting (3 entries) and have an equal chance to win.</span></p>
                    <p>&nbsp;</p>
                    <h4 className="text-white"><strong>How Thank You Draw winners are selected</strong></h4>
                    <p><span style={{ fontWeight: 400 }}>To keep things fair winners are chosen by a certified random number generator.</span></p>
                    <p>&nbsp;</p>
                    <h4 className="text-white"><strong>Who can win a prize</strong></h4>
                    <p><span style={{ fontWeight: 400 }}>In order to win a prize, entrants must be resident of the United Kingdom and over the age of 18 years.</span></p>
                    <p>&nbsp;</p>
                    <h4 className="text-white"><strong>What happens if I win?</strong></h4>
                    <p><span style={{ fontWeight: 400 }}>All Thank You Draw winners will be contacted by email with instructions on how to claim their prize.</span></p>
                    <p><span style={{ fontWeight: 400 }}>Prizes are non-transferable and cannot be substituted.<br /><br /></span></p>
                    <p><span style={{ fontWeight: 400 }}>Certain prizes may come with additional conditions, like our parkrun Forever travel experiences. The terms related to travel experiences will be posted in the months in which they are available, however as a general rule all travelers are expected to be legally able to travel to any destination. Travel restrictions and limitations may be applicable, and awarding a prize is ultimately the discretion of parkrun Forever and its partners. Acts of god, extraordinary circumstances, or unforeseeable acts by third parties which limit the ability to deliver prizes are not the responsibility of parkrun, PFW Holdings Ltd., or any partners of parkrun and parkrun Forever.<br /><br /></span></p>
                    <p><span style={{ fontWeight: 400 }}>You agree to waive all of your rights to bring any claim, action, or proceeding against any of parkrun Forever or its partners,in connection with the Thank You Draw, and to forever and irrevocably agree to release, defend, indemnify, and hold harmless parkrun Forever and its partners from any and all claims, lawsuits, judgments, causes of action, proceedings, fines, liability costs and expenses that may arise in connection with your participation in the Thank You Draw, or claims around any prizes associated with the Thank You Draw.</span></p>
                </div>


            </section>





            <Sitefooter/>

        </>
    )
}

