import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import SiteHeader from '../components/SiteHeader';
import Sitefooter from '../components/footer';
import RecdonationAccountBanner from '../components/recdonation-account-banner';
import { useLocation } from 'react-router-dom'
import axios from 'axios';
import useDocumentTitle from '../components/useDocumentTitle';
import secureLocalStorage from 'react-secure-storage';
import { useNavigate } from 'react-router-dom';
import RecdonationAccountSupport from '../components/recdonation-account-support';

export default function MyDonationPlan() {
    useDocumentTitle('My Donation Plan');
    const location = useLocation()
    const [isLoaded, setLoadComplete] = useState('');
    const [name, SetName] = useState(location.state ? location.state.name : '')
    const [entrydata, SetEntryData] = useState(location.state ? location.state.entrydata : '')
    const [allData, SetAllData] = useState(location.state ? location.state.allData : '')
    const [merge, Setmerge] = useState([]);
    const navigate = useNavigate()
    let userDetail = secureLocalStorage.getItem('LogObject');
    let siteID = process.env.REACT_APP_SITE_ID;


    useEffect(() => {
        if (userDetail === null) {
            navigate('/login');
        } else {
            if (allData === '' || entrydata === '') {
                navigate('/account/my-donation-entries');


                // console.log(userDetail)

                axios.get(`${process.env.REACT_APP_API_URL}/api/v1/users/${userDetail.userID}/data/${siteID}`, {
                    withCredentials: true,
                    credentials: "same-origin"
                }).then(res => {
                    if (res.data.status && res.data.content.drawEntries) {
                        SetName(res.data.content.userFirstName)
                        SetAllData(res.data.content)
                        console.log("my donation plan", res.data.content);
                        SetEntryData(res.data.content.drawEntries);
                        let allData = res.data.content;
                        // console.log("my donation plan", res.data.content);
                        let filteredData = [];
                        let drawEntries = res.data.content.drawEntries;
                        let drawTransaction = res.data.content.transactions;
                        let subscriptions = res.data.content.subscriptions;
                        filteredData = drawTransaction?.filter(item => ((item?.transactionSiteID == process.env.REACT_APP_DRAW_ID || item?.transactionSiteID == process.env.REACT_APP_SITE_ID) &&
                            item.transactionStatus == 3 &&
                            !subscriptions.some(sub => sub.userTransactionID === item.transactionID && (sub.userSubscriptionStatusID !== 3 || sub.userSubscriptionStatusID !== 7))
                        )).map(item => {
                            let el = item;
                            el.DrawEntries = drawEntries.find(ent => ent.drawEntryID == el.transactionDrawEntriesID)
                            return el;
                        })
                            .sort((a, b) => new Date(b.transactionDateTime) - new Date(a.transactionDateTime))

                        if (filteredData?.length > 0) {
                            Setmerge([filteredData[0]]);
                        } else {
                            Setmerge([]);
                        }
                        // console.log("data  merge", merge);

                    }
                    setLoadComplete(true);
                })
            } else {
                // console.log("data recieved", allData);

                let filteredData = [];
                let drawEntries = allData?.drawEntries;
                let drawTransaction = allData?.transactions;
                let subscriptions = allData?.subscriptions;
                filteredData = drawTransaction?.filter(item => (item?.transactionSiteID == process.env.REACT_APP_DRAW_ID || item?.transactionSiteID == process.env.REACT_APP_SITE_ID) && item.transactionStatus == 3
                    && !subscriptions.some(sub => sub.userTransactionID === item.transactionID && sub.userSubscriptionStatusID === 6)
                ).map(item => {
                    let el = item;
                    el.DrawEntries = drawEntries.find(ent => ent.drawEntryID == el.transactionDrawEntriesID)
                    return el;
                })
                    .sort((a, b) => new Date(b.transactionDateTime) - new Date(a.transactionDateTime))
                if (filteredData?.length > 0) {
                    Setmerge([filteredData[0]]);
                } else {
                    Setmerge([]);
                }
                setLoadComplete(true);
                // console.log("data  merge",merge.length, merge);
            }
        }

        return () => {
            document.body.classList.remove('bg-branding-2')
        }
    }, [])
    // console.log("merge",merge);

    return (
        <>
            <SiteHeader />
            <section className='bg-branding-2 font-face-sh font-regular' style={{ marginTop: '-110px', padding: '160px 0 20px' }}>
                <RecdonationAccountBanner name={name} />

                <nav className="account-nav-recdonation">
                    <div className="container">
                        <div className="d-flex flex-row justify-content-center flex-wrap">
                            <div className="p-2">
                                <Link to={'/account/my-donation-entries'} state={{ name: name, entrydata: entrydata, allData: allData }} className="btn btn-100 px-2 py-0 border-0 text-light">My Entries</Link>
                            </div>
                            <div className="p-2">
                                <Link to={'/account/my-donation-plan'} state={{ name: name, entrydata: entrydata, allData: allData }} className="btn btn-100 px-2 py-0 border-0 active">My Donation Plan</Link>
                            </div>
                            <div className="p-2">
                                <Link to={'/account/my-prizes-won'} state={{ name: name, entrydata: entrydata, allData: allData }} className="btn btn-100 px-2 py-0 border-0 text-light">Prizes Won</Link>
                            </div>
                            <div className="p-2">
                                <Link to={'/account/my-subscriptions'} state={{ name: name, entrydata: entrydata, allData: allData }} className="btn btn-100 px-2 py-0 border-0 text-light">My Subscriptions</Link>
                            </div>
                            <div className="p-2">
                                <Link to={'/account/my-payments'} state={{ name: name, entrydata: entrydata, allData: allData }} className="btn btn-100 px-2 py-0 border-0 text-light">My Payments</Link>
                            </div>
                            <div className="p-2">
                                <Link to={'/account/my-donation-details'} state={{ name: name, entrydata: entrydata, allData: allData }} className="btn btn-100 px-2 py-0 border-0 text-light">My Details</Link>
                            </div>
                        </div>
                    </div>
                </nav>

                <div className="page account pt-4">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">

                                {isLoaded ?
                                    merge.length === 0 ?
                                        <div className="card mb-4 content-card your-entries" style={{ maxWidth: 500, margin: 'auto', borderRadius: 10 }}>
                                            <div className="card-header text-center bg-white px-md-5 px-2 pt-5 border-0">
                                                <h2 className="text-center fs-5 fw-6"><span>You don't have any donation Plan</span></h2>
                                                <hr />
                                                <div className='my-5'>
                                                    <Link to={"/Support"} className='select-donate-btn text-white text-decoration-none fs-6 px-3 font-regular site-button-primary py-3 px-md-5 px-4 text-decoration-none rounded-pill'>Select Donation Plan</Link>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        merge.map((item) => {
                                            return (
                                                <div className="card mb-4 content-card your-entries" style={{ maxWidth: 500, margin: 'auto', borderRadius: 10 }}>
                                                    <div className="card-header text-center bg-white px-5 pt-5 border-0">
                                                        <h2 className="text-center fs-4 fw-6"><span>Current donation plan</span></h2>
                                                        <div className="my-5">
                                                            <h2 id="big-number" className='branding-4 mb-0' style={{ fontSize: '7rem' }}><span><span className="pound-sign display-5 position-relative" style={{ top: -42, right: 2 }}>£</span><span id="number">{item.DrawEntries?.drawEntryPrice}</span></span></h2>
                                                            <p className="mt-1"><strong>{item.transactionTypeID === 3 ? "Per Month, Paid monthly" : "Per year, Paid yearly"}</strong></p>
                                                        </div>
                                                        <hr />
                                                    </div>
                                                    <div className="card-block px-5 pb-4">
                                                        <h5 className="text-left fw-7 fs-6 font-bold">Joining parkrun forever means:</h5>
                                                        <ul className="text-left mb-4 mt-3 plan-benefits">
                                                            <li>keeping parkrun free, for everyone, forever</li>
                                                            <li>free entry into our monthly Thank You Draw</li>
                                                            <li>receiving a parkrun Forever supporter pack</li>
                                                            <li>regular updates from parkrun HQ</li>
                                                        </ul>
                                                        <hr />
                                                        {/* <Link id="select-donation-button" to={`/account/my-donation-change/${item.transactionID}`} state={item} className='d-block w-100 text-white text-center text-decoration-none fs-5 font-regular fw-normal site-button-primary py-3 px-5 rounded-3 mt-4 mb-3'>Change your plan</Link> */}
                                                        <Link id="select-donation-button" to={`/account/my-subscriptions`} className='d-block w-100 text-white text-center text-decoration-none fs-5 font-regular fw-normal site-button-primary py-3 px-5 rounded-3 mt-4 mb-3'>Change your plan</Link>
                                                    </div>
                                                </div>
                                            )
                                        }) :
                                    <div className="card mb-4 pt-5 content-card your-entries" style={{ maxWidth: 500, margin: 'auto', borderRadius: 10 }}>
                                        <div className='user-subscription-section font-face-sh pb-5'>
                                            <div className='no-subscription-box bg-white rounded-3 p-4 mx-auto' style={{ maxWidth: 327, boxShadow: '0 4px 28px rgba(0,0,0,.08)' }}>
                                                <p className='text-center fs-14 m-0' style={{ color: '#302d51' }}><strong>Loading...</strong></p>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <RecdonationAccountSupport />
            </section>

            <Sitefooter />
        </>
    )
}
