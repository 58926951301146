const initialSubscriptionState = {
  sub_amount: 6,
  sub_period: "Monthly", // Monthly, One-Off
  sub_mode: "", // new, edit

  sub_o_amount: 0,
  sub_o_sub_item_id: "",
};

const Reducer = (state = initialSubscriptionState, action) => {
  if (action.type === "setSubscriptionAmount") {
    state = { ...state, sub_amount: action.payload };
    return state;
  } else if (action.type === "setSubscriptionPeriod") {
    state = { ...state, sub_period: action.payload };
    return state;
  } else if (action.type === "setSubscriptionMode") {
    state = { ...state, sub_mode: action.payload };
    return state;
  } else if (action.type === "setSubscriptionOAmount") {
    state = { ...state, sub_o_amount: action.payload };
    return state;
  } else if (action.type === "setSubscriptionOSubItemID") {
    state = { ...state, sub_o_sub_item_id: action.payload };
    return state;
  } else {
    return state;
  }
};

export default Reducer;
