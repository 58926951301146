import React from 'react'
import { Link } from 'react-router-dom'
import '../App.css'
import Sitefooter from '../components/footer';
import SiteHeader from '../components/SiteHeader';
import useDocumentTitle from '../components/useDocumentTitle';

export default function About() {
    useDocumentTitle('About');

    return (
        <>   
            <SiteHeader/>

            <section className="hero-section herosec-img-about font-face-sh" style={{ marginTop: '-110px', padding: '170px 0 200px' }}>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 text-center '>
                            <h1 className='text-light font-semibold display-5 '>Not all good things must end.</h1>
                            <p className='text-light font-regular fs-3 '>parkrun Forever</p>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className='container'>
                    <div className='row align-items-center pt-5'>
                        <div className='col-md-6 col-sm-12 p-0 m-0 '>
                            <h2 className='font-semibold fs-3 text-center '>
                                From one parkrun to the many
                            </h2>
                            <p className='font-light fs-6 text-center px-4'>
                            The best things in life are free. Especially a Saturday or Sunday morning parkrun. And that’s not just us saying it, from a handful of people in Bushy Park, London, all those years ago, to more than 8 million people registered around the world.
                            </p>
                        </div>
                        <div className='col-md-6 col-sm-12 p-0 m-0'>
                            <img className='w-100' src={require('../images/about/about1.jpeg')} alt="Image parkrunforever about" />
                        </div>
                    </div>
                    <div className='row align-items-center pb-5'>
                        <div className='col-md-6 col-sm-12 p-0 m-0 '>
                            <img className='w-100' src={require('../images/about/about2.jpeg')} alt="Image parkrunforever about" />
                        </div>
                        <div className='col-md-6 col-sm-12 p-0 m-0'>
                            <h2 className='font-semibold fs-3 text-center '>
                            Our challenge and your support
                            </h2>
                            <p className='font-light fs-6 text-center px-4'>
                            Someone new joins parkrun every 42 seconds. parkrun is free to participate in, and delivered locally by volunteers, but as we continue to expand at an unprecedented rate, so too do the costs involved. We know that many parkrunners want a way of contributing to our future sustainability. parkrun Forever allows our community to make small, regular donations (and claim Gift Aid) that will help safeguard parkrun for future generations.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='yellow-strip text-center w-100 m-0 p-2'>
                    <div className='row container mx-auto text-center align-items-center'>
                        <div className='col-12 p-5'>
                            <h3 className='font-semibold fs-1 text-center text-light'>
                                Introducing parkrun Forever
                            </h3>
                            <p className='font-light fs-6 text-center text-light'>
                            parkrun Forever is a voluntary way for the parkrun community to help keep parkrun free, for everyone, forever. By contributing a little every month you will make a big difference in securing our future. As a thank you, you will be entered into the Thank You Draw each month.
                            </p>
                            <Link to={"/Support"} className='text-white text-decoration-none fs-6 px-3 font-regular site-button-primary py-3 px-5 text-decoration-none rounded-pill '>Start Supporting</Link>
                        </div>
                    </div>
                </div>
            </section>


            <Sitefooter/>

        </>
    )
}

