import React from "react";
import Slider from "react-slick";
import Quoet from '../images/slider/quoet.svg';

export default function Testimonials() {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay:true,
    };
    return (

        <Slider {...settings}>
            <div className="row ">
                <div className="col-12  text-center mx-auto">
                    <img src={Quoet} className="mx-auto" style={{ width: 130, height: 130 }} alt="" />
                    <p className="mx-auto col-md-6 col-sm-12 font-regular">
                    Delighted to win! Thanks for getting me and so many others out of bed each Saturday. You deserve every penny donated through parkrun Forever.
                    </p>
                    <div>
                        <img className="rounded-pill mx-auto my-3" src={require('../images/slider/2.jpeg')} style={{height:120,width:120}} alt="" />
                    </div>
                    <p className="mx-auto font-semibold py-2 fs-2">Rob</p>
                </div>
            </div>
            <div className="row ">
                <div className="col-12  text-center mx-auto">
                    <img src={Quoet} className="mx-auto" style={{ width: 130, height: 130 }} alt="" />
                    <p className="mx-auto col-md-6 col-sm-12 font-regular">
                    It's a pleasure to be able to play a small part in ensuring that parkrun remains free, forever.
                    </p>
                    <div>
                        <img className="rounded-pill mx-auto my-3" src={require('../images/slider/1.jpeg')} style={{height:120,width:120}} alt="" />
                    </div>
                    <p className="mx-auto font-semibold py-2 fs-2">Gaye</p>
                </div>
            </div>



        </Slider>

    )
}
