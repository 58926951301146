import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import AccountTopLogo from '../images/account/account-top-logo.svg'
import RunningIcon from '../images/account/running-icon.svg'
import FemaleRunnersIcon from '../images/account/female-runners-icon.svg'
import JuniorsIcon from '../images/account/juniors-icon.svg'

export default function RecdonationAccountSupport() {

  return (
    <>
        <div className="recdonation-account-banner">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 mb-5">
                        <div className="card content-card account-support-card bg-branding-1" style={{ maxWidth: 500, margin: 'auto', borderRadius: 10 }}>
                            <div className="card-header border-0 pt-5">
                                <h2 className="text-center mb-3 fs-4 text-light fw-6">Your support in action</h2>
                            </div>
                            <div className="card-block px-4 pt-4">
                                <div className="row text-center">
                                    <div className="col-4 col-xs-12 text-light wt100Resp">
                                        <img className="mb-3" src={RunningIcon}  />
                                        <h3 className='fs-6'>1216</h3>
                                        <p className='fs-13'>UK parkrun locations</p>
                                    </div>
                                    <div className="col-4 col-xs-12 text-light wt100Resp">
                                        <img className="mb-3" src={FemaleRunnersIcon}  />
                                        <h3 className='fs-6'>5.1million</h3>
                                        <p className='fs-13'>total parkrunners</p>
                                    </div>
                                    <div className="col-4 col-xs-12 text-light wt100Resp">
                                        <img className="mb-3" src={JuniorsIcon} />
                                        <h3 className='fs-6'>49,962,342</h3>
                                        <p className='fs-13'>completed parkruns</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
