import React from 'react'
import { Link } from 'react-router-dom'

export default function Sitefooter() {
  return (
    <footer className="footer bg-branding-1-light">
    <div className="footer-last-rows">
      <div className="footer-main m-0">
        <div className="container">
          <div className="d-flex flex-column flex-md-row justify-content-md-between">
            <div className="p-2 col-md p-md-0">
              <div className="p-0 px-md-3 ">
                <hr className="hidden-md-up " />
                <h2 className="py-2 py-md-0 font-semobold fs-5">Contact Us</h2>
              </div>
              <hr className="hidden-md-up text-light" />
              <div className="p-0 px-md-3 ">
                <p>
                  <span><strong>Post:</strong> PFW Holdings Ltd Suite#300, 4 Blenheim Court, Peppercorn Close, <br />Peterborough, PE1 2DU</span>
                </p>
                <p>
                  <span>For support with the parkrun Forever website, please email us <strong><a style={{color: 'grey'}} href="mailto:hello@playfundwin.com">here</a></strong></span>
                </p>
              </div>
            </div>
            <div className="p-2 col-md p-md-0">
              <div className="p-0 pl-md-3 ">
              <hr className="hidden-md-up " />
                <h2 className="py-2 py-md-0 font-semobold fs-5">Main Menu</h2>
              </div>
              <hr className="hidden-md-up text-light" />
              <div className="p-0 pl-md-3 ">
                <ul className="list-unstyled">
                  <li><Link to={'/about'} className="text-decoration-none text-muted" href="/about/">About</Link></li>
                  <li><Link to={'/prizes'} className="text-decoration-none text-muted" href="/prizes/">Thank You Draw</Link></li>
                  <li><Link to={'/support'} className="text-decoration-none text-muted" href="/donation-plan/">Support Now</Link></li>
                </ul>
              </div>
            </div>
          </div>
          <hr className="hidden-md-up text-light" />
          <div className="d-flex flex-column flex-md-row justify-content-md-between">
            <div className="px-2 px-md-0 pb-0 ">
              <p className="footer__credit">
                parkrun Forever is operated on behalf of <a style={{color: 'gray'}} href="https://playfundwin.com" target="_blank">Play Fund Win</a>
              </p>
            </div>
            <div className="px-2 px-md-0 pb-0 ">
              <ul className="list-unstyled list-inline-flex">
                <li><Link to={'/terms-conditions'} className="text-decoration-none text-muted">Terms &amp; Conditions</Link></li>
                <li><Link to={'/privacy-policy'} className="text-decoration-none text-muted">Privacy Policy</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-logo-area">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <div className="footer-logos d-flex flex-row justify-content-between justify-content-md-center align-items-center align-self-center">
                <div className="p-2 mr-4">
                  <img className='w-100' src={require('../images/visa-logo-grey.png')} alt="Visa Card" />
                </div>
                <div className="p-2 mr-4">
                  <span className="footer__logo"><img className='w-100' src={require('../images/mastercard-logo-grey.png')} alt="Mastercard Card" /></span>
                </div>
                <div className="p-2">
                  <img className='w-100' src={require('../images/maestro-logo-grey.png')} alt="Maestro Card" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>

  )
}
