import React from 'react'
import { Link } from 'react-router-dom'
import SiteHeader from '../components/SiteHeader';
import Sitefooter from '../components/footer';
import RecdonationAccountBanner from '../components/recdonation-account-banner';
import RecdonationAccountSupport from '../components/recdonation-account-support';
import { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import secureLocalStorage from 'react-secure-storage'
import axios from 'axios';
import apiClient from '../api.config';
import useDocumentTitle from '../components/useDocumentTitle';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import creditCardType from 'credit-card-type';

const header = { withCredentials: true, credentials: "same-origin" }
const luhn = require('luhn');

export default function MyPayments() {
    useDocumentTitle('My Payments');
    const userDetail = secureLocalStorage.getItem('LogObject');
    const userData = userDetail;
    let siteID = process.env.REACT_APP_SITE_ID
    // let APIURL = "https://api.playfundwin.com/api/v1";
    let APIURL = process.env.REACT_APP_API_URL

    const [name, SetName] = useState('');
    const [isLoaded, setLoadComplete] = useState('');
    const navigate = useNavigate();

    const [entryData, SetEntryData] = useState([]);
    const [allData, SetAllData] = useState([]);

    const [merge, Setmerge] = useState([]);
    const [inc, SetInc] = useState(1);

    const [userPaymentMethodList, SetUserPaymentMethodList] = useState([]);
    const [paymentMethodChange, SetPaymentMethodChange] = useState(1);
    const [modalAddNewCard, SetModalAddNewCard] = useState(false);
    const [modalEditCard, SetModalEditCard] = useState(false);
    const [modalDeleteCard, SetModalDeleteCard] = useState(false);
    const [modalDefaultCard, SetModalDefaultCard] = useState(false);

    const [modalShow, setModalShow] = useState(false);
    const [modalShow2, setModalShow2] = useState(false);

    //selected_card
    const [selectedCard, SetSelectedCard] = useState(null);

    //add new card 
    const [cardNumber, setCardNumber] = useState("");
    const [cardCVC, setCardCVC] = useState("");
    const [cardExpiryDate, setCardExpiryDate] = useState("");
    const [clickPaymentMethodBtn, setClickPaymentMethodBtn] = useState(false);
    const [cardBrandLogo, setCardBrandLogo] = useState('');
    const [fname, SetFname] = useState("");
    const [Lname, SetLname] = useState("");

    useEffect(() => {
        if (!userData) {
            navigate('/login');
        } else {
            axios.get(`${APIURL}/users/${userData.userID}/data`, {
                withCredentials: true,
                credentials: "same-origin"
            }
            ).then(res => {
                console.log(res.content);
                SetFname(res.data.content?.userFirstName)
                SetLname(res.data.content?.userSurname)
            }
            );
        }
    }, [])

    const handleCardNumberChange = (event) => {
        let value = event.target.value.replace(/\D/g, "");
        value = value.replace(/(\d{4})(?=\d)/g, "$1 ");
        setCardNumber(value);

        if (!value) {
            // If the card number is not entered or cleared, remove the card brand logo
            setCardBrandLogo('');
        } else {
            // Get the card brand based on the card number's BIN
            const cardType = creditCardType(value);
            if (cardType && cardType.length > 0) {
                // You can use this information to display the card brand logo
                const cardBrand = cardType[0].type.toLowerCase();
                // Assuming you have card brand logos in your assets folder
                setCardBrandLogo(cardBrand);
                console.log("cardBrandLogo:", cardBrandLogo);
            } else {
                setCardBrandLogo(''); // No brand logo found
            }
        }
    };

    const handleCardCVCChange = (event) => {
        let value = event.target.value;
        setCardCVC(value);
    };
    function handleCardExpiryDateChange(event) {
        const { value } = event.target;
        let formattedValue = value.replace(/\D/g, "").slice(0, 4);
        if (formattedValue.length > 2) {
            formattedValue = `${formattedValue.slice(0, 2)}/${formattedValue.slice(
                2,
                4
            )}`;
        }
        setCardExpiryDate(formattedValue);
    }

    async function handleAddUserPaymentMethod(event) {

        console.log("handleAddUserPaymentMethod:");
        if (!luhn.validate(cardNumber) || cardNumber === "") {
            toast.error(`Invalid card number`);
            return;
        }

        if (cardExpiryDate.length === 0) {
            toast.error(`Invalid expiry date`);
            return;
        }

        let expYear = parseInt('20' + cardExpiryDate.split("/")[1]);
        let expMonth = parseInt(cardExpiryDate.split("/")[0]);

        if (parseInt(expYear) < new Date().getFullYear()) {
            toast.error(`Invalid expiry Year`);
            return;
        }

        // Check that the expiration date is a valid future date
        const expDate = new Date(`${expYear}-${expMonth}-01`);
        const currentDate = new Date();
        if (expDate < currentDate || expMonth > 12 || parseInt(expMonth) === 0) {
            toast.error(`Card is expired`);
            return;
        }

        // Check that the CVC is a valid number
        const cvcRegex = /^[0-9]{3,4}$/; // Regex to match 3- or 4-digit numbers
        if (!cvcRegex.test(cardCVC)) {
            toast.error(`Invalid CVC`);
            return;
        }

        let body = {
            userID: userData.userID,
            action: "addPaymentMethod",
            cardNumber: cardNumber,
            cardCVC: cardCVC,
            expMonth: cardExpiryDate.split("/")[0],
            expYear: cardExpiryDate.split("/")[1],
        }

        setClickPaymentMethodBtn(true);
        let res = await actionUserPaymentMethod(body);
        console.log("AddUserPaymentMethod:", res);
        setClickPaymentMethodBtn(false);

        if (res.data?.status) {
            toast.success(res.data.content.message);
            updatePaymentMethod();
            SetModalAddNewCard(false);
        } else {
            if (res.response?.data?.status !== true) {
                toast.error(res.response.data.error.msg);
            } else {
                toast.error("Unknown Error!");
            }
        }
    }

    async function handleEditUserPaymentMethod(event) {
        // if (!luhn.validate(m_cardNumber)) {
        //   toast.error(`Invalid card number`);
        //   return;
        // }
        let expYear = parseInt("20" + cardExpiryDate.split("/")[1]);
        let expMonth = parseInt(cardExpiryDate.split("/")[0]);

        if (cardExpiryDate.length === 0) {
            toast.error(`Invalid expiry date`);
            return;
        }

        if (parseInt(expYear) < new Date().getFullYear()) {
            toast.error(`Invalid expiry Year`);
            return;
        }

        // Check that the expiration date is a valid future date
        const expDate = new Date(`${expYear}-${expMonth}-01`);
        const currentDate = new Date();
        if (expDate < currentDate || expMonth > 12 || expMonth === 0) {
            toast.error(`Card is expired`);
            return;
        }

        // // Check that the CVC is a valid number
        // const cvcRegex = /^[0-9]{3,4}$/; // Regex to match 3- or 4-digit numbers
        // if (!cvcRegex.test(m_cardCVC)) {
        //   toast.error(`Invalid CVC`);
        //   return;
        // }

        let body = {
            userID: userData.userID,
            paymentMethodID: selectedCard.paymentMethodID,
            action: "editPaymentMethod",
            // cardNumber: m_cardNumber.replace(/\D/g, ""),
            // cardCVC: m_cardCVC,
            expMonth: expMonth,
            expYear: expYear,
        };

        setClickPaymentMethodBtn(true);
        let res = await actionUserPaymentMethod(body);
        console.log("EditUserPaymentMethod:", res);
        setClickPaymentMethodBtn(false);

        if (res.data?.status) {
            toast.success(res.data.content.message);
            updatePaymentMethod();
            SetModalEditCard(false);
        } else {
            if (res.response?.data?.status !== true) {
                toast.error(res.response.data.error.msg);
            } else {
                toast.error("Unknown Error!");
            }
        }
    }

    async function handleDeleteUserPaymentMethod(event) {
        let body = {
            userID: userData.userID,
            paymentMethodID: selectedCard.paymentMethodID,
            action: "deletePaymentMethod",
        };

        setClickPaymentMethodBtn(true);
        let res = await actionUserPaymentMethod(body);
        console.log("deleteUserPaymentMethod:", res);
        setClickPaymentMethodBtn(false);

        if (res.data?.status) {
            toast.success(res.data.content.message);
            updatePaymentMethod();
            SetModalDeleteCard(false);
        } else {
            if (res.response?.data?.status !== true) {
                toast.error(res.response.data.error.msg);
            } else {
                toast.error("Unknown Error!");
            }
        }
    }

    async function handleDefaultUserPaymentMethod(event) {
        let body = {
            userID: userData.userID,
            paymentMethodID: selectedCard.paymentMethodID,
            action: "setDefaultPaymentMethod",
        };

        setClickPaymentMethodBtn(true);
        let res = await actionUserPaymentMethod(body);
        console.log("setDefaultUserPaymentMethod:", res);
        setClickPaymentMethodBtn(false);

        if (res.data?.status) {
            toast.success(res.data.content.message);
            updatePaymentMethod();
            SetModalDefaultCard(false);
        } else {
            if (res.response?.data?.status !== true) {
                toast.error(res.response.data.error.msg);
            } else {
                toast.error("Unknown Error!");
            }
        }
    }

    function updatePaymentMethod() {
        SetPaymentMethodChange(paymentMethodChange + 1);
    }

    async function actionUserPaymentMethod(body) {
        try {
            const res = await apiClient.post(`${APIURL}/api/v1/users/cardInfo`, body, header);
            console.log("addUserPaymentMethod:", res);
            return res;
        } catch (err) {
            console.log("result error:", err)
            return err;
        }
    }

    async function getUserPaymentMethodList(body) {
        try {

            const res = await apiClient.post(`${APIURL}/api/v1/users/cardInfo`, body, header);
            console.log("getUserPaymentMethods", res);

            if (res.data?.content?.status === 200) {
                let payment_method_list = [];
                if (Array.isArray(res.data?.content?.payment_method_list?.data)) {
                    let default_payment_method = null;
                    for (let i = 0; i < res.data.content.payment_method_list.data.length; i++) {
                        let payment_method = {
                            paymentMethodID: res.data.content.payment_method_list.data[i].id,
                            cardBrand: res.data.content.payment_method_list.data[i].card.brand,
                            cardNumber: 'XXXXXXXXXXXX' + res.data.content.payment_method_list.data[i].card.last4,
                            expMonth: res.data.content.payment_method_list.data[i].card.exp_month,
                            expYear: res.data.content.payment_method_list.data[i].card.exp_year,
                            last4: res.data.content.payment_method_list.data[i].card.last4,
                            obj: res.data.content.payment_method_list.data[i]
                        }

                        if (payment_method.paymentMethodID === res.data.content.default_payment_method_id) {
                            default_payment_method = payment_method;
                        } else {
                            payment_method_list.push(payment_method);
                        }
                    }
                    // payment_method_list = payment_method_list.reverse();
                    if (!res.data.content.default_payment_method_id) {
                        let payment_method = {
                            paymentMethodID: null,
                            cardBrand: "____",
                            cardNumber: 'No default',
                            expMonth: "XX",
                            expYear: "XXXX",
                            last4: "XXXX",
                            obj: null
                        }
                        payment_method_list.unshift(payment_method);
                    } else {
                        payment_method_list.unshift(default_payment_method);
                    }
                }
                return payment_method_list;
            }

            return [];
        } catch (err) {
            console.log("result error:", err)
            return err;
        }
    }

    async function getEntrayData() {
        axios.get(`${process.env.REACT_APP_API_URL}/api/v1/users/${userDetail.userID}/data/${siteID}`,
            {
                withCredentials: true,
                credentials: "same-origin"
            }
        ).then(res => {
            if (res.data.status && res.data.content.transactions) {
                SetEntryData(res.data.content.drawEntries);
            }
        })
    }

    useEffect(() => {
        if (!userData) {
            navigate('/login');
        } else {
            console.log("call payment method =--------------------------------------");
            getUserPaymentMethodList({ userID: userData.userID, action: "getPaymentMethods" }).then((res) => {
                if (Array.isArray(res)) {
                    SetUserPaymentMethodList(res);
                } else {
                    SetUserPaymentMethodList([]);
                }
                setLoadComplete(true);
            });
        }

        return () => {
            document.body.classList.remove('bg-branding-2')
        }

    }, [paymentMethodChange]);


    useEffect(() => {
        if (userDetail === null) {
            navigate('/login');
        } else {
            axios.get(`${process.env.REACT_APP_API_URL}/api/v1/users/${userDetail.userID}/data/${siteID}`,
                {
                    withCredentials: true,
                    credentials: "same-origin"
                }
            ).then(res => {

                if (res.data.status && res.data.content.transactions) {
                    // res.data.content.transactions.transactionSiteID
                    SetName(res.data.content.userFirstName)

                    SetAllData(res.data.content)
                    SetEntryData(res.data.content.drawEntries);

                    // 
                    let entry = res.data.content.drawEntries;
                    // drawEntryID
                    // transactionDrawEntriesID
                    let myMerge = [];
                    myMerge = res.data.content.transactions
                        .filter(item => (item?.transactionSiteID == process.env.REACT_APP_DRAW_ID || item?.transactionSiteID == process.env.REACT_APP_SITE_ID) && item.transactionStatus == 3).map(item => {
                            let el = item;
                            el.DrawEntries = res.data.content.drawEntries.find(ent => ent.drawEntryID == el.transactionDrawEntriesID)
                            return el;
                        })
                    Setmerge(myMerge)


                }
            })
        }

        return () => {
            document.body.classList.remove('bg-branding-2')
        }
    }, [inc])

    return (
        <>
            <SiteHeader />

            <section className='bg-branding-2 font-face-sh font-regular' style={{ marginTop: '-110px', padding: '160px 0 20px' }}>
                <RecdonationAccountBanner name={name} />

                <nav className="account-nav-recdonation">
                    <div className="container">
                        <div className="d-flex flex-row justify-content-center flex-wrap">
                            <div className="p-2">
                                <Link to={'/account/my-donation-entries'} state={{ name: name, entrydata: entryData, allData: allData }} className="btn btn-100 px-2 py-0 border-0 text-light">My Entries</Link>
                            </div>
                            <div className="p-2">
                                <Link to={'/account/my-donation-plan'} state={{ name: name, entrydata: entryData, allData: allData }} className="btn btn-100 px-2 py-0 border-0 text-light">My Donation Plan</Link>
                            </div>
                            <div className="p-2">
                                <Link to={'/account/my-prizes-won'} state={{ name: name, entrydata: entryData, allData: allData }} className="btn btn-100 px-2 py-0 border-0 text-light">Prizes Won</Link>
                            </div>
                            <div className="p-2">
                                <Link to={'/account/my-subscriptions'} state={{ name: name, entrydata: entryData, allData: allData }} className="btn btn-100 px-2 py-0 border-0 text-light">My Subscriptions</Link>
                            </div>
                            <div className="p-2">
                                <Link to={'/account/my-payments'} state={{ name: name, entrydata: entryData, allData: allData }} className="btn btn-100 px-2 py-0 border-0 active">My Payments</Link>
                            </div>
                            <div className="p-2">
                                <Link to={'/account/my-donation-details'} state={{ name: name, entrydata: entryData, allData: allData }} className="btn btn-100 px-2 py-0 border-0 text-light">My Details</Link>
                            </div>
                        </div>
                    </div>
                </nav>

                <div className="page account pt-4">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 noSpaceLeftResp">
                                <div className="card mb-4 content-card your-entries" style={{ maxWidth: 500, margin: 'auto', borderRadius: 10 }}>
                                    <div className="card-header bg-white px-md-5 px-2 pt-5 border-0" style={{ borderRadius: 10 }}>
                                        {isLoaded ?
                                            userPaymentMethodList.length === 0 ?
                                                <>
                                                    <div className='row' style={{ justifyContent: 'center' }}>
                                                        <button type="button" id="card_token_button2" className="btn btn-details"
                                                            onClick={() => {
                                                                setCardNumber("");
                                                                setCardExpiryDate("");
                                                                setCardCVC("");
                                                                SetModalAddNewCard(true);
                                                            }}
                                                        >Add New Card</button>
                                                    </div>
                                                    <p className='text-center fs-14 mt-4 mb-5' style={{ color: '#302d51' }}><strong>There is no payment methods available.</strong></p>
                                                </>
                                                :
                                                <>
                                                    <h2 className="text-center fs-4 fw-6"><span>My Payment Methods</span></h2>
                                                    <hr />
                                                    <div className='row' style={{ justifyContent: 'center' }}>
                                                        <button type="button" id="card_token_button2" className="btn btn-details"
                                                            onClick={() => {
                                                                setCardNumber("");
                                                                setCardExpiryDate("");
                                                                setCardCVC("");
                                                                SetModalAddNewCard(true);
                                                            }}
                                                        >Add New Card</button>
                                                    </div>

                                                    {/* {
                                                        userPaymentMethodList.length >0 ?
                                                         userPaymentMethodList.map((row, index) => {
                                                            return (
                                                                <div key={row.paymentMethodID} className='row mt-3'>
                                                                    {
                                                                        index === 0 && <div className='col-12 mb-2'>Default:</div>
                                                                    }
                                                                    {
                                                                        index === 1 && <div className='col-12 mb-2'>Others:</div>
                                                                    }

                                                                    <div className='col-12' style={{ position: 'relative' }}>
                                                                        <input type="text" name="method" placeholder="XXXX XXXX XXXX XXXX" value={"XXXX XXXX XXXX " + row.last4 + ' | ' + row.expMonth + '/' + row.expYear} disabled={true} className='rounded-pill align-self-center w-100 px-4 p-3 bg-white border-0' />

                                                                        <div
                                                                            style={{
                                                                                position: 'absolute',
                                                                                border: 'none',
                                                                                right: '30px',
                                                                                top: '10px',
                                                                                fontSize: '20px',
                                                                                background: 'transparent',
                                                                                display: 'flex',
                                                                                justifyContent: 'center',
                                                                                alignItems: 'center',
                                                                            }}>

                                                                            {
                                                                                index !== 0 && <button
                                                                                    onClick={() => {
                                                                                        setCardNumber("XXXX XXXX XXXX " + row.last4);
                                                                                        setCardExpiryDate((row.expMonth > 9 ? row.expMonth : '0' + row.expMonth) + "/" + row.expYear.toString().substr(2, 3));
                                                                                        setCardCVC('XXX');
                                                                                        SetSelectedCard(row);
                                                                                        SetModalDefaultCard(true);
                                                                                    }}
                                                                                    style={{
                                                                                        border: 'none',
                                                                                        fontSize: '20px',
                                                                                        background: 'transparent',
                                                                                    }}>
                                                                                    <i className='fa fa-check-circle'></i>
                                                                                </button>
                                                                            }
                                                                            {
                                                                                index !== 0 && <button
                                                                                    onClick={() => {
                                                                                        setCardNumber("XXXX XXXX XXXX " + row.last4);
                                                                                        setCardExpiryDate((row.expMonth > 9 ? row.expMonth : '0' + row.expMonth) + "/" + row.expYear.toString().substr(2, 3));
                                                                                        setCardCVC('XXX');
                                                                                        SetSelectedCard(row);
                                                                                        SetModalDeleteCard(true);
                                                                                    }}
                                                                                    style={{
                                                                                        border: 'none',
                                                                                        fontSize: '20px',
                                                                                        background: 'transparent',
                                                                                    }}>
                                                                                    <i className='fa fa-trash'></i>
                                                                                </button>
                                                                            }
                                                                            <button
                                                                                onClick={() => {
                                                                                    setCardNumber("XXXX XXXX XXXX " + row.last4);
                                                                                    setCardExpiryDate((row.expMonth > 9 ? row.expMonth : '0' + row.expMonth) + "/" + row.expYear.toString().substr(2, 3));
                                                                                    setCardCVC('XXX');
                                                                                    SetSelectedCard(row);
                                                                                    SetModalEditCard(true);
                                                                                }}
                                                                                style={{
                                                                                    border: 'none',
                                                                                    fontSize: '20px',
                                                                                    background: 'transparent',
                                                                                }}>
                                                                                <i className='fa fa-edit'></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }):""
                                                    } */}

                                                    <div className='row mt-4 p-0 align-items-center justify-content-center'>
                                                        {/* {
                                                            index === 0 && <div className='col-12 mb-2'>Default:</div>
                                                        }
                                                        {
                                                            index === 1 && <div className='col-12 mb-2'>Others:</div>
                                                        } */}
                                                        {
                                                            userPaymentMethodList.map((row, index) => {
                                                                return (

                                                                    <div key={index} className='col-12 p-md-0 px-3 mb-4' style={{ position: 'relative' }}>
                                                                        <div className='m-md-3 py-4 px-md-4 px-3 border border-2 shadow-lg bg-white position-relative' style={{ borderRadius: '20px' }}>
                                                                            {
                                                                                index === 0 && <div className='position-absolute' style={{ right: '-11px', top: '-12px', backgroundColor: '#f4c00c', color: '#393011', padding: '1px 10px', borderRadius: '5px' }}>Default</div>
                                                                            }
                                                                            <div className='d-flex justify-content-between align-items-center' style={{ marginTop: '-4px' }}>
                                                                                <h4 className='fs-5 align-self-center w-100 bg-white border-0 mb-2 fw-bold text-capitalize payment-card-number'>XXXX XXXX XXXX {row.last4 ? row.last4 : "XXXX"}</h4>
                                                                                {row?.cardBrand === 'visa' ?
                                                                                    <img src={require(`../images/payment-logo/visa-logo.png`)} className='position-relative card-brand-logo' style={{ width: '50px', top: '-3px' }} alt="Card Brand Logo" />
                                                                                    : ''}
                                                                                {row?.cardBrand === 'mastercard' ?
                                                                                    <img src={require(`../images/payment-logo/mastercard-logo.png`)} className='position-relative card-brand-logo' style={{ width: '50px', top: '-3px' }} alt="Card Brand Logo" />
                                                                                    : ''}
                                                                                {row?.cardBrand === 'amex' ?
                                                                                    <img src={require(`../images/payment-logo/amex-logo.png`)} className='position-relative card-brand-logo' style={{ width: '50px', top: '-3px' }} alt="Card Brand Logo" />
                                                                                    : ''}
                                                                                {row?.cardBrand === 'american-express' ?
                                                                                    <img src={require(`../images/payment-logo/american-express-logo.png`)} className='position-relative card-brand-logo' style={{ width: '50px', top: '-3px' }} alt="Card Brand Logo" />
                                                                                    : ''}
                                                                                {row?.cardBrand === 'discover' ?
                                                                                    <img src={require(`../images/payment-logo/discover-logo.png`)} className='position-relative card-brand-logo' style={{ width: '50px', top: '-3px' }} alt="Card Brand Logo" />
                                                                                    : ''}
                                                                                {row?.cardBrand === 'jcb' ?
                                                                                    <img src={require(`../images/payment-logo/jcb-logo.png`)} className='position-relative card-brand-logo' style={{ width: '50px', top: '-3px' }} alt="Card Brand Logo" />
                                                                                    : ''}
                                                                                {row?.cardBrand === 'diners-club' ?
                                                                                    <img src={require(`../images/payment-logo/diners-club-logo.png`)} className='position-relative card-brand-logo' style={{ width: '50px', top: '-3px' }} alt="Card Brand Logo" />
                                                                                    : ''}
                                                                                {row?.cardBrand === 'unionpay' ?
                                                                                    <img src={require(`../images/payment-logo/unionpay-logo.png`)} className='position-relative card-brand-logo' style={{ width: '50px', top: '-3px' }} alt="Card Brand Logo" />
                                                                                    : ''}
                                                                                {row?.cardBrand === 'maestro' ?
                                                                                    <img src={require(`../images/payment-logo/maestro-logo.png`)} className='position-relative card-brand-logo' style={{ width: '50px', top: '-3px' }} alt="Card Brand Logo" />
                                                                                    : ''}
                                                                            </div>
                                                                            <h4 className='fs-5 align-self-center w-100 bg-white border-0 mb-2 payment-card-holdername'>{row?.obj?.billing_details?.name ? row?.obj?.billing_details?.name : fname + ' ' + Lname ? fname + ' ' + Lname : ''}</h4>
                                                                            {/* <h4 className='align-self-center w-100 bg-white border-0 mb-2 fs-5 mb-4' style={{color: '#7d7d7d'}}>Expires {row.expMonth + '/' + row.expYear}</h4> */}
                                                                            <div className='mt-2 d-flex'>
                                                                                <div className=' me-5'>
                                                                                    <p className='m-0' style={{ fontSize: '0.95rem', color: '#7d7d7d' }}>VALID THRU</p>
                                                                                    <p className='fw-bold' style={{ fontSize: '0.95rem', color: '#7d7d7d' }}>
                                                                                        {row.expMonth ? row.expMonth : "XX"}/{row.expYear ? row.expYear : "XX"}
                                                                                    </p>
                                                                                </div>
                                                                                <div className=''>
                                                                                    <p className='m-0' style={{ fontSize: '0.95rem', color: '#7d7d7d' }}>CVC</p>
                                                                                    <p className='fw-bold' style={{ fontSize: '0.95rem', color: '#7d7d7d' }}>
                                                                                        XXX
                                                                                    </p>
                                                                                </div>
                                                                            </div>

                                                                            <div
                                                                                style={{
                                                                                    // position: 'absolute',
                                                                                    border: 'none',
                                                                                    right: '30px',
                                                                                    top: '10px',
                                                                                    fontSize: '20px',
                                                                                    background: 'transparent',
                                                                                    display: 'flex',
                                                                                    justifyContent: 'center',
                                                                                    alignItems: 'center',
                                                                                }}>

                                                                                {/* {
                                                                                    index !== 0 &&  */}
                                                                                <button
                                                                                    className='text-white fs-6 px-3 py-1 w-50 rounded-pill align-self-center fw-bold'
                                                                                    onClick={() => {
                                                                                        setCardNumber("XXXX XXXX XXXX " + row.last4);
                                                                                        setCardExpiryDate((row.expMonth > 9 ? row.expMonth : '0' + row.expMonth) + "/" + row.expYear.toString().substr(2, 3));
                                                                                        setCardCVC('XXX');
                                                                                        SetSelectedCard(row);
                                                                                        SetModalDeleteCard(true);
                                                                                    }}
                                                                                    style={{
                                                                                        border: 'none',
                                                                                        lineHeight: 2,
                                                                                        backgroundColor: '#DC3545',
                                                                                        // fontSize: '20px',
                                                                                        // background: 'transparent',
                                                                                    }}>
                                                                                    {/* <i className='fa fa-trash'></i> */}
                                                                                    Remove
                                                                                </button>
                                                                                {/* } */}
                                                                                <button
                                                                                    className='bg-branding-1 text-white fs-6 px-3 py-1 ms-2 w-50 rounded-pill align-self-center fw-bold'
                                                                                    onClick={() => {
                                                                                        setCardNumber("XXXX XXXX XXXX " + row.last4);
                                                                                        setCardExpiryDate((row.expMonth > 9 ? row.expMonth : '0' + row.expMonth) + "/" + row.expYear.toString().substr(2, 3));
                                                                                        setCardCVC('XXX');
                                                                                        SetSelectedCard(row);
                                                                                        SetModalEditCard(true);
                                                                                    }}
                                                                                    style={{
                                                                                        border: 'none',
                                                                                        lineHeight: 2,
                                                                                        // fontSize: '18px',
                                                                                        // background: 'transparent',
                                                                                    }}>
                                                                                    {/* <i className='fa fa-edit'></i> */}
                                                                                    Edit
                                                                                </button>
                                                                                {
                                                                                    index !== 0 &&
                                                                                    <button
                                                                                        className='bg-branding-green text-white fs-6 ms-2 rounded-circle d-flex align-items-center justify-content-center align-self-center fw-bold make-default-btn'
                                                                                        onClick={() => {
                                                                                            setCardNumber("XXXX XXXX XXXX " + row.last4);
                                                                                            setCardExpiryDate((row.expMonth > 9 ? row.expMonth : '0' + row.expMonth) + "/" + row.expYear.toString().substr(2, 3));
                                                                                            setCardCVC('XXX');
                                                                                            SetSelectedCard(row);
                                                                                            SetModalDefaultCard(true);
                                                                                        }}
                                                                                        title="Make Default"
                                                                                        style={{
                                                                                            border: 'none',
                                                                                            width: '50px',
                                                                                            height: '40px',
                                                                                        }}>
                                                                                        {/* Make Default */}
                                                                                        <i className='fa fa-check-circle fs-4'></i>
                                                                                    </button>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </>
                                            :
                                            <div className='user-subscription-section font-face-sh pb-5'>
                                                <div className='no-subscription-box bg-white rounded-3 p-4 mx-auto' style={{ maxWidth: 327, boxShadow: '0 4px 28px rgba(0,0,0,.08)' }}>
                                                    <p className='text-center fs-14 m-0' style={{ color: '#302d51' }}><strong>Loading...</strong></p>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <RecdonationAccountSupport />
            </section>

            <Modal show={modalAddNewCard} onHide={() => SetModalAddNewCard(false)} size="md" centered>
                <Modal.Header closeButton style={{ position: 'relative' }} className='payment-card-modal-header px-3'>
                    <Modal.Title>
                        <div className="fw-bold branding-1">Add A New Card</div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='payment-card-modal-body'>
                    <div className="px-3">
                        <div className='row position-relative'>
                            <label htmlFor="card_number" className='p-0 mb-2'>Card Number:</label>
                            <div className='position-relative p-0'>
                                <input
                                    value={cardNumber}
                                    onChange={handleCardNumberChange}
                                    placeholder="XXXX XXXX XXXX XXXX"
                                    maxLength={19}
                                    className='rounded-pill w-100'
                                    style={{ border: '1px solid #C9C9C9', padding: "13px 20px" }}
                                />
                                {/* Display the card brand logo */}
                                {cardBrandLogo && (
                                    <img src={require(`../images/payment-logo/${cardBrandLogo}-logo.png`)} className='position-absolute card-brand-logo-type' style={{ width: '50px', right: '20px', top: '0px', bottom: '0px', margin: 'auto' }} alt="Card Brand Logo" />
                                )}
                            </div>
                        </div>
                        {/* <div className='row mt-3 position-relative'>
              <label htmlFor="card_number" className='p-0 mb-2'>Card Holder Name:</label>
              <div className='position-relative p-0'>
              <input
                value={cardHolderName}
                onChange={handleCardHolderNameChange}
                placeholder="e.g. John Doe"
                maxLength={19}
                className='rounded-pill w-100'
                style={{ border: '1px solid #C9C9C9', padding: "13px 20px" }}
              />
              </div>
            </div> */}
                        <div className='row mt-3'>
                            <div className="col-6">
                                <div className="row pe-2">
                                    <label className='p-0 mb-2'>Expiry date:</label>
                                    <input
                                        type="text"
                                        placeholder="MM/YY"
                                        value={cardExpiryDate}
                                        onChange={handleCardExpiryDateChange}
                                        className='rounded-pill'
                                        style={{ border: '1px solid #C9C9C9', padding: "13px 20px" }}
                                    />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="row ps-2">
                                    <label className='p-0 mb-2'>CVC/CVV:</label>
                                    <input
                                        type="text"
                                        value={cardCVC}
                                        onChange={handleCardCVCChange}
                                        placeholder="XXX"
                                        maxLength={4}
                                        className='rounded-pill'
                                        style={{ border: '1px solid #C9C9C9', padding: "13px 20px" }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer className='payment-card-modal-footer'>
                    <div className="px-3 pb-3 w-100 d-flex justify-content-center card-foot-inner">
                        <div className="row">
                            <div style={{
                                display: 'flex',
                            }}>
                                <div className="d-flex">
                                    <div>
                                        <input
                                            className="btn text-white rounded-pill align-self-center px-2 py-2 fs-6 fw-bold"
                                            type="button"
                                            name="submit"
                                            value="Cancel"
                                            onClick={() => { SetModalAddNewCard(false) }}
                                            style={{ lineHeight: 2, width: '120px', backgroundColor: '#DC3545' }} />
                                    </div>
                                    <div className='ms-3'>
                                        <input
                                            className="bg-branding-1 text-white rounded-pill align-self-center px-2 py-2 fs-6 fw-bold"
                                            type="button"
                                            name="submit"
                                            value="Save"
                                            disabled={clickPaymentMethodBtn}
                                            onClick={() => {
                                                handleAddUserPaymentMethod();
                                            }}
                                            style={{ lineHeight: 2, width: '120px' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='trusted-secure text-center my-2 w-100 d-flex justify-content-center'>
                        <img src={require('../images/padlock.png')} className="d-inline-block" style={{ width: 20, height: 'auto' }} />
                        <span className="d-inline-block fw-bold branding-2 ms-1" style={{ color: '#999999' }}>Trusted Secure</span>
                    </div>
                </Modal.Footer>
            </Modal>
            {
                selectedCard && <Modal show={modalEditCard} onHide={() => SetModalEditCard(false)} size="md" centered>
                    <Modal.Header closeButton style={{ position: 'relative' }} className='payment-card-modal-header px-3'>
                        <Modal.Title>
                            <div className="fw-bold branding-1">Edit Card</div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='payment-card-modal-body'>
                        <div className="px-3">
                            <div className='row'>
                                <label htmlFor="card_number" className='p-0 mb-2'>Card Number:</label>
                                <div className='position-relative p-0'>
                                    <input
                                        // disabled="true"
                                        value={cardNumber}
                                        onChange={handleCardNumberChange}
                                        placeholder="XXXX XXXX XXXX XXXX"
                                        maxLength={19}
                                        className='rounded-pill w-100'
                                        style={{ border: '1px solid #C9C9C9', padding: "13px 20px" }}
                                    />
                                    {/* Display the card brand logo */}
                                    {cardBrandLogo && (
                                        <img src={require(`../images/payment-logo/${cardBrandLogo}-logo.png`)} className='position-absolute card-brand-logo-type' style={{ width: '50px', right: '20px', top: '0px', bottom: '0px', margin: 'auto' }} alt="Card Brand Logo" />
                                    )}
                                </div>
                            </div>
                            {/* <div className='row mt-3'>
                <label htmlFor="card_number" className='p-0 mb-2'>Card Holder Name:</label>
                <div className='position-relative p-0'>
                <input
                  value={cardHolderName}
                  onChange={handleCardHolderNameChange}
                  placeholder="e.g. John Doe"
                  maxLength={19}
                  className='rounded-pill w-100'
                  style={{ border: '1px solid #C9C9C9', padding: "13px 20px" }}
                />
                </div>
              </div> */}
                            <div className='row mt-3'>
                                <div className="col-6">
                                    <div className="row pe-2">
                                        <label className='p-0 mb-2'>Expiry date:</label>
                                        <input
                                            type="text"
                                            placeholder="MM/YY"
                                            value={cardExpiryDate}
                                            onChange={handleCardExpiryDateChange}
                                            className='rounded-pill'
                                            style={{ border: '1px solid #C9C9C9', padding: "13px 20px" }}
                                        />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="row ps-2">
                                        <label className='p-0 mb-2'>CVC/CVV:</label>
                                        <input
                                            type="text"
                                            // disabled="true"
                                            value={cardCVC}
                                            onChange={handleCardCVCChange}
                                            placeholder="XXX"
                                            maxLength={4}
                                            className='rounded-pill'
                                            style={{ border: '1px solid #C9C9C9', padding: "13px 20px" }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer className='payment-card-modal-footer'>
                        <div className="px-3 pb-3 w-100 d-flex justify-content-center card-foot-inner">
                            <div className="row">
                                <div style={{
                                    display: 'flex',
                                }}>
                                    <div className="d-flex">
                                        <div>
                                            <input
                                                className="btn text-white rounded-pill align-self-center px-2 py-2 fs-6 fw-bold"
                                                type="button"
                                                name="submit"
                                                value="Cancel"
                                                onClick={() => { SetModalEditCard(false) }}
                                                style={{ lineHeight: 2, width: '120px', backgroundColor: '#DC3545' }} />
                                        </div>
                                        <div className='ms-3'>
                                            <input
                                                className="bg-branding-1 text-white rounded-pill align-self-center px-2 py-2 fs-6 fw-bold"
                                                type="button"
                                                name="submit"
                                                value="Save"
                                                disabled={clickPaymentMethodBtn}
                                                onClick={() => {
                                                    handleEditUserPaymentMethod();
                                                }}
                                                style={{ lineHeight: 2, width: '120px' }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='trusted-secure text-center my-2 w-100 d-flex justify-content-center'>
                            <img src={require('../images/padlock.png')} className="d-inline-block" style={{ width: 20, height: 'auto' }} />
                            <span className="d-inline-block fw-bold branding-2 ms-1" style={{ color: '#999999' }}>Trusted Secure</span>
                        </div>
                    </Modal.Footer>
                </Modal>
            }
            {
                selectedCard && <Modal show={modalDeleteCard} onHide={() => SetModalDeleteCard(false)} size="md" centered>
                    <Modal.Header closeButton style={{ position: 'relative' }} className='payment-card-modal-header px-md-4 px-3'>
                        <Modal.Title>
                            <div className="fw-bold branding-1">Remove Card</div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='payment-card-modal-body px-md-4 px-3'>
                        <div>
                            <div className="row">
                                <h5>
                                    Are you sure you want to remove this payment method?
                                </h5>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-center">
                        <div className="px-3 pb-3">
                            <div className="row">
                                <div style={{
                                    display: 'flex',
                                }}>
                                    <div className="d-flex">
                                        <div>
                                            <input
                                                className="btn text-white rounded-pill align-self-center px-2 py-2 fs-6 fw-bold"
                                                type="button"
                                                name="submit"
                                                value="Cancel"
                                                onClick={() => { SetModalDeleteCard(false) }}
                                                style={{ lineHeight: 2, width: '120px', backgroundColor: '#DC3545' }} />
                                        </div>
                                        <div className='ms-3'>
                                            <input
                                                className="bg-branding-1 text-white rounded-pill align-self-center px-2 py-2 fs-6 fw-bold"
                                                type="button"
                                                name="submit"
                                                value="Yes"
                                                disabled={clickPaymentMethodBtn}
                                                onClick={() => {
                                                    handleDeleteUserPaymentMethod();
                                                }}
                                                style={{ lineHeight: 2, width: '120px' }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>
            }
            {
                selectedCard && <Modal show={modalDefaultCard} onHide={() => SetModalDefaultCard(false)} size="md" centered>
                    <Modal.Header closeButton style={{ position: 'relative' }} className='payment-card-modal-header px-md-4 px-3'>
                        <Modal.Title>
                            <div className="fw-bold branding-1">Set Default Card</div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='payment-card-modal-body px-md-4 px-3'>
                        <div>
                            <div className="row">
                                <h5>
                                    Would you like to set this as your default payment method?
                                </h5>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-center">
                        <div className="px-3 pb-3">
                            <div className="row">
                                <div style={{
                                    display: 'flex',
                                }}>
                                    <div className="d-flex">
                                        <div>
                                            <input
                                                className="btn text-white rounded-pill align-self-center px-2 py-2 fs-6 fw-bold"
                                                type="button"
                                                name="submit"
                                                value="Cancel"
                                                onClick={() => { SetModalDefaultCard(false) }}
                                                style={{ lineHeight: 2, width: '120px', backgroundColor: '#DC3545' }} />
                                        </div>
                                        <div className='ms-3'>
                                            <input
                                                className="bg-branding-1 text-white rounded-pill align-self-center px-2 py-2 fs-6 fw-bold"
                                                type="button"
                                                name="submit"
                                                value="Yes"
                                                disabled={clickPaymentMethodBtn}
                                                onClick={() => {
                                                    handleDefaultUserPaymentMethod();
                                                }}
                                                style={{ lineHeight: 2, width: '120px' }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>
            }
            {/* <Modal
                show={modalShow} onHide={() => setModalShow(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton className='d-flex flex-column justify-content-start'>
                    <Modal.Title id="contained-modal-title-vcenter" className="w-100 text-left">
                        Claim Prize
                    </Modal.Title>
                    <p className='w-100 text-left m-0'>Please fill the below bank details</p>
                </Modal.Header>
                <Modal.Body>
                    <div className='claim-methods d-flex gap-2 mb-3'>
                        <Button onClick={() => { }} className={'w-50 py-3 px-5 border border-2 border-white rounded-3 border border-branding-1 bg-transparent branding-1 branding-1-hover text-decoration-none fs-6 font-face-sh'} style={{ lineHeight: '10px' }}>
                            Bank Account
                        </Button>

                    </div>
                    <div className={'d-block'}>
                        <input type="email" id="paypalemail" onChange={(event) => { }} placeholder="Email Address" className="p-3 my-3 form-control" />

                        <button type="submit" id="btn-signup" onClick={() => { }} className="button btn bg-branding-green border-0 w-100 fs-5 font-face-sh-bold rounded-3 py-3 mt-3 mb-2 text-light" style={{ backgroundColor: 'rgb(5, 179, 4)', lineHeight: '10px' }}>Submit</button>
                    </div>

                    <div className='trusted-secure text-center my-2'>
                        <img src={require('../images/padlock.png')} className="d-inline-block" style={{ width: 20, height: 'auto' }} />
                        <span className="d-inline-block fw-bold branding-2 ms-1">Trusted Secure</span>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={modalAddNewCard} onHide={() => SetModalAddNewCard(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton className="d-flex flex-column justify-content-start" style={{ position: 'relative' }}>
                    <Modal.Title className="w-100 text-left">
                        <div className="px-3"> Add A New Card </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="px-3">
                        <div className='row'>
                            <label htmlFor="card_number" className='p-0 mb-1'>Card Number:</label>
                            <input
                                value={cardNumber}
                                onChange={handleCardNumberChange}
                                placeholder="XXXX XXXX XXXX XXXX"
                                maxLength={19}
                                style={{ borderRadius: '5px', border: '1px solid #C9C9C9', padding: "5px 20px" }}
                            />
                        </div>
                        <div className='row mt-3'>
                            <div className="col-6">
                                <div className="row me-0">
                                    <label className='p-0 mb-1'>Expiry date:</label>
                                    <input
                                        type="text"
                                        placeholder="MM/YY"
                                        value={cardExpiryDate}
                                        onChange={handleCardExpiryDateChange}
                                        style={{ borderRadius: '5px', border: '1px solid #C9C9C9', padding: "5px 20px" }}
                                    />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="row ms-0">
                                    <label className='p-0 mb-1'>CVC/CVV:</label>
                                    <input
                                        type="text"
                                        value={cardCVC}
                                        onChange={handleCardCVCChange}
                                        placeholder="XXX"
                                        maxLength={4}
                                        style={{ borderRadius: '5px', border: '1px solid #C9C9C9', padding: "5px 20px" }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <div className="px-0">
                        <div className="row">
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row-reverse',
                            }}>
                                <div className="row" style={{ flexDirection: 'row-reverse' }}>
                                    <div style={{ width: '150px' }}>
                                        <input
                                            className="btn pfw-button-green rounded-3 align-self-center px-2 py-2 fs-6 fw-bold text-light"
                                            type="button"
                                            name="submit"
                                            value="Yes"
                                            disabled={clickPaymentMethodBtn}
                                            onClick={() => {
                                                handleAddUserPaymentMethod();
                                            }}
                                            style={{ width: '120px', backgroundColor: 'rgb(5, 179, 4)', lineHeight: '10px' }} />
                                    </div>
                                    <div style={{ width: '150px' }}>
                                        <input
                                            className="btn pfw-button-green rounded-3 align-self-center px-2 py-2 fs-6 fw-bold text-light"
                                            type="button"
                                            name="submit"
                                            value="Cancel"
                                            onClick={() => { SetModalAddNewCard(false) }}
                                            style={{ width: '120px', backgroundColor: 'rgb(5, 179, 4)', lineHeight: '10px' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
            {
                selectedCard && <Modal show={modalEditCard} onHide={() => SetModalEditCard(false)}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton className="d-flex flex-column justify-content-start" style={{ position: 'relative' }}>
                        <Modal.Title className="w-100 text-left">
                            <div className="px-3"> Edit Card </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="px-3">
                            <div className='row'>
                                <label htmlFor="card_number" className='p-0 mb-1'>Card Number:</label>
                                <input
                                    // disabled="true"
                                    value={cardNumber}
                                    onChange={handleCardNumberChange}
                                    placeholder="XXXX XXXX XXXX XXXX"
                                    maxLength={19}
                                    style={{ borderRadius: '5px', border: '1px solid #C9C9C9', padding: "5px 20px" }}
                                />
                            </div>
                            <div className='row mt-3'>
                                <div className="col-6">
                                    <div className="row me-0">
                                        <label className='p-0 mb-1'>Expiry date:</label>
                                        <input
                                            type="text"
                                            placeholder="MM/YY"
                                            value={cardExpiryDate}
                                            onChange={handleCardExpiryDateChange}
                                            style={{ borderRadius: '5px', border: '1px solid #C9C9C9', padding: "5px 20px" }}
                                        />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="row ms-0">
                                        <label className='p-0 mb-1'>CVC/CVV:</label>
                                        <input
                                            type="text"
                                            // disabled="true"
                                            value={cardCVC}
                                            onChange={handleCardCVCChange}
                                            placeholder="XXX"
                                            maxLength={4}
                                            style={{ borderRadius: '5px', border: '1px solid #C9C9C9', padding: "5px 20px" }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <div className="px-0">
                            <div className="row">
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row-reverse',
                                }}>
                                    <div className="row" style={{ flexDirection: 'row-reverse' }}>
                                        <div style={{ width: '150px' }}>
                                            <input
                                                className="btn pfw-button-green rounded-3 align-self-center px-2 py-2 fs-6 fw-bold text-light"
                                                type="button"
                                                name="submit"
                                                value="Yes"
                                                disabled={clickPaymentMethodBtn}
                                                onClick={() => {
                                                    handleEditUserPaymentMethod();
                                                }}
                                                style={{ width: '120px', backgroundColor: 'rgb(5, 179, 4)', lineHeight: '10px' }} />
                                        </div>
                                        <div style={{ width: '150px' }}>
                                            <input
                                                className="btn pfw-button-green rounded-3 align-self-center px-2 py-2 fs-6 fw-bold text-light"
                                                type="button"
                                                name="submit"
                                                value="Cancel"
                                                onClick={() => { SetModalEditCard(false) }}
                                                style={{ width: '120px', backgroundColor: 'rgb(5, 179, 4)', lineHeight: '10px' }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>
            }
            {
                selectedCard && <Modal show={modalDeleteCard} onHide={() => SetModalDeleteCard(false)} size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Header closeButton className="d-flex flex-column justify-content-start" style={{ position: 'relative' }}>
                        <Modal.Title className="w-100 text-left">
                            <div className="px-3"> Remove Card </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="px-3">
                            <div className="row">
                                <h5>
                                    Are you sure you want to remove this payment method?
                                </h5>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <div className="px-3">
                            <div className="row">
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row-reverse',
                                }}>
                                    <div className="row" style={{ flexDirection: 'row-reverse' }}>
                                        <div style={{ width: '150px' }}>
                                            <input
                                                className="btn pfw-button-green rounded-3 align-self-center px-2 py-2 fs-6 fw-bold text-light"
                                                type="button"
                                                name="submit"
                                                value="Yes"
                                                disabled={clickPaymentMethodBtn}
                                                onClick={() => {
                                                    handleDeleteUserPaymentMethod();
                                                }}
                                                style={{ width: '120px', backgroundColor: 'rgb(5, 179, 4)', lineHeight: '10px' }} />
                                        </div>
                                        <div style={{ width: '150px' }}>
                                            <input
                                                className="btn pfw-button-green rounded-3 align-self-center px-2 py-2 fs-6 fw-bold text-light"
                                                type="button"
                                                name="submit"
                                                value="Cancel"
                                                onClick={() => { SetModalDeleteCard(false) }}
                                                style={{ width: '120px', backgroundColor: 'rgb(5, 179, 4)', lineHeight: '10px' }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>
            }
            {
                selectedCard && <Modal show={modalDefaultCard} onHide={() => SetModalDefaultCard(false)} size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Header closeButton className="d-flex flex-column justify-content-start" style={{ position: 'relative' }}>
                        <Modal.Title className="w-100 text-left">
                            <div className="px-3"> Set Default Card </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="px-3">
                            <div className="row">
                                <h5>
                                    Would you like to set this as your default payment method?
                                </h5>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <div className="px-3">
                            <div className="row">
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row-reverse',
                                }}>
                                    <div className="row" style={{ flexDirection: 'row-reverse' }}>
                                        <div style={{ width: '150px' }}>
                                            <input
                                                className="btn pfw-button-green rounded-3 align-self-center px-2 py-2 fs-6 fw-bold text-light"
                                                type="button"
                                                name="submit"
                                                value="Yes"
                                                disabled={clickPaymentMethodBtn}
                                                onClick={() => {
                                                    handleDefaultUserPaymentMethod();
                                                }}
                                                style={{ width: '120px', backgroundColor: 'rgb(5, 179, 4)', lineHeight: '10px' }} />
                                        </div>
                                        <div style={{ width: '150px' }}>
                                            <input
                                                className="btn pfw-button-green rounded-3 align-self-center px-2 py-2 fs-6 fw-bold text-light"
                                                type="button"
                                                name="submit"
                                                value="Cancel"
                                                onClick={() => { SetModalDefaultCard(false) }}
                                                style={{ width: '120px', backgroundColor: 'rgb(5, 179, 4)', lineHeight: '10px' }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>
            } */}
            <ToastContainer />

            <Sitefooter />
        </>
    )
}