import { React, useState } from 'react'
import { Link } from 'react-router-dom'
import '../App.css'
import SiteHeader from '../components/SiteHeader';
import TopIndicator1 from '../images/donation/indicator-1.svg'
import { AmountVar } from '../components/global';
import Sitefooter from '../components/footer';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreaters } from '../State';
import useDocumentTitle from '../components/useDocumentTitle';


export default function Support() {
    useDocumentTitle('Support');

    const [isActive, setActive] = useState(true);
    const [ActiveTab, setActiveTab] = useState(true);
    const [AmountText, setAmountText] = useState(6);
    const period = useSelector(state=>state.period);
    const amount = useSelector(state=>state.amount);
    AmountVar.period = period;
    AmountVar.amount = amount;
    const dispatch = useDispatch();
    const {SetAmount,SetPeriod} = bindActionCreators(actionCreaters,dispatch)


    const SetOnline = (e) => {
        e.preventDefault();
        setActiveTab(true);
        window.scrollTo({
            top: 0, 
            behavior: 'smooth'
          });
    };

    const SetPost = (e) => {
        e.preventDefault();
        setActiveTab(false);
        window.scrollTo({
            top: 0, 
            behavior: 'smooth'
          });
          console.log(period,amount);
    };

    const SetMonthly = (e) => {
        e.preventDefault();
        setActive(true);
        SetPeriod('Monthly');
        AmountVar.period = 'Monthly';
    };
    const SetAnually = (e) => {
        e.preventDefault();
        setActive(false);
        SetPeriod('Anually');
        AmountVar.period = 'Anually';
    };

    function handleAmount(amount) {
        setAmountText(amount);
        AmountVar.amount = amount;
        SetAmount(amount);
        return true;
    }
    function hndleInputAmount(event) {
        // AmountVar.amount = event.target.value;
        // setAmountText(event.target.value)
        handleAmount(event.target.value);
        if(event.target.value < 3){
        handleAmount(3);

            event.preventDefault();
            setAmountText(3)
            alert("select Amount minimum £3")
            return false;
        }
    }

    return (
        <>
            <SiteHeader />
            <section className="hero-section herosec-img-support font-face-sh" style={{ marginTop: '-160px', padding: '170px 0 40px' }}>
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-sm-12 col-md-5 text-center mx-auto'>
                            <img className='w-100' src={TopIndicator1} alt="top-Indicator" />
                        </div>
                        <div className='row text-center align-items-center justify-content-center mx-0 my-4 px-0'>
                            <div className='col-lg-5 col-md-9 col-12 bg-light rounded-3 p-4 text-center'>
                                <div className={ActiveTab ? 'tab-1':'d-none'}>
                                <h2 className='font-semibold fs-4' >Select Donation Plan</h2>

                                <ul className="nav nav-pills ml-0 d-inline-flex mb-4 donation my-3" role="tablist">
                                    <li className="nav-item">
                                        <a onClick={SetMonthly} id="monthly-tab" className={isActive ? "py-2 px-3 nav-link active" : "py-2 px-3 nav-link"} data-toggle="pill" href="#monthly" role="tab" aria-selected="true">Monthly</a>
                                    </li>
                                    <li className="nav-item">
                                        <a onClick={SetAnually} id="annually-tab" className={isActive ? "py-2 px-3 nav-link" : "py-2 px-3 nav-link active"} data-toggle="pill" href="#annually" role="tab" aria-selected="false">Annually</a>
                                    </li>
                                </ul>
                                <div className="tab-content text-center px-md-4">


                                    <h2 id="big-number " className='branding-4 font-regular'><span><span className="pound-sign position-relative" style={{ bottom: 30 }}>£</span><span id="number" className='display-1'>{AmountText}</span></span></h2>
                                    <div className="tab-pane active show" id="monthly" role="tabpanel"><p><strong>Paid Monthly</strong></p></div>
                                    <div className={isActive ? "tab-pane" : "tab"} id="annually" role="tabpanel" aria-expanded="true"><p className="mb-0 "><strong>Per Month, Paid Annually</strong></p>
                                        <p style={{ position: 'inherit', marginBottom: '-7px!important' }} className="annually-breakdown mb-0 hidden-xl-down branding-4">This means paying £<span>{AmountText*12}</span> now, for the whole year.</p>
                                    </div>
                                    <fieldset id="selected-amount">
                                        <div className="row mb-3 mt-4">
                                            <div className="col-4 pr-1">
                                                <button id="ticket_option_3" className="w-100 border-0 ticket_option px-md-5 py-2 fs-3 font-regular text-center" style={amount == '3' ? {backgroundColor:'#FFA300',color:'#fff'}:{}} onClick={() => handleAmount(3)}><span>£</span>3</button>
                                            </div>
                                            <div className="col-4 px-2">
                                                <button id="ticket_option_6" className="w-100 border-0 ticket_option px-md-5 py-2 fs-3 font-regular text-center" style={amount == '6' ? {backgroundColor:'#FFA300',color:'#fff'}:{}} onClick={() => handleAmount(6)}><span>£</span>6</button>
                                            </div>
                                            <div className="col-4 pl-1">
                                                <button id="ticket_option_12" className="w-100 border-0 ticket_option px-md-5 py-2 fs-3 font-regular text-center" style={amount == '12' ? {backgroundColor:'#FFA300',color:'#fff'}:{}} onClick={() => handleAmount(12)}><span>£</span>12</button>
                                            </div>
                                        </div>
                                        <div className="row mb-5 justify-content-end">
                                            <div className="col-4 pr-2">
                                                <button id="ticket_option_10" className="w-100 border-0 ticket_option px-md-5 py-2 fs-3 font-regular text-center" style={amount == '20' ? {backgroundColor:'#FFA300',color:'#fff'}:{}} onClick={() => handleAmount(20)}><span>£</span>20</button>
                                            </div>
                                            <div className="col-8 dark-grey pl-2 position-relative " id="custom-input">
                                                <input onChange={hndleInputAmount} type="number" min='3' id="amount-input" className='w-100 ps-5 py-1 fs-2 font-regular' name="custom-amount" value={AmountText} />
                                                <p className='position-absolute fs-4 font-regular' style={{ top: 13, left: 33 }}>£</p>
                                            </div>
                                            <div className="col-12 pl-3 form-errors error d-none">
                                                <p className="error-msg text-start">Please enter an amount between 3 and 100,000</p>
                                            </div>
                                        </div>
                                        <h5 className="text-start">Joining parkrun forever means:</h5>
                                        <ul className="text-start mb-4 mt-3 plan-benefits">
                                            <li>keeping parkrun free, for everyone, forever</li>
                                            <li>free entry into our monthly Thank You Draw</li>
                                            <li>regular updates from parkrun HQ</li>
                                        </ul>
                                        <Link to={"/donation-registration"} className='d-block w-100 text-white text-decoration-none fs-6 font-regular site-button-primary py-3 px-5 text-decoration-none rounded-3'>Donate now</Link>
                                    </fieldset>
                                </div>
                                </div>
                                <div className={ActiveTab ? 'd-none':'px-md-4 tab-2'}>
                                <h2 className='font-semibold fs-4' >Alternatively, enter for free without making a donation by clicking 'Post' below.</h2>
                                <hr/>
                                <p className='font-light text-start'>While we encourage donations to support our mission, we understand that some people may not be able to donate.</p>
                                <h2 className='font-semibold fs-4 py-3'>For 3 entries to the Thank You Draw</h2>
                                    <h3 className='font-regular fs-4 text-start'>Please post the following information:</h3>
                                <div className="tab-content text-center px-md-4">
                                        <ul className="text-start mb-4 mt-3 px-4 p-md-0 plan-benefits">
                                            <li>First Name</li>
                                            <li>Last Name</li>
                                            <li>Email</li>
                                            <li>parkrun ID</li>
                                        </ul>
                                </div>
                                <p className='font-light text-start'>to:</p>
                                <h3 className='font-regular fs-4 text-start'>PFW Holdings Ltd <br/>Suite #300, 4 Blenheim Court,<br/>Peppercorn Close,<br/>Peterborough, PE1 2DU</h3>
                                <hr/>
                                <p className='font-light'>Postal entries received after 30th of every month, will be entered into next month’s Thank You Draw.</p>
                                        <Link to={"/terms-conditions"} className=' fs-6 font-semibold  py-3 px-5 text-dark  rounded-3'>Full terms and conditions</Link>
                                </div>
                                <div className="my-5 mb-2 font-light">
                                    <p>Alternatively, enter for free without making a donation by clicking 'Post' below.</p>
                                    <ul className="nav font-regular nav-pills ml-0 d-inline-flex w-md-50 w-sm-75 mb-4 online-post-tab">
                                        <li className="nav-item w-50">
                                            <a onClick={SetOnline} className={ActiveTab ? "nav-link  active":"nav-link"} href="#online-donation">Online</a>
                                        </li>
                                        <li className="nav-item w-50">
                                            <a onClick={SetPost} className={ActiveTab ? "nav-link ":"nav-link  active"}  href="#post-donation">Post</a>
                                        </li>
                                    </ul>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Sitefooter/>

        </>
    )
}

