const Reducer = (state='Monthly',action)=>{
    if(action.type === 'setPeriod'){
        state=action.payload;
        return state;
    }else{
        return state;
    }
}

export default Reducer;
