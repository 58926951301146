import { React, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import SiteHeader from '../components/SiteHeader';
import Sitefooter from '../components/footer';
import RecdonationAccountBanner from '../components/recdonation-account-banner';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import { useLocation } from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import apiClient from '../api.config';
import useDocumentTitle from '../components/useDocumentTitle';
import { toast, ToastContainer } from 'react-toastify';
import RecdonationAccountSupport from '../components/recdonation-account-support';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

export default function MyDonationDetails() {
    useDocumentTitle('Edit Profile');

    const [open, setOpen] = useState(false);
    // const navigate = useNavigate();
    let userDetail = secureLocalStorage.getItem('LogObject');
    let siteID = "146a712f-5df6-4056-a65b-e7dce24f7aa0"
    const siteId = process.env.REACT_APP_SITE_ID;

    const location = useLocation()
    const [parkrunId, SetParkrunID] = useState('');
    const [name, SetName] = useState('')
    const [entrydata, SetEntryData] = useState(null)
    const [allData, SetAllData] = useState(null)
    const [fname, SetFname] = useState('');
    const [lname, SetLname] = useState('');
    const [email, SetEmail] = useState('');
    const [password, SetPassword] = useState('');
    // const [giftaid, SetGiftaid] = useState(false);
    const [address1, SetAddress1] = useState('');
    const [address2, SetAddress2] = useState('');
    const [postcode, SetPostCode] = useState('');
    const [town, SetTown] = useState('');
    const [SubscriptionPeriod, SetSubscriptionPeriod] = useState('');
    const [claimGift, setClaimgift] = useState(false);
    const [conscent1, setconscent1] = useState(false);
    const [conscent2, setconscent2] = useState(false);
    const [userPhoneNumber, setUserPhoneNumber] = useState('');

    const navigate = useNavigate()


    useEffect(() => {
        // console.log(JSON.parse(location.state.allData.userMeta).town)

        if (userDetail === null) {
            navigate('/login');
        } else {

            axios.get(`${process.env.REACT_APP_API_URL}/api/v1/users/${userDetail.userID}/data/${siteID}`, {
                withCredentials: true,
                credentials: "same-origin"
            }).then(res => {
                console.log(res);
                if (res.data.status) {
                    let entry = res.data.content.drawEntries;
                    SetName(res.data.content.userFirstName)
                    SetAllData(res.data.content)
                    SetEntryData(res.data.content.drawEntries);
                    SetFname(res.data.content.userFirstName);
                    SetLname(res.data.content.userSurname);
                    SetEmail(res.data.content.userEmail);
                    setUserPhoneNumber(res.data.content.userPhoneNumber)
                    SetAddress1(res.data.content.userAddressLine1);
                    SetAddress2(res.data.content.userAddressLine2);
                    SetPostCode(res.data.content.userAddressPostcode);
                    SetTown(JSON.parse(res.data.content.userMeta).town);
                    setClaimgift(JSON.parse(res.data.content.userMeta)?.parkrunGiftAid);
                    setconscent1(JSON.parse(res.data.content.userMeta)?.parkrunEmailForPrizeConscent);
                    setconscent2(JSON.parse(res.data.content.userMeta)?.parkrunPostForPrizeConscent);
                    SetSubscriptionPeriod(JSON.parse(res.data.content.userMeta).SubscriptionPeriod);
                    SetParkrunID(JSON.parse(res.data.content.userMeta).parkrunId);
                }
            })

        }
        if (address1?.trim().length != 0 && address2?.trim().length != 0 && postcode?.trim().length != 0) {
            setClaimgift(true);
        }

        return () => {
            document.body.classList.remove('bg-branding-2')
        }
    }, []);

    async function DiscardChanges() {
        axios.get(`${process.env.REACT_APP_API_URL}/api/v1/users/${userDetail.userID}/data/${siteID}`, {
            withCredentials: true,
            credentials: "same-origin"
        }).then(res => {
            console.log(res);
            if (res.data.status) {
                let entry = res.data.content.drawEntries;
                SetName(res.data.content.userFirstName)
                SetAllData(res.data.content)
                SetEntryData(res.data.content.drawEntries);
                SetFname(res.data.content.userFirstName);
                SetLname(res.data.content.userSurname);
                SetEmail(res.data.content.userEmail);
                setUserPhoneNumber(res.data.content.userPhoneNumber);
                SetAddress1(res.data.content.userAddressLine1);
                SetAddress2(res.data.content.userAddressLine2);
                SetPostCode(res.data.content.userAddressPostcode);
                SetTown(JSON.parse(res.data.content.userMeta).town);
                setClaimgift(JSON.parse(res.data.content.userMeta)?.parkrunGiftAid);
                setconscent1(JSON.parse(res.data.content.userMeta)?.parkrunEmailForPrizeConscent);
                setconscent2(JSON.parse(res.data.content.userMeta)?.parkrunPostForPrizeConscent);
                SetSubscriptionPeriod(JSON.parse(res.data.content.userMeta).SubscriptionPriod);
                SetParkrunID(JSON.parse(res.data.content.userMeta).parkrunId);
            }
        }).then(() =>
            setOpen(false)
        )
    }



    async function UpdateUser() {
        const response = await toast.promise(apiClient.put(
            `${process.env.REACT_APP_API_URL}/api/v1/users`,
            {
                "userID": allData.userID,
                "userFirstName": fname,
                "userSurname": lname,
                "userEmail": email,
                "userAddressLine1": address1,
                "userAddressLine2": address2,
                "userPhoneNumber": userPhoneNumber,
                "userAddressPostcode": postcode,
                "userMeta": {
                    "Website": "Park Run Forver",
                    "parkrunId": parkrunId,
                    "town": town,
                    "SubscriptionPeriod": SubscriptionPeriod,
                    "parkrunGiftAid": claimGift,
                    "parkrunEmailForPrizeConscent": conscent1,
                    "parkrunPostForPrizeConscent": conscent2,
                }
            }
            ,
            {
                withCredentials: true,
                credentials: "same-origin"
            }
        ).then(() => {
            DiscardChanges();
            setOpen(false);
        }
        ),
            {
                pending: 'Please wait...',
                success: 'Profile Updated',
                error: 'Something went Wrong while updating'
            }
        );

    }

    async function ChangePassword() {
        const response = await toast.promise(apiClient.post(
            `${process.env.REACT_APP_API_URL}/api/v1/auth/request`,
            {
                userEmail: email,
            },
            {
                withCredentials: true,
                credentials: "same-origin"
            }
        ),
            {
                pending: 'Please wait...',
                success: 'Thank you! If we have record of a user with your email address, you will receive an email with a link to reset your password.',
                error: 'Something went Wrong'
            }
        );

    }



    return (
        <>
            <SiteHeader />

            <section className='bg-branding-2 font-face-sh font-regular' style={{ marginTop: '-110px', padding: '160px 0 20px' }}>
                <RecdonationAccountBanner />

                <nav className="account-nav-recdonation">
                    <div className="container">
                        <div className="d-flex flex-row justify-content-center flex-wrap">
                            <div className="p-2">
                                <Link to={'/account/my-donation-entries'} state={{ name: name, entrydata: entrydata, allData: allData }} className="btn btn-100 px-2 py-0 border-0 text-light">My Entries</Link>
                            </div>
                            <div className="p-2">
                                <Link to={'/account/my-donation-plan'} state={{ name: name, entrydata: entrydata, allData: allData }} className="btn btn-100 px-2 py-0 border-0 text-light">My Donation Plan</Link>
                            </div>
                            <div className="p-2">
                                <Link to={'/account/my-prizes-won'} state={{ name: name, entrydata: entrydata, allData: allData }} className="btn btn-100 px-2 py-0 border-0 text-light">Prizes Won</Link>
                            </div>
                            <div className="p-2">
                                <Link to={'/account/my-subscriptions'} state={{ name: name, entrydata: entrydata, allData: allData }} className="btn btn-100 px-2 py-0 border-0 text-light">My Subscriptions</Link>
                            </div>
                            <div className="p-2">
                                <Link to={'/account/my-payments'} state={{ name: name, entrydata: entrydata, allData: allData }} className="btn btn-100 px-2 py-0 border-0 text-light">My Payments</Link>
                            </div>
                            <div className="p-2">
                                <Link to={'/account/my-donation-details'} state={{ name: name, entrydata: entrydata, allData: allData }} className="btn btn-100 px-2 py-0 border-0 active">My Details</Link>
                            </div>
                        </div>
                    </div>
                </nav>

                <div className="page account pt-4">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                                <div className="card mb-4 content-card your-entries" style={{ maxWidth: 500, margin: 'auto', borderRadius: 10 }}>
                                    <div className="card-header text-center bg-white px-md-5 px-3 pt-5 border-0">
                                        <h2 className="text-center fs-4 fw-6"><span>My Details</span></h2>
                                        <hr />
                                        {!open ?
                                            <button type="button" id="card_token_button2" onClick={() => setOpen(!open)} className="btn btn-details">Edit Details</button>
                                            : <div className='d-flex gap-2 justify-content-center'>
                                                <button type="button" id="card_token_button2" onClick={UpdateUser} className="btn btn-details">Save</button>
                                                <button type="button" id="card_token_button2" onClick={DiscardChanges} className="btn btn-details">Discard Edit</button>
                                            </div>
                                        }


                                        {/* <Button onClick={() => setOpen(!open)} aria-controls="first-name-collapse" aria-expanded={open} className="edit-btn mb-0 float-end">Edit Details</Button> */}

                                    </div>
                                    <div className="card-block card-my-details px-md-5 px-3 pb-4">
                                        {/* TO DO: Add action to the form */}

                                        <p data-label="Actions" style={{ display: "none" }}>
                                            {/* <Button onClick={() => setOpen(!open)} aria-controls="card-div" aria-expanded={open} className="edit-btn last mb-3">Change Card</Button> */}
                                        </p>
                                        <div style={{ display: 'none' }}>
                                            <input type="hidden" id="stripe_card_token_token" name="stripe_card_token[token]" defaultValue="tok_1M43pLFm8g2Q0TJgwqM7lDLG" />
                                            <div><label htmlFor="stripe_card_token_provider" className="required">Provider</label>
                                                <select id="stripe_card_token_provider" name="stripe_card_token[provider]"><option value="WORLDPAY">WORLDPAY</option><option value="STRIPE" >STRIPE</option><option value="PAYPAL">PAYPAL</option></select></div>
                                            <input type="hidden" id="stripe_card_token_cardType" name="stripe_card_token[cardType]" defaultValue="VISA" /><input type="hidden" id="stripe_card_token_maskedCardNumber" name="stripe_card_token[maskedCardNumber]" defaultValue="**** **** **** 5031" /><input type="hidden" id="stripe_card_token_expMonth" name="stripe_card_token[expMonth]" /><input type="hidden" id="stripe_card_token_expYear" name="stripe_card_token[expYear]" /><input type="hidden" id="stripe_card_token__token" name="stripe_card_token[_token]" defaultValue="MSAFMnAbK4gSP1xJmDoeYlf5VQar3iBMVRDxvSth1fs" />
                                        </div>
                                        <h3 className="mt-4 mb-2 fs-6 fw-6"><span><span>Account details</span></span></h3>
                                        <hr style={{ display: "none" }} />
                                        <div className="mb-4">
                                            <div className="row py-3 align-items-center" style={{ borderBottom: '1px solid #dadada' }}>
                                                <div className="col-md-4 col-12 p fs-14 fw-6"><strong>parkrun ID</strong></div>
                                                <div className="col-md-8 col-12 p">
                                                    {!open ? <p className="dark-grey fs-14">{parkrunId}</p> : ''}
                                                        {/* <Button onClick={() => setOpen(!open)} aria-controls="parkrun-id" aria-expanded={open} className="edit-btn mb-0 float-end">Edit</Button> */}
                                                    <Collapse in={open}>
                                                        <div className="collapse mt-2" id="parkrun-id">
                                                            <div className="form-group field-wrap">
                                                                <div className="form-control-wrap">
                                                                    <input type="text" id="donation_my_details_parkrunId" onChange={(e) => { SetParkrunID(e.target.value) }} defaultValue={parkrunId} name="donation_my_details[parkrunId]" data-address="parkrunId" placeholder="parkrun ID" className="form-control" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Collapse>
                                                </div>

                                            </div>
                                            <div className="row py-3 align-items-center" style={{ borderBottom: '1px solid #dadada' }}>
                                                <div className="col-md-4 col-12 p fs-14 fw-6"><strong>First Name</strong></div>
                                                <div className="col-md-8 col-12 p">
                                                    {!open ? <p className="dark-grey fs-14 mb-0">{fname}</p> : ''}
                                                        {/* <Button onClick={() => setOpen(!open)} aria-controls="first-name-collapse" aria-expanded={open} className="edit-btn mb-0 float-end">Edit</Button> */}
                                                    <Collapse in={open}>
                                                        <div className="collapse mt-2" id="first-name-collapse">
                                                            <div className="form-group field-wrap">
                                                                <div className="form-control-wrap">
                                                                    <input type="text" onChange={(e) => { SetFname(e.target.value) }} id="donation_my_details_firstName" name="donation_my_details[firstName]" data-address="firstName" placeholder="First Name" className="form-control" defaultValue={fname} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Collapse>
                                                </div>
                                            </div>
                                            <div className="row py-3 align-items-center" style={{ borderBottom: '1px solid #dadada' }}>
                                                <div className="col-md-4 col-12 p fs-14 fw-6"><strong>Last Name</strong></div>
                                                <div className="col-md-8 col-12 p">
                                                    {!open ? <p className="dark-grey fs-14 mb-0">{lname}</p> : ''}
                                                        {/* <Button onClick={() => setOpen(!open)} aria-controls="last-name-collapse" aria-expanded={open} className="edit-btn mb-0 float-end">Edit</Button> */}
                                                    <Collapse in={open}>
                                                        <div className="collapse mt-2" id="last-name-collapse">
                                                            <div className="form-group field-wrap">
                                                                <div className="form-control-wrap">
                                                                    <input type="text" onChange={(e) => { SetLname(e.target.value) }} id="donation_my_details_lastName" name="donation_my_details[lastName]" data-address="lastName" placeholder="Last Name" className="form-control" defaultValue={lname} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Collapse>
                                                </div>
                                                <div id="lname-error" className="col-12 form-errors error d-none">
                                                    <p className="error-msg text-left">You need to enter your last name to continue.</p>
                                                </div>
                                            </div>
                                            <div className="row py-3 align-items-center" style={{ borderBottom: '1px solid #dadada' }}>
                                                <div className="col-md-4 col-12 p fs-14 fw-6"><strong>Phone Number</strong></div>
                                                <div className="col-md-8 col-12 p">
                                                    {!open ? <p className="dark-grey fs-14 mb-0">{userPhoneNumber}</p> : ''}
                                                    <Collapse in={open}>
                                                        <div className="collapse mt-2" id="last-name-collapse">
                                                            <div className="form-group field-wrap">
                                                                <div className="form-control-wrap">
                                                                    <PhoneInput
                                                                        placeholder="Enter phone number"
                                                                        value={userPhoneNumber}
                                                                        onChange={setUserPhoneNumber}
                                                                        defaultCountry="GB"
                                                                        className="form-control"
                                                                    />
                                                                    {/* <input type="text" onChange={(e)=>{setUserPhoneNumber(e.target.value)}} id="donation_my_details_lastName" name="donation_my_details[userPhoneNumber]" data-address="userPhoneNumber" placeholder="Last Name" className="form-control" defaultValue={userPhoneNumber} /> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Collapse>
                                                </div>
                                            </div>
                                            <div className="row py-3" style={{ borderBottom: '1px solid #dadada' }}>
                                                <div className="col-md-4 col-12 p fs-14 fw-6"><strong>Email Address</strong></div>
                                                <div className="col-md-8 col-12 p">
                                                    <p className="dark-grey fs-14 mb-0">{email}</p>
                                                </div>
                                            </div>

                                            <div className="row py-3" style={{ borderBottom: '1px solid #dadada' }}>
                                                <div className="col-4 p fs-14 fw-6"><strong>Password:</strong></div>
                                                <div className="col-8 p fs-14">
                                                    <button className='bg-white border-0 text-muted text-decoration-underline' onClick={ChangePassword}>Change Password</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="gift-aid" style={{ marginBottom: 25 }}>
                                            <label className="control control-checkbox edit-checkbox">
                                                Claim Gift Aid at no extra cost,<br />adding an extra 25p to every £1 donated
                                                {/* onChange={(e)=>e.target.checked=claimGift} */}
                                                <input type="checkbox" className={claimGift ? "checked" : ''} onChange={(e) => setClaimgift(e.target.checked)} defaultChecked={claimGift} id="gift-aid-input" name="gift-aid" />
                                                <div className="control_indicator" />
                                            </label>
                                        </div>
                                        <div className="collapse" id="gift-aid-fields" style={{ display: 'block' }}>
                                            <div className="form-group field-wrap">
                                                <div className="form-control-wrap">
                                                    <input type="text" readOnly={!open} onChange={(e) => { SetAddress1(e.target.value) }} id="donation_my_details_houseNameNumber" name="donation_my_details[houseNameNumber]" data-address="houseNameNumber" className="form-control p" placeholder="Address Line 1" defaultValue={address1} />
                                                </div>
                                            </div>
                                            <div className="form-group field-wrap">
                                                <div className="form-control-wrap">
                                                    <input type="text" readOnly={!open} onChange={(e) => { SetAddress2(e.target.value) }} id="donation_my_details_street" name="donation_my_details[street]" data-address="street" className="form-control p" placeholder="Address Line 2 (Optional)" defaultValue={address2} />
                                                </div>
                                            </div>
                                            <div className="form-group field-wrap">
                                                <div className="form-control-wrap">
                                                    <input type="text" readOnly={!open} onChange={(e) => { SetTown(e.target.value) }} id="donation_my_details_town" name="donation_my_details[town]" data-address="town" className="form-control p" placeholder="Town" defaultValue={town} />
                                                </div>
                                            </div>
                                            <div className="form-group field-wrap">
                                                <div className="form-control-wrap">
                                                    <input type="text" readOnly={!open} onChange={(e) => { SetPostCode(e.target.value) }} id="donation_my_details_postcode" name="donation_my_details[postcode]" data-address="postcode" className="form-control p" placeholder="Postcode" defaultValue={postcode} />
                                                </div>
                                            </div>
                                            <div id="postcode-error" className="col-12 pl-0 form-errors error d-none" style={{ top: '-10px' }}>
                                                <p className="error-msg text-left">Please fill in this field!</p>
                                            </div>
                                            <p className="pb-3 pt-2">I confirm that I have, or will pay an amount of Income and/or Capital
                                                Gains tax from 6 April to 5 April that is at least equal to the amount of tax that all the
                                                charities that I donate to will reclaim on my gifts for that tax year.</p>
                                            <p className="pb-3">I understand that other taxes like Council Tax do not qualify</p>
                                            <p className="pb-3">I understand parkrun will reclaim 25p of tax on every £1 that I have given <br /><br />I understand that if I pay less Income Tax / or Capital Gains tax in the current tax year than the amount of Gift Aid claimed on all of my donations it is my responsibility to pay any difference.</p>
                                            <label className="control control-checkbox edit-checkbox">
                                                Confirm Gift Aid Declaration
                                                <input type="checkbox" disabled={!open} className={claimGift ? "checked" : ''} onChange={(e) => setClaimgift(e.target.checked)} defaultValue={claimGift} id="gift-aid-declaration-input" name="gift-aid-declaration" required="required" />
                                                <div className="control_indicator" />
                                            </label>
                                            <div id="confirmgiftaid-error" className="col-12 pl-0 form-errors error d-none">
                                                <p className="error-msg text-left">You need to confirm Gift Aid Declaration to continue</p>
                                            </div>
                                        </div>
                                        <fieldset className="details-edit">
                                            <h3 className="mt-4 mb-2 fs-6 fw-6"><span><span>Updates Preferences</span></span></h3>
                                            <p className="mb-2">parkrun forever would like to keep you in the loop, please select the options below:</p>
                                            <label className="control control-checkbox edit-checkbox mb-3">
                                                Email for prize updates, charity stories and offers
                                                <input type="checkbox" disabled={!open} className={`${conscent1 ? "checked" : ''}`} onChange={(e) => setconscent1(e.target.checked)} id="email-check" name="terms" required="required" />
                                                <div className="control_indicator" />
                                            </label>
                                            <label className="control control-checkbox edit-checkbox mb-4">
                                                Post for parkrun forever updates and rewards
                                                <input type="checkbox" disabled={!open} className={conscent2 ? "checked" : ''} onChange={(e) => setconscent2(e.target.checked)} id="post-check" name="terms" required="required" />
                                                <div className="control_indicator" />
                                            </label>
                                            <div style={{ display: 'none' }}>
                                                <div><label className="p" htmlFor="donation_my_details_funderEmail">Email for prize updates, charity stories and offers</label><input type="checkbox" id="donation_my_details_funderEmail" name="donation_my_details[funderEmail]" className="form-check-input" defaultValue={1} defaultChecked="checked" /></div><div><label className="p" htmlFor="donation_my_details_funderPost">Post for gifts and membership news</label><input type="checkbox" id="donation_my_details_funderPost" name="donation_my_details[funderPost]" className="form-check-input" defaultValue={1} defaultChecked="checked" /></div><div><label htmlFor="donation_my_details_countryCode">Country</label><input type="text" id="donation_my_details_countryCode" name="donation_my_details[countryCode]" data-address="country" className="form-control p" placeholder="Country" defaultValue="GB" /></div><div><label htmlFor="donation_my_details_county">County</label><input type="text" id="donation_my_details_county" name="donation_my_details[county]" data-address="county" className="form-control p" placeholder="County" /></div><div><label htmlFor="donation_my_details_addressReference">Address</label><input type="text" id="donation_my_details_addressReference" name="donation_my_details[addressReference]" data-address="addressReference" className="form-control p" placeholder="Address" /></div><div><label className="p" htmlFor="donation_my_details_giftaid">Giftaid</label><input type="checkbox" id="donation_my_details_giftaid" name="donation_my_details[giftaid]" className="form-check-input" defaultValue={1} defaultChecked="checked" /></div><input type="hidden" id="donation_my_details__token" name="donation_my_details[_token]" defaultValue="nI3Ve4kJ83PzciIiBwCDecBjtx3oQus3GBIom6N-Y3E" />
                                            </div>
                                            <div className="d-flex flex-row mt-5">
                                                <div className="col p-0 pr-2">
                                                    {open ?
                                                        <div className='d-flex gap-2 justify-content-center'>
                                                            <button type="button" id="card_token_button2" onClick={UpdateUser} className="btn btn-details">Save</button>
                                                            <button type="button" id="card_token_button2" onClick={DiscardChanges} className="btn btn-details">Discard Edit</button>
                                                        </div>
                                                        : ''}
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <RecdonationAccountSupport />
            </section>

            <Sitefooter />
            <ToastContainer />
        </>
    )
}
