import React from "react";
import Slider from "react-slick";

export default function AccountPrizeslider(props) {
  var settings = {
    dots: props.dots ? true:false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay:true,
    arrows:props.arrows ? true:true ,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (

    <Slider {...settings}>
      <div className="col-md-4 col-sm-1">
        <div className="position-relative">
          <img className="w-100" src={require('../images/account/flatbands.jpeg')}  />
        </div>
      </div>
      <div className="col-md-4 col-sm-1">
        <div className="position-relative">
          <img className="w-100" src={require('../images/account/mug.jpeg')}  />
        </div>
      </div>
      <div className="col-md-4 col-sm-1">
        <div className="position-relative">
          <img className="w-100" src={require('../images/account/apricot-hoodie.jpeg')}  />
        </div>
      </div>
      <div className="col-md-4 col-sm-1">
        <div className="position-relative">
          <img className="w-100" src={require('../images/account/contra-ls-tee-black.jpeg')}  />
        </div>
      </div>
      <div className="col-md-4 col-sm-1">
        <div className="position-relative">
          <img className="w-100" src={require('../images/account/water-bottles.jpeg')}  />
        </div>
      </div>

 
    </Slider>

  )
}
