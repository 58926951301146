const Reducer = (state=6,action)=>{
    if(action.type === 'setAmount'){
        state=action.payload;
        return state;
    }
    else{
        return state;
    }
}

export default Reducer;
