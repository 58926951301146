import React from 'react'
import { Link } from 'react-router-dom'
import '../App.css'
import { useEffect } from 'react'
import SiteHeader from '../components/SiteHeader';
import Sitefooter from '../components/footer';
import useDocumentTitle from '../components/useDocumentTitle'

export default function PrivacyPolicy() {
    useDocumentTitle('Privacy Policy');
    useEffect(() => {
        document.body.classList.add('bg-branding-2');
        return () => {
            document.body.classList.remove('bg-branding-2')
        }
    }, [])
    return (
        <>
            <SiteHeader />
            <div className="container">
                <h1><span>&nbsp;</span></h1>
                <h1 className="mb-5" style={{ textAlign: 'left', fontSize: '2.1rem', lineHeight: 1, color: 'white' }}><span><strong>parkrun Forever Privacy Policy</strong></span></h1>
                <p style={{color:'#b4b4b4'}}>&nbsp;</p>
                <h4 className="text-white"><span style={{ fontWeight: 400 }}>OUR APPROACH</span></h4>
                <ol>
                    <li>
                        <p style={{color:'#b4b4b4'}}><span style={{ fontWeight: 400 }}>This Privacy Policy (the </span><strong>“Policy”</strong><span style={{ fontWeight: 400 }}> ) sets out how we, PFW Holdings Ltd, the donation platform operators of parkrun Forever, with offices at PFW Holdings Ltd Suite#300, 4 Blenheim Court, Peppercorn Close, Peterborough, PE1 2DU, process the personal data of visitors to the website, </span><a href="http://www.parkrunforever.com"><span style={{ fontWeight: 400 }}>www.parkrunforever.com</span></a><span style={{ fontWeight: 400 }}> &nbsp;participants in our promotions (</span><strong>“User”</strong><span style={{ fontWeight: 400 }}>).</span></p>
                    </li>
                    <li>
                        <p style={{color:'#b4b4b4'}}><span style={{ fontWeight: 400 }}>If you have any questions about this Policy, please contact us at <a href="mailto:hello@parkrunforever.com?">hello@playfundwin.com</a> </span></p>
                    </li>
                </ol>
                <h4 className="text-white"><span style={{ fontWeight: 400 }}>PERSONAL DATA</span></h4>
                <ol>
                    <li>
                        <p style={{color:'#b4b4b4'}}><span style={{ fontWeight: 400 }}>Personal data means any information relating to a person who can be identified either directly or indirectly; it may include name, gender, date of birth, email address, username, photo, IP address, information about preferences, patterns of behaviour, and location data (</span><strong>“Personal Data”</strong><span style={{ fontWeight: 400 }}>).</span></p>
                    </li>
                </ol>
                <h4 className="text-white"><span style={{ fontWeight: 400 }}>DATA CONTROLLERS</span></h4>
                <ol>
                    <li>
                        <p style={{color:'#b4b4b4'}}><span style={{ fontWeight: 400 }}>The successful operation of parkrun Forever requires that parkrun Limited, with offices at PFW Holdings Ltd Suite #300, 4 Blenheim Court, Peppercorn Close, Peterborough, PE1 2DU acts as a data controller, and that PFW Holdings Ltd. act as a data processor.</span></p>
                    </li>
                    <li>
                        <p style={{color:'#b4b4b4'}}><span style={{ fontWeight: 400 }}>parkrun Limited will process and control the data according to pakrun’s Privacy Policy. You can find the link to parkrun’s Privacy Policy&nbsp;<a href="http://www.parkrun.com/privacy/">here</a>.&nbsp;</span></p>
                    </li>
                </ol>
                <h4 className="text-white"><span style={{ fontWeight: 400 }}>HOW WE USE DATA &amp; CONSENT</span></h4>
                <ol>
                    <li>
                        <p style={{color:'#b4b4b4'}}><span style={{ fontWeight: 400 }}>We will only process your Personal Data, in accordance with applicable law, for the following purposes:</span></p>
                    </li>
                </ol>
                <p style={{color:'#b4b4b4'}}><span style={{ fontWeight: 400 }}>(a) creating and maintaining your user account; </span></p>
                <p style={{color:'#b4b4b4'}}><span style={{ fontWeight: 400 }}>(b) offering goods, services and suggestions to you in a personalised way. With your permission, for example, you may receive communications by emails, post, fax, phone or mobile messaging related to charitable updates or promotions. You can unsubscribe from these promotional communications at any time;</span></p>
                <p style={{color:'#b4b4b4'}}><span style={{ fontWeight: 400 }}>(c) enabling our suppliers and service providers to carry out certain functions on our behalf, including identity, verification, technical, logistical or other functions, as may be required, in order to fulfil your orders;</span></p>
                <p style={{color:'#b4b4b4'}}><span style={{ fontWeight: 400 }}>(d) listing or combining your Personal Data with other information we get from third parties to help understand your needs and provide you with a better service;</span></p>
                <p style={{color:'#b4b4b4'}}><span style={{ fontWeight: 400 }}>(e) ensuring the security of your account and our business, preventing or detecting fraud or abuses of our website;</span></p>
                <p style={{color:'#b4b4b4'}}><span style={{ fontWeight: 400 }}>(f) developing and improving our services, for example, by reviewing use of our website and services;</span></p>
                <p style={{color:'#b4b4b4'}}><span style={{ fontWeight: 400 }}>(g) to comply with applicable law, for example, in response to a request from a court or regulatory body, where such request is made in accordance with the law;<br /><br /></span></p>
                <ol start={2}>
                    <li>
                        <p style={{color:'#b4b4b4'}}><span style={{ fontWeight: 400 }}>The legal basis for us processing your Personal Data for the purposes described above will typically be because you have provided us with your consent. However, we may also rely on other legal grounds, for example, where the processing is necessary:</span></p>
                    </li>
                </ol>
                <p style={{color:'#b4b4b4'}}><span style={{ fontWeight: 400 }}>(a) to fulfil a contract that we have in place with you;</span></p>
                <p style={{color:'#b4b4b4'}}><span style={{ fontWeight: 400 }}>(b) for our legitimate business interests; or</span></p>
                <p style={{color:'#b4b4b4'}}><span style={{ fontWeight: 400 }}>(c) for compliance with a legal obligation to which we are subject.<br /><br /></span></p>
                <h4 className="text-white"><span style={{ fontWeight: 400 }}>DISCLOSURE</span></h4>
                <ol>
                    <li>
                        <p style={{color:'#b4b4b4'}}><span style={{ fontWeight: 400 }}>There are circumstances where we wish to disclose or are compelled to disclose your Personal Data to third parties. This will only take place in accordance with applicable law and for the purposes listed above. These scenarios include disclosure:</span></p>
                    </li>
                </ol>
                <p style={{color:'#b4b4b4'}}><span style={{ fontWeight: 400 }}>(a) to our subsidiaries, branches or associated offices;</span></p>
                <p style={{color:'#b4b4b4'}}><span style={{ fontWeight: 400 }}>(b) to our outsourced service providers or suppliers to facilitate the provision of our services to our Users, for example, the disclosure to our data centre provider for the safe keeping of your Personal Data; or financial institutions to complete a prize payout;</span></p>
                <p style={{color:'#b4b4b4'}}><span style={{ fontWeight: 400 }}>(c) to third party service providers and consultants in order to protect the security or integrity of our business, including our databases and systems and for business continuity reasons;</span></p>
                <p style={{color:'#b4b4b4'}}><span style={{ fontWeight: 400 }}>(d) to another legal entity, on a temporary or permanent basis, for the purposes of a joint venture, collaboration, financing, sale, merger, reorganisation, change of legal form, dissolution or similar event. In the case of a merger or sale, your Personal Data will be permanently transferred to a successor company;</span></p>
                <p style={{color:'#b4b4b4'}}><span style={{ fontWeight: 400 }}>(e) to public authorities where we are required by law to do so; and</span></p>
                <p style={{color:'#b4b4b4'}}><span style={{ fontWeight: 400 }}>(f) to any other third party where you have provided your consent.<br /><br /></span></p>
                <h4 className="text-white"><span style={{ fontWeight: 400 }}>INTERNATIONAL TRANSFER</span></h4>
                <ol>
                    <li>
                        <p style={{color:'#b4b4b4'}}><span style={{ fontWeight: 400 }}>We may transfer your Personal Data to a third party in countries outside the country from which it was originally collected for further processing in accordance with the purposes set out in paragraph 3 above. In particular, your Personal Data may be transferred and to our outsourced service providers located abroad. In these circumstances we will, as required by applicable law, ensure that your privacy rights are adequately protected by appropriate technical, organisation, contractual or other lawful means. Please contact us for a copy of the safeguards which we have put in place to protect your Personal Data and privacy rights in these circumstances.</span></p>
                    </li>
                </ol>
                <h4 className="text-white"><span style={{ fontWeight: 400 }}>RETENTION</span></h4>
                <ol>
                    <li>
                        <p style={{color:'#b4b4b4'}}><span style={{ fontWeight: 400 }}>Your Personal Data will be retained for so long as you are registered with us or your details are required to provide our services, unless longer retention is required to comply with regulatory mandates or where we have a legitimate and lawful reason.</span></p>
                    </li>
                    <li>
                        <p style={{color:'#b4b4b4'}}><span style={{ fontWeight: 400 }}>We may keep an anonymized form of your Personal Data, which will no longer refer to you, for statistical purposes without time limits, to the extent permitted by applicable law.</span></p>
                    </li>
                    <li>
                        <p style={{color:'#b4b4b4'}}><span style={{ fontWeight: 400 }}>All Personal Data is stored in highly secure data centres that comply with the latest ISO 27001 security management standards.</span></p>
                    </li>
                </ol>
                <h4 className="text-white"><span style={{ fontWeight: 400 }}>DATA SUBJECT RIGHTS</span></h4>
                <ol>
                    <li>
                        <p style={{color:'#b4b4b4'}}><span style={{ fontWeight: 400 }}>Data protection law provides individuals in certain countries with numerous rights, including the right to: access, rectify, erase, restrict, transport, and object to the processing of, their Personal Data. Individuals may also have the right to lodge a complaint with the relevant data protection authority if they believe that their Personal Data is not being processed in accordance with applicable data protection law. If you would like to exercise any of your rights, please contact us at: hello@playfundwin.com</span></p>
                    </li>
                </ol>
                <h4 className="text-white"><span style={{ fontWeight: 400 }}>ANALYTICS</span></h4>
                <ol>
                    <li>
                        <p style={{color:'#b4b4b4'}}><span style={{ fontWeight: 400 }}>In order to collect the information as described above, we may use analytics technology on our website, including Google Analytics, among others. This technology collects information, usually in an anonymous form about how Users use our website. This is accomplished by placing a small text file or ‘cookie’ on a User’s computer. This allows the website to remember convenient information that will allow for a better website experience.</span></p>
                    </li>
                </ol>
                <p style={{color:'#b4b4b4'}}><span style={{ fontWeight: 400 }}>Users can also opt out of Google’s advertising tracking cookie </span><a href="https://www.google.com/policies/technologies/ads/"><span style={{ fontWeight: 400 }}>here</span></a><span style={{ fontWeight: 400 }}>, or use a browser plugin to opt out of all Google Analytics tracking software found </span><a href="https://www.google.com/settings/ads/plugin"><span style={{ fontWeight: 400 }}>here.</span></a><br /><br /></p>
                <h4 className="text-white"><span style={{ fontWeight: 400 }}>LINKED WEBSITES</span></h4>
                <ol>
                    <li>
                        <p style={{color:'#b4b4b4'}}><span style={{ fontWeight: 400 }}>Please note that any websites that may be linked to our websites are subject to their own privacy policy.</span></p>
                    </li>
                </ol>
                <h4 className="text-white"><span style={{ fontWeight: 400 }}>POLICY CHANGES</span></h4>
                <ol>
                    <li>
                        <p style={{color:'#b4b4b4'}}><span style={{ fontWeight: 400 }}>Any changes we make to our privacy policy in the future will be posted on this page and, where appropriate, notified to you by e-mail. Please check back frequently to see any updates or changes to our privacy policy.</span></p>
                    </li>
                </ol>
                <p style={{color:'#b4b4b4'}}>&nbsp;</p>
            </div>





            <Sitefooter/>
        </>
    )
}

