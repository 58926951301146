import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import axios from "axios";
import apiClient from "../api.config";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
import { addPaymentInfo, purchase } from "../utils/meta/metaPixelEvent";

const StripeForm = (props) => {
  const [email, setEmail] = useState("");
  const [amount, setAmount] = useState("");
  const [userName, setName] = useState("");
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const [parkrunId, setparkrunId] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [address1, setaddress1] = useState("");
  const [address2, setaddress2] = useState("");
  const [postcode, setpostcode] = useState("");
  const [period, setPeriod] = useState("");
  const [payType, setPayType] = useState("");
  const [editMode, setEditMode] = useState("new");
  const [editTotal, setEditTotal] = useState(0);
  const [editSubID, setEditSubID] = useState(0);

  const [town, setTown] = useState("");
  const [parkrunGiftAid, setparkrunGiftAid] = useState(false);
  const [parkrunEmailForPrizeConscent, setparkrunEmailForPrizeConscent] =
    useState(false);
  const [parkrunPostForPrizeConscent, setparkrunPostForPrizeConscent] =
    useState(false);
  const [login, setLogin] = useState(
    secureLocalStorage.getItem("LogObject")
      ? secureLocalStorage.getItem("LogObject").loggedin
      : false
  );
  // = {ConfirmGift}
  // ={ cb1 }
  // ={ cb2 }
  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  useEffect(() => {
    setEmail(props.email);
    setAmount(props.amount);
    setName(props.userName);
    setparkrunId(props.parkrunId);
    setUserPassword(props.password);
    setaddress1(props.address1);
    setaddress2(props.address2);
    setpostcode(props.postcode);
    setPeriod(props.period);
    setPayType(props.payType ? props.payType : "Donate");
    setEditMode(props.editMode ? props.editMode : "new");
    setEditTotal(props.editTotal ? props.editTotal : 0);
    setEditSubID(props.editSubID ? props.editSubID : "");
    setTown(props.town);
    setparkrunGiftAid(props.parkrunGiftAid);
    setparkrunEmailForPrizeConscent(props.parkrunEmailForPrizeConscent);
    setparkrunPostForPrizeConscent(props.parkrunPostForPrizeConscent);
    setUserPhoneNumber(props.userPhoneNumber ? props.userPhoneNumber : "")
  }, [props]);

  const handleSubmit = (stripe) => async () => {
    // ---------------------------------------Create User ------------------------------------------
    // if (!login) {
    //   try {
    //     const createUser = await axios.post(
    //       `${process.env.REACT_APP_API_URL}/api/v1/users`,
    //       {
    //         userFirstName: userName?.split(" ")[0],
    //         userSurname: userName?.split(" ")[1],
    //         userEmail: email,
    //         userPassword: userPassword,
    //         userDateOfBirth: "1900-01-01",
    //         userAddressLine1: address1.trim() == "" ? "-" : address1,
    //         userAddressLine2: address2 == "" ? "-" : address2,
    //         userAddressCountryLocaleID: 77,
    //         userAddressPostcode: postcode == "" ? "-" : postcode,
    //         userRoleID: 1,
    //         userGender: 3,
    //         userLanguageID: 51,
    //         userAccountApproved: 1,
    //         userOptInMarketingPFW: 0,
    //         userMeta: {
    //           Website: "Park Run Forver",
    //           parkrunId: parkrunId ? parkrunId : "-",
    //           town: town ? town : "-",
    //           SubscriptionPeriod: period,
    //           parkrunGiftAid: parkrunGiftAid,
    //           parkrunEmailForPrizeConscent: parkrunEmailForPrizeConscent,
    //           parkrunPostForPrizeConscent: parkrunPostForPrizeConscent,
    //         },
    //       }
    //     );
    //   } catch (error) {
    //     // console.log(error);
    //   }
    // }

    try {
      console.log("create payment method form mode:", editMode);

      // ---------------------------------------Create Payment ------------------------------------------

      const cardElement = elements.getElement(CardElement);

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });
      if (error) {
        console.log("[error While Creating Element]", error);
        toast.error("[error While Creating Element] : " + error);
      } else {
        addPaymentInfo();
        const DrawData = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/sites/${process.env.REACT_APP_SITE_ID}/draws`
        );
        const res = await toast.promise(
          apiClient
            .post(
              `${process.env.REACT_APP_API_URL}/api/v1/transactions/stripe3ds/checkout`,
              {
                pageType: 'checkout',
                amount: amount,
                userEmail: email,
                name: userName,
                userPhoneNumber: userPhoneNumber,
                address1: address1.trim() == "" ? "-" : address1,
                address2: address2 != "" ? address2 : "-",
                postcode: postcode != "" ? postcode : "-",
                stripeToken: paymentMethod.id,
                transactionItems: [
                  {
                    drawID: DrawData.data.content[0].drawID,
                    drawCauseID: DrawData.data.content[0].drawHostCauseID,
                    ticketPrice: amount,
                    subscriptionPeriod: period,
                    ticketQuantity: 1,
                    isSubscription:
                      payType == "Monthly" || payType == "Donate" ? 1 : 0,
                    itemTotal: amount,
                  },
                ],
                transactionUserID: "",
                transactionSiteID: process.env.REACT_APP_SITE_ID,
                product: [
                  {
                    name: DrawData.data.content[0].drawName,
                    price: amount,
                    description:
                      DrawData.data.content[0].drawName + " Subscription",
                    metadata: {
                      userEmail: email,
                    },
                    data: [
                      {
                        id: 6,
                        name: DrawData.data.content[0].drawName,
                        drawID: DrawData.data.content[0].drawID,
                        drawCauseID: DrawData.data.content[0].drawHostCauseID,
                        price: amount,
                        quantity: 1,
                        slug: DrawData.data.content[0].drawPageURL,
                        subscription:
                          payType == "Subscribe" || payType == "Donate"
                            ? true
                            : false,
                        subscriptionPeriod: period,
                        itemTotal: amount,
                      },
                    ],
                  },
                ],
                editSubscription:
                  editMode == "edit"
                    ? {
                        editSubID: editSubID,
                        editTotal: editTotal,
                      }
                    : null,
              },
              {
                withCredentials: true,
                credentials: "same-origin",
              }
            )
            .catch((e) => {
              if (e.code === "ERR_BAD_REQUEST")
                console.log("Something went wrong", e);
              toast.error(e.response.data.message);
            }),
          {
            pending: "Please wait...",
            error: "Something went wrong",
          }
        );
        let transactionID = res.data.transactionID;

        if (res.data.actionRequired) {
          secureLocalStorage.setItem("LogObject", {
            userID: res.data.userID,
            email: email,
            loggedin: true,
          });
          const { paymentIntent, error } = await toast.promise(
            stripe.confirmCardPayment(res.data.clientSecret),
            {
              pending: "Please wait...",
              error: "3D Secure Failed",
            }
          );
          if (error) {
            await apiClient.post(
              `${process.env.REACT_APP_API_URL}/api/v1/transactions/stripe3ds/updateStatus`,
              {
                transactionID: error.payment_intent.id,
                status: 4,
              }
            );
            toast("Payment Failed.", { type: "error" });
            await timeout(2000);
          }
          if (paymentIntent.status === "succeeded") {
            console.log(paymentIntent);
            const res2 = await apiClient.post(
              `${process.env.REACT_APP_API_URL}/api/v1/transactions/stripe3ds/updateStatus`,
              {
                transactionID: paymentIntent?.id,
                sendMail: true,
                mailData: res.data.mailData,
                status: 3,
              }
            );
            console.log(res2);
            toast("Payment Done Successfully", { type: "success" });
          }
          const currency = 'GBP';
          const value = amount;
          purchase(currency, value);
          await timeout(2000);
          // if (payType == "Donate") {
          //   props.navigate("/account/my-donation-entries");
          // } else {
          //   props.navigate("/account/my-subscriptions");
          // }
          props.navigate(`/payment-success/${transactionID.toString()}`);
        } else {
          secureLocalStorage.setItem("LogObject", {
            userID: res.data.userID,
            email: email,
            loggedin: true,
          });
          toast("Payment Done Successfully", { type: "success" });
          const currency = 'GBP';
          const value = amount;
          purchase(currency, value);

          await timeout(2000);
          // if (payType == "Donate") {
          //   props.navigate("/account/my-donation-entries");
          // } else {
          //   props.navigate("/account/my-subscriptions");
          // }
          props.navigate(`/payment-success/${transactionID.toString()}`);
        }
      }
    } catch (error) {
      console.error(error);
      if (error.response?.data?.error?.validationErrors[0]) {
        return toast(
          error.response?.data?.error?.validationErrors[0]?.msg +
            "Please Login",
          { type: "error" }
        );
      }
      return toast("Payment failed!", { type: "error" });
    }
  };

  const stripe = useStripe();
  const elements = useElements();

  return (
    <>
      {stripe && stripe._keyMode === "live" ? (
        ""
      ) : (
        <div className="d-flex justify-content-center mb-2">
          <span
            className="text-white text-center bg-danger px-3 py-1"
            style={{ marginTop: "-25px" }}
          >
            Stripe : {stripe?._keyMode} Mode
          </span>
        </div>
      )}
      <div className="p-3 my-3 border rounded-4px-3 py-3 my-1 d-block w-100 bg-white">
        <CardElement
          options={{
            style: {
              base: {
                fontSize: "16px",
                color: "#424770",
                "::placeholder": {
                  color: "#aab7c4",
                },
              },
              invalid: {
                color: "#9e2146",
              },
            },
          }}
        />
      </div>
      <button
        className="d-block w-100 text-white text-decoration-none fs-6 font-regular site-button-primary py-3 px-5 text-center text-decoration-none rounded-3"
        onClick={handleSubmit(stripe, elements)}
      >
        {payType} Now{" "}
        <strong>
          {" "}
          £{amount} {editMode == "edit" ? "(" + (amount - editTotal) + ")" : ""}{" "}
        </strong>
      </button>
      <span className="text-muted text-center d-flex justify-content-center mt-3">
        * 100% Secure & Safe Payment *
      </span>
    </>
  );
};

export default StripeForm;
