import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import SiteRouter from "./site-router";
import { Provider } from "react-redux";
import { Store } from "./State/store";
import "react-toastify/dist/ReactToastify.css";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://1bdfb32a43217cf8bbf07244acfee285@o1326559.ingest.us.sentry.io/4508041129689088",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/parkrunforever\.com/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Provider store={Store}>
      <SiteRouter />
    </Provider>
  </React.StrictMode>
);
