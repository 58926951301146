import React from "react";
import axios from "axios";
let url = process.env.REACT_APP_API_URL;
// let url = "https://api.playfundwin.com";
let siteID = process.env.REACT_APP_SITE_ID
// let siteID = "146a712f-5df6-4056-a65b-e7dce24f7aa0";

let header = {
  withCredentials: true,
  credentials: "same-origin",
};

export function userLogin(requestBody) {
  return axios
    .post(url + "/auth/local", requestBody)
    .then((res) => res)
    .catch((err) => err);
}

export async function getUser(userID) {
  try {
    const res = await axios.get(url + `/api/v1/users/${userID}`, header);
    return res;
  } catch (err) {
    return err;
  }
}

export async function getSiteDraws() {
  try {
    const res = await axios.get(url + `/api/v1/sites/${siteID}/draws`, header);
    return res;
  } catch (err) {
    return err;
  }
}
