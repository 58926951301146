import React from 'react'
import { Link } from 'react-router-dom'
import SiteHeader from '../components/SiteHeader';
import Sitefooter from '../components/footer';
import RecdonationAccountBanner from '../components/recdonation-account-banner';
import RecdonationAccountSupport from '../components/recdonation-account-support';
import AccountPrizeslider from '../components/account-prize-slider';
import { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import secureLocalStorage from 'react-secure-storage'
import axios from 'axios';
import apiClient from '../api.config';
import useDocumentTitle from '../components/useDocumentTitle';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import EntriesSlider from '../components/entriesSlider';



export default function MyDonationEntries() {
    useDocumentTitle('My Donation Entries');
    const userDetail = secureLocalStorage.getItem('LogObject');
    
    const userData = userDetail;
    let siteID = process.env.REACT_APP_SITE_ID
    const [name, SetName] = useState('');
    const [isLoaded, SetisLoaded] = useState('');
    const navigate = useNavigate();
    const [PrizeData, setPrizeData] = useState([]);

    const [entryData, SetEntryData] = useState([]);
    const [allData, SetAllData] = useState([]);
    const [merge, Setmerge] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [modalShow2, setModalShow2] = useState(false);
    const [fullname, setFullName] = useState('');
    const [accountnumber, setAccountNumber] = useState('');
    const [sortcode, setSortCode] = useState('');
    const [paypalemail, setPaypalEmail] = useState('');
    const [bankaccount, setBankAccount] = useState(true);
    const [paypal, setPaypal] = useState();
    const [prizeID, setPrizeID] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [postcode, setPostcode] = useState('');
    const [userSurname, setUserSurname] = useState('');
    const [userFirstName, setUserFirstName] = useState('');
    const [prizeCodeModal,SetPrizeCodeModal]=useState(false);
    const [prizeCode,SetPrizeCode] =useState([]);
    const [prizeCodeInput,SetPrizeCodeInput] =useState('');
    const [inc,SetInc] = useState(1);

    useEffect(() => {
        if (userDetail === null) {
            navigate('/login');
        } else {
            axios.get(`${process.env.REACT_APP_API_URL}/api/v1/users/${userDetail.userID}/data/${siteID}`,
                {
                    withCredentials: true,
                    credentials: "same-origin"
                }
            ).then(res => {

                if (res.data.status && res.data.content.transactions) {
                    // res.data.content.transactions.transactionSiteID
                    SetName(res.data.content.userFirstName)

                    SetAllData(res.data.content)
                    SetEntryData(res.data.content.drawEntries);
                    setPrizeData(res.data.content.prizes && res.data.content.prizes.length > 0 ? res.data.content.prizes : []);

                    setAddress1(res.data.content.userAddressLine1)
                    setAddress2(res.data.content.userAddressLine2)
                    setPostcode(res.data.content.userAddressPostcode)
                    setPaypalEmail(res.data.content.userEmail)
                    // 
                    let entry = res.data.content.drawEntries;
                    // drawEntryID
                    // transactionDrawEntriesID
                    let myMerge = [];
                    myMerge = res.data.content.transactions
                        .filter(item => (item?.transactionSiteID == process.env.REACT_APP_DRAW_ID || item?.transactionSiteID == process.env.REACT_APP_SITE_ID) && item.transactionStatus == 3).map(item => {
                            let el = item;
                            el.DrawEntries = res.data.content.drawEntries.find(ent => ent.drawEntryID == el.transactionDrawEntriesID)
                            return el;
                        })
                    Setmerge(myMerge)


                }
            })
        }

        return () => {
            document.body.classList.remove('bg-branding-2')
        }
    }, [inc])


    return (
        <>
            <SiteHeader />

            <section className='bg-branding-2 font-face-sh font-regular' style={{ marginTop: '-110px', padding: '160px 0 20px' }}>
                <RecdonationAccountBanner name={name} />

                <nav className="account-nav-recdonation">
                    <div className="container">
                        <div className="d-flex flex-row justify-content-center flex-wrap">
                            <div className="p-2">
                                <Link to={'/account/my-donation-entries'} state={{ name: name, entrydata: entryData, allData: allData }} className="btn btn-100 px-2 py-0 border-0 active">My Entries</Link>
                            </div>
                            <div className="p-2">
                                <Link to={'/account/my-donation-plan'} state={{ name: name, entrydata: entryData, allData: allData }} className="btn btn-100 px-2 py-0 border-0 text-light">My Donation Plan</Link>
                            </div>
                            <div className="p-2">
                                <Link to={'/account/my-prizes-won'} state={{ name: name, entrydata: entryData, allData: allData }} className="btn btn-100 px-2 py-0 border-0 text-light">Prizes Won</Link>
                            </div>
                            <div className="p-2">
                                <Link to={'/account/my-subscriptions'} state={{ name: name, entrydata: entryData, allData: allData }} className="btn btn-100 px-2 py-0 border-0 text-light">My Subscriptions</Link>
                            </div>
                            <div className="p-2">
                                <Link to={'/account/my-payments'} state={{ name: name, entrydata: entryData, allData: allData }} className="btn btn-100 px-2 py-0 border-0 text-light">My Payments</Link>
                            </div>
                            <div className="p-2">
                                <Link to={'/account/my-donation-details'} state={{ name: name, entrydata: entryData, allData: allData }} className="btn btn-100 px-2 py-0 border-0 text-light">My Details</Link>
                            </div>
                        </div>
                    </div>
                </nav>

                <div className="page account pt-4">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 noSpaceLeftResp">

                                <div className="card mb-4 content-card your-entries" style={{ maxWidth: 500, margin: 'auto', borderRadius: 10 }}>
                                    <div className="card-header text-center bg-white px-md-5 px-4 pt-4 border-0">
                                        <h2 className="text-center fs-4 fw-6 mt-1 mb-3"><span>Thank You Draw Entries</span></h2>
                                        <EntriesSlider />
                                    </div>
                                    <div className="card-block text-center px-5 pb-3 mt-5">
                                        <p className="mb-3 fs-14 fw-semibold">Your entries will be issued once next draw opens.</p>

                                        <hr />
                                        <p className="mb-3 fs-14">Thank you for supporting parkrun forever!</p>
                                    </div>
                                </div>

                                <div className="card mb-4 content-card card-block text-center px-5 py-4" style={{ maxWidth: 500, margin: 'auto', borderRadius: 10 }}>
                                    <div id="entries-prizes">
                                        <h2 className="mb-3 fs-4 fw-6"><span>This month's prizes</span></h2>
                                        <hr />
                                        <div className="row">
                                            <div className="col-12 my-entries-carousel">
                                                <AccountPrizeslider />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <RecdonationAccountSupport />
            </section>
            <ToastContainer />

            <Sitefooter />
        </>
    )
}