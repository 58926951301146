import React from 'react'
import { Link } from 'react-router-dom'

import { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import secureLocalStorage from 'react-secure-storage'
import axios from 'axios';
import apiClient from '../api.config';
import useDocumentTitle from '../components/useDocumentTitle';
import Modal from 'react-bootstrap/Modal';

import { actionCreaters } from '../State';

import SiteHeader from '../components/SiteHeader';
import Sitefooter from '../components/footer';
import RecdonationAccountBanner from '../components/recdonation-account-banner';
import RecdonationAccountSupport from '../components/recdonation-account-support';

const header = { withCredentials: true, credentials: "same-origin" }

export default function MySubscriptions() {
    useDocumentTitle('My Subscriptions');
    const userDetail = secureLocalStorage.getItem('LogObject');
    const userData = userDetail;
    let siteId = process.env.REACT_APP_SITE_ID;
    let APIURL = process.env.REACT_APP_API_URL + "/api/v1";

    const [name, SetName] = useState('');
    const [isLoaded, setLoadComplete] = useState('');
    const navigate = useNavigate();

    const [entryData, SetEntryData] = useState([]);
    const [allData, SetAllData] = useState([]);

    let siteID = process.env.REACT_APP_SITE_ID
    const [merge, Setmerge] = useState([]);
    const [inc,SetInc] = useState(1);

    const [userSubscriptionList, SetUserSubscriptionList] = useState([]);
    const [subscriptioniChange, SetSubscriptionChange] = useState(1);
    const [modalCancelHost, SetModalCancelHost] = useState(false);
    const [modalCancelDraw, SetModalCancelDraw] = useState(false);
    const [modalEditDraw, SetModalEditDraw] = useState(false);
    const [editQuantity, SetEditQuantity] = useState(1);
    const [selectHost, SetSelectHost] = useState(null);
    const [selectDraw, SetSelectDraw] = useState(null);
    const [showDetails, SetShowDetails] = useState({});
    const [clickProcessBtn, SetClickProcessBtn] = useState(false);
    const [userSubsData, SetUserSubsData] = useState([]);

    const dispatch = useDispatch();
    const { sub_amount, sub_period, sub_mode, sub_o_amount, sub_o_sub_item_id } = useSelector(state => state.subscription);
    const { SetSubscriptionPeriod, SetSubscriptionMode, SetSubscriptionOAmount, SetSubscriptionOSubItemID } = bindActionCreators(actionCreaters, dispatch)

    useEffect(() => {
        if (userDetail === null) {
            navigate('/login');
        } else {
            axios.get(`${process.env.REACT_APP_API_URL}/api/v1/users/${userDetail.userID}/data/${siteID}`,
                {
                    withCredentials: true,
                    credentials: "same-origin"
                }
            ).then(res => {

                if (res.data.status && res.data.content.transactions) {
                    // res.data.content.transactions.transactionSiteID
                    SetName(res.data.content.userFirstName)

                    SetAllData(res.data.content)
                    SetEntryData(res.data.content.drawEntries);

                    // 
                    let entry = res.data.content.drawEntries;
                    // drawEntryID
                    // transactionDrawEntriesID
                    let myMerge = [];
                    myMerge = res.data.content.transactions
                        .filter(item => (item?.transactionSiteID == process.env.REACT_APP_DRAW_ID || item?.transactionSiteID == process.env.REACT_APP_SITE_ID) && item.transactionStatus == 3).map(item => {
                            let el = item;
                            el.DrawEntries = res.data.content.drawEntries.find(ent => ent.drawEntryID == el.transactionDrawEntriesID)
                            return el;
                        })
                    Setmerge(myMerge)
                    console.log("my merge", myMerge);

                }
            })
        }

        return () => {
            document.body.classList.remove('bg-branding-2')
        }
    }, [inc])

    //----------------------------- apis ---------------------------
    async function getUserSubscriptionList(userID) {
        try {
            const res = await axios.get(APIURL + `/users/${userID}/subscription`, header);
            console.log("getUserSubscriptionList", res);
            let host_list = [];

            if (Array.isArray(res.data?.content)) {

                let next_draw_list = [];
                for (let i = 0; i < res.data.content.length; i++) {
                    if (siteId != res.data.content[i].siteID) continue;
                    if (res.data.content[i].userSubscriptionStatusID == 10) {// SUBSCRIPTION_START_NEXT
                        next_draw_list.push(res.data.content[i]);
                    }
                }

                for (let i = 0; i < res.data.content.length; i++) {

                    if (siteId != res.data.content[i].siteID) continue;

                    let selected_host = null;
                    for (let iHost = 0; iHost < host_list.length; iHost++) {
                        if (host_list[iHost].hostID == res.data.content[i].userSubscriptionHostID) {
                            selected_host = host_list[iHost];
                            break;
                        }
                    }
                    if (selected_host == null) {
                        selected_host = {
                            hostName: res.data.content[i].hostName,
                            hostID: res.data.content[i].userSubscriptionHostID,
                            amount: 0,
                            subscriptionType: "Monthly",
                            active: [],
                            cancel: [],
                            other: [],
                        }
                        host_list.push(selected_host);
                    }
                    if (res.data.content[i].userSubscriptionStatusID == 3) {// SUBSCRIPTION_ACTIVE 
                        selected_host.active.push(res.data.content[i]);
                        selected_host.amount += res.data.content[i].userSubscriptionAmount;
                    } else if (res.data.content[i].userSubscriptionStatusID == 9) {
                        let is_exist_10 = 0;
                        for (let iNext = 0; iNext < next_draw_list.length; iNext++) {
                            if (next_draw_list[iNext].userSubscriptionStatusID == 10 &&
                                next_draw_list[iNext].userSubscriptionProcessorID == res.data.content[i].userSubscriptionProcessorID &&
                                next_draw_list[iNext].userSubscriptionProcessorItemID == res.data.content[i].userSubscriptionProcessorItemID
                            ) {
                                is_exist_10 = 1;
                                res.data.content[i]['nextAmount'] = next_draw_list[iNext]['userSubscriptionAmount'];
                                break;
                            }
                        }
                        selected_host.active.push(res.data.content[i]);
                        selected_host.amount += res.data.content[i].userSubscriptionAmount;
                    } else if (res.data.content[i].userSubscriptionStatusID == 6) { // SUBSCRIPTION_CANCELLED
                        selected_host.cancel.push(res.data.content[i]);
                    } else {
                        selected_host.other.push(res.data.content[i]);
                    }
                }
                return host_list;
            }
            return [];
        } catch (err) {
            console.log("result error:", err)
            return err;
        }
    }

    async function cancelUserSubscriptionDraw(body) { //userSubscriptionID
        try {
            const res = await apiClient.post(APIURL + `/transactions/stripe/cancelSubscriptionDraw`, body);
            return res;
        } catch (err) {
            console.log("result error:", err)
            return err;
        }
    }

    async function cancelUserSubscriptionHost(body) { //hostID, UserID
        try {
            const res = await apiClient.post(APIURL + `/transactions/stripe/cancelSubscriptionHost`, body);
            return res;
        } catch (err) {
            console.log("result error:", err)
            return err;
        }
    }

    async function editUserSubscription(body) { //quantity
        try {
            const res = await apiClient.post(APIURL + `/transactions/stripe/editSubscription`, body);
            return res;
        } catch (err) {
            console.log("result error:", err)
            return err;
        }
    }

    // ------------------------------ apis end -------------------------------

    function updateSubscription() {
        SetSubscriptionChange(subscriptioniChange + 1);
    }

    async function handleCancelHost() {
        let userID = userData.userID;
        let hostID = selectHost.hostID;
        SetClickProcessBtn(true);
        let res = await cancelUserSubscriptionHost({ userID, hostID });
        if (res.status) {
            toast.success(`Host Subscription successfully cancelled.`);
            updateSubscription();
            SetModalCancelHost(false);
        } else {
            if (res.response.status === 403) {
                toast.error(`${res.response.data.content.message}`);
            } else {
                toast.error(`${res.message}`);
            }
            SetModalCancelHost(false);
        }
        SetClickProcessBtn(false);
    }

    async function handleCancelDraw() {
        console.log("cancel draw subscription process ...");
        let userSubscriptionID = selectDraw.userSubscriptionID;
        let res = await cancelUserSubscriptionDraw({ userSubscriptionID });
        if (res.status && res.data?.content?.status == 200) {
            toast.success(`Draw subscription successfully cancelled.`);
            updateSubscription();
        } else {
            toast.error("Cancel Draw Subscription Failed.");
        }
        SetModalCancelDraw(false);
        SetClickProcessBtn(false);
    }

    async function handleEditDraw() {
        console.log("edit draw subscription process ...");
        SetClickProcessBtn(true)

        if (selectDraw.userSubscriptionQuantity === editQuantity) {
            toast.error(`Please enter the changed value.`);
            return;
        }

        if (editQuantity === "" || editQuantity < 1) {
            toast.error(`Please enter a valid value.`);
            return;
        }

        let res = await editUserSubscription({
            type: 2, //Draw Subscription
            userSubscriptionID: selectDraw.userSubscriptionID,
            newQuantity: editQuantity
        });
        console.log("edit draw res:", res);
        SetClickProcessBtn(false)
        if (res.status) {
            toast.success(`Draw subscription successfully updated.`);
            updateSubscription();
        } else {
            if (res.response.status === 403) {
                toast.error(`${res.response.data.content.message}`);
            } else {
                toast.error(`${res.message}`);
            }
        }
        SetModalEditDraw(false);
    }

    const handleQuantityChange = (event) => {
        let value = event.target.value;
        value = parseInt(value);
        if (isNaN(value)) {
            value = "";
        }
        SetEditQuantity(value);
    };

    function showDetailsHandler(hostID) {
        let cloneShowDetails = { ...showDetails };
        let key = 'key_' + hostID;
        if (cloneShowDetails[key] === false) {
            cloneShowDetails[key] = true;
        } else if (cloneShowDetails[key] === true) {
            cloneShowDetails[key] = false;
        } else {
            cloneShowDetails[key] = true;
        }
        SetShowDetails(cloneShowDetails);
    }

    useEffect(() => {
        if (!userData) {
            navigate('/login');
        } else {

            getUserSubscriptionList(userData.userID).then((res) => {
                if (Array.isArray(res)) {
                    SetUserSubscriptionList(res);
                    console.log("ressss",res);
                } else {
                    SetUserSubscriptionList([]);
                }
                setLoadComplete(true);
            });

        }

        return () => {
            document.body.classList.remove('bg-branding-2')
        }

    }, [subscriptioniChange]);

    useEffect(() => {
        if (userSubscriptionList[0] && merge) {
            console.log("userSubscriptionList", userSubscriptionList);
            console.log("merge", merge);
            const updatedUserSubscriptionList = userSubscriptionList[0].active.map((item2) => {
                const matchingMergeItem = merge.find((item) => item.transactionID === item2.userTransactionID);
    
                if (matchingMergeItem) {
                    return {
                        ...item2,
                        transactionTypeID: matchingMergeItem.transactionTypeID,
                    };
                }
    
                return item2;
            });
            SetUserSubsData(updatedUserSubscriptionList);
        }
    }, [userSubscriptionList, merge])
    
    console.log("SetUserSubsData", userSubsData);
    return (
        <>
            <SiteHeader />

            <section className='bg-branding-2 font-face-sh font-regular' style={{ marginTop: '-110px', padding: '160px 0 20px' }}>
                <RecdonationAccountBanner name={name} />

                <nav className="account-nav-recdonation">
                    <div className="container">
                        <div className="d-flex flex-row justify-content-center flex-wrap">
                            <div className="p-2">
                                <Link to={'/account/my-donation-entries'} state={{ name: name, entrydata: entryData, allData: allData }} className="btn btn-100 px-2 py-0 border-0 text-light">My Entries</Link>
                            </div>
                            <div className="p-2">
                                <Link to={'/account/my-donation-plan'} state={{ name: name, entrydata: entryData, allData: allData }} className="btn btn-100 px-2 py-0 border-0 text-light">My Donation Plan</Link>
                            </div>
                            <div className="p-2">
                                <Link to={'/account/my-prizes-won'} state={{ name: name, entrydata: entryData, allData: allData }} className="btn btn-100 px-2 py-0 border-0 text-light">Prizes Won</Link>
                            </div>
                            <div className="p-2">
                                <Link to={'/account/my-subscriptions'} state={{ name: name, entrydata: entryData, allData: allData }} className="btn btn-100 px-2 py-0 border-0 active">My Subscriptions</Link>
                            </div>
                            <div className="p-2">
                                <Link to={'/account/my-payments'} state={{ name: name, entrydata: entryData, allData: allData }} className="btn btn-100 px-2 py-0 border-0 text-light">My Payments</Link>
                            </div>
                            <div className="p-2">
                                <Link to={'/account/my-donation-details'} state={{ name: name, entrydata: entryData, allData: allData }} className="btn btn-100 px-2 py-0 border-0 text-light">My Details</Link>
                            </div>
                        </div>
                    </div>
                </nav>

                <div className="page account pt-4">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 noSpaceLeftResp">
                                <div className="card mb-4 content-card your-entries" style={{ maxWidth: 500, margin: 'auto', borderRadius: 10 }}>
                                    <div className="card-header bg-white px-1 pt-5 border-0">
                                        {isLoaded ?
                                            userSubscriptionList.reduce((totalActive, userSubscription) => totalActive + userSubscription.length, 0) === 0 ?
                                                <div className='mb-5'>
                                                    <p className='text-center fs-14 pb-10' style={{ color: '#302d51' }}><strong>There is no subscription. <br />Please</strong> <Link className='text-decoration-none' to={"/account/my-subscription-plan"} style={{ color: '#4b467d' }}>choose the subscription plan here</Link></p>
                                                </div>
                                                :
                                                <>
                                                    {
                                                        userSubscriptionList.map((row, index) => {
                                                            console.log("row", row)
                                                            return (
                                                                row.amount > 0 && <React.Fragment key={row.hostID}>
                                                                    <div className='user-subscription-section font-face-sh pb-5'>
                                                                        <div className='no-subscription-box bg-white rounded-3 p-4 mx-auto' style={{ maxWidth: 450, boxShadow: '0 4px 28px rgba(0,0,0,.08)' }}>
                                                                            <div className="row" style={{ borderRadius: '10px', backgroundColor: '#FFF', marginTop: '10px', padding: '10px' }}>
                                                                                <div className="col-12">
                                                                                    <label htmlFor="" style={{ 'fontSize': '18px', 'fontWeight': 'bold' }}> Host: {row.hostName} </label>
                                                                                </div>
                                                                                <div className="col-8 py-1">
                                                                                    <label htmlFor=""> <strong>Amount:</strong> {row.amount} </label>
                                                                                </div>
                                                                                {/* <div className="col-8 py-1">
                                                                                    <label htmlFor="">  Subscription Type: {row.subscriptionType} </label>
                                                                                    <label htmlFor="">  Subscription Type: {row.subscriptionType === 'Monthly' ? "Monthly" : "Annual"} </label>
                                                                                </div> */}
                                                                                <div className="col-4" style={{ justifyContent: 'space-around', display: 'flex' }}>
                                                                                    <input
                                                                                        className="btn pfw-button-green rounded-pill align-self-center px-1 py-1 fs-6 fw-bold border border-2 border-white rounded-3 site-button-primary bg-branding-1-hover text-white"
                                                                                        type="button"
                                                                                        name="submit"
                                                                                        value="Cancel"
                                                                                        onClick={() => {
                                                                                            SetSelectHost(row);
                                                                                            SetModalCancelHost(true);
                                                                                        }}
                                                                                        style={{ lineHeight: 1.5, width: '150px' }} />
                                                                                    {/* <input
                                                                className="btn pfw-button-green rounded-pill align-self-center px-1 py-1 fs-6 fw-bold"
                                                                type="button"
                                                                name="submit"
                                                                value="Edit"
                                                                onClick={() => {
                                                                }}
                                                                style={{ lineHeight: 1.5, width: '150px' }} /> */}
                                                                                </div>
                                                                                <div className="col-12 mb-2">
                                                                                    <button className='bg-branding-1 text-white rounded-3' style={{ border: 'none', padding: '10px' }}
                                                                                        onClick={() => {
                                                                                            showDetailsHandler(row.hostID)
                                                                                        }}>
                                                                                        {
                                                                                            showDetails['key_' + row.hostID] ?
                                                                                                <i className='fa fa-minus-square'></i> :
                                                                                                <i className='fa fa-plus-square'></i>
                                                                                        }  View details </button>
                                                                                </div>
                                                                                {
                                                                                    showDetails['key_' + row.hostID] &&
                                                                                    <div className="col-12">
                                                                                        <div style={{
                                                                                            padding: '30px 30px',
                                                                                            background: 'buttonface'
                                                                                        }}>
                                                                                            {
                                                                                                userSubsData.map((row2, index) => {
                                                                                                    return (
                                                                                                        <React.Fragment key={row2.userSubscriptionID}>
                                                                                                            <div className="row pb-4 mb-4" style={{ borderBottom: '3px solid #FFF' }}>
                                                                                                                <div className="col-12">
                                                                                                                    <div className="row">
                                                                                                                        <div className="col-12" style={{}}>
                                                                                                                        <strong>Draw Name:</strong> {row2.drawName}
                                                                                                                        </div>
                                                                                                                        <div className="col-12" style={{}}>
                                                                                                                        <strong>Price:</strong> £{
                                                                                                                                (row2.userSubscriptionStatusID == 9) ? (row2.userSubscriptionAmount + "( Next: £" + row2.nextAmount + " ) ") : row2.userSubscriptionAmount
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                        <div className="col-12">
                                                                                                                        <strong>Subscription Type:</strong> {row2.transactionTypeID === 3 ? "Monthly" : "Annual"}
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="col-12"
                                                                                                                    style={{ paddingTop: '20px', display: 'flex', justifyContent: 'space-around' }}>
                                                                                                                    <input
                                                                                                                        className="btn pfw-button-green rounded-pill align-self-center px-1 py-1 fs-6 fw-bold border border-2 border-white rounded-3 site-button-primary bg-branding-1-hover text-white"
                                                                                                                        type="button"
                                                                                                                        name="submit"
                                                                                                                        value="Cancel"
                                                                                                                        onClick={() => {
                                                                                                                            SetSelectHost(row);
                                                                                                                            SetSelectDraw(row2);
                                                                                                                            SetModalCancelDraw(true);
                                                                                                                        }}
                                                                                                                        style={{ lineHeight: 1.5, width: '150px', lineHeight: '10px' }} />
                                                                                                                    <input
                                                                                                                        className="btn pfw-button-green rounded-pill align-self-center px-1 py-1 fs-6 fw-bold border border-2 border-white rounded-3 bg-branding-1 bg-branding-1-hover text-white"
                                                                                                                        type="button"
                                                                                                                        name="submit"
                                                                                                                        value="Edit"
                                                                                                                        onClick={() => {
                                                                                                                            console.log("set subscription o amount:", row2.userSubscriptionPrice);

                                                                                                                            SetSubscriptionMode("edit");
                                                                                                                            SetSubscriptionOAmount(row2.userSubscriptionPrice);
                                                                                                                            SetSubscriptionPeriod("Monthly");
                                                                                                                            SetSubscriptionOSubItemID(row2.userSubscriptionID);

                                                                                                                            // SetSelectHost(row);
                                                                                                                            // SetSelectDraw(row2);
                                                                                                                            // SetEditQuantity(row2.userSubscriptionQuantity);
                                                                                                                            // SetModalEditDraw(true);
                                                                                                                            // AmountVar.d_mode = "edit";
                                                                                                                            // AmountVar.d_o_price = row2.userSubscriptionPrice;
                                                                                                                            // AmountVar.d_o_period = "Monthly";
                                                                                                                            // localStorage.setItem("AmountvarDMode", "edit");
                                                                                                                            // localStorage.setItem("AmountvarDOPeriod", "Monthly");
                                                                                                                            // localStorage.setItem("AmountvarDOAmount", row2.userSubscriptionPrice);
                                                                                                                            // localStorage.setItem("AmountvarDOSubID", row2.userSubscriptionID);
                                                                                                                            navigate('/account/my-subscription-plan');
                                                                                                                        }}
                                                                                                                        style={{ lineHeight: 1.5, width: '150px', lineHeight: '10px' }} />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </React.Fragment>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </div>
                                                                                    </div>

                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </React.Fragment>
                                                            );
                                                        })
                                                    }
                                                </>
                                            :
                                            <div className='user-subscription-section font-face-sh pb-5'>
                                                <div className='no-subscription-box bg-white rounded-3 p-4 mx-auto' style={{ maxWidth: 327, boxShadow: '0 4px 28px rgba(0,0,0,.08)' }}>
                                                    <p className='text-center fs-14 m-0' style={{ color: '#302d51' }}><strong>Loading...</strong></p>
                                                </div>
                                            </div>
                                        }

                                        <div className='mx-auto text-center pb-5 mt-2' style={{ maxWidth: 327 }}>
                                            <Link to={"/account/my-subscription-plan"} onClick={() => {
                                                SetSubscriptionMode("new");
                                                SetSubscriptionPeriod("Monthly");
                                                SetSubscriptionOAmount(0);
                                                SetSubscriptionOSubItemID("");
                                            }} className='py-3 px-5 border border-2 border-white rounded-pill bg-branding-1 text-white text-decoration-none fs-6 lh-1 font-face-sh fw-bold'>Enter The Draw</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <RecdonationAccountSupport />
            </section>
            {
                selectHost && <Modal show={modalCancelHost} onHide={() => SetModalCancelHost(false)} size="lg">
                    <Modal.Header closeButton style={{ position: 'relative' }}>
                        <Modal.Title>
                            <div className="px-3"> Cancel Host </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="px-3">
                            <div className="row">
                                <h5>
                                    Are you sure you want to cancel this host subscription?
                                </h5>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="px-3">
                            <div className="row">
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row-reverse',
                                }}>
                                    <div className="row" style={{ flexDirection: 'row-reverse' }}>
                                        <div style={{ width: '150px' }}>
                                            <input
                                                className="btn pfw-button-green rounded-pill align-self-center px-2 py-2 fs-6 fw-bold"
                                                type="button"
                                                name="submit"
                                                value="Yes"
                                                disabled={clickProcessBtn}
                                                onClick={() => {
                                                    handleCancelHost();
                                                }}
                                                style={{ lineHeight: 2, width: '120px' }} />
                                        </div>
                                        <div style={{ width: '150px' }}>
                                            <input
                                                className="btn pfw-button-green rounded-pill align-self-center px-2 py-2 fs-6 fw-bold"
                                                type="button"
                                                name="submit"
                                                value="Cancel"
                                                onClick={() => { SetModalCancelHost(false) }}
                                                style={{ lineHeight: 2, width: '120px' }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>
            }

            {
                selectHost && selectDraw && <Modal show={modalCancelDraw} onHide={() => SetModalCancelDraw(false)} size="lg">
                    <Modal.Header closeButton style={{ position: 'relative' }}>
                        <Modal.Title>
                            <div className="px-3"> Cancel Draw </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="px-3">
                            <div className="row">
                                <h5>
                                    Are you sure you want to cancel this draw subscription?
                                </h5>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="px-3">
                            <div className="row">
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row-reverse',
                                }}>
                                    <div className="row" style={{ flexDirection: 'row-reverse' }}>
                                        <div style={{ width: '150px' }}>
                                            <input
                                                className="btn pfw-button-green rounded-pill align-self-center px-2 py-2 fs-6 fw-bold"
                                                type="button"
                                                name="submit"
                                                value="Yes"
                                                disabled={clickProcessBtn}
                                                onClick={() => {
                                                    handleCancelDraw();
                                                }}
                                                style={{ lineHeight: 2, width: '120px' }} />
                                        </div>
                                        <div style={{ width: '150px' }}>
                                            <input
                                                className="btn pfw-button-green rounded-pill align-self-center px-2 py-2 fs-6 fw-bold"
                                                type="button"
                                                name="submit"
                                                value="Cancel"
                                                onClick={() => { SetModalCancelDraw(false) }}
                                                style={{ lineHeight: 2, width: '120px' }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>
            }

            {
                selectHost && selectDraw && <Modal show={modalEditDraw} onHide={() => SetModalEditDraw(false)} size="lg">
                    <Modal.Header closeButton style={{ position: 'relative' }}>
                        <Modal.Title>
                            <div className="px-3"> Edit Host/Draw </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="px-3">
                            <div className="row">
                                <div className="col-12">
                                    <label style={{ width: '100px', 'textAlign': 'right', 'paddingRight': '20px' }}>Price:</label> £ {selectDraw.userSubscriptionPrice}
                                </div>
                                <div className="col-12 mt-5">
                                    <label style={{ width: '100px', 'textAlign': 'right', 'paddingRight': '20px' }}>Quantity:</label>
                                    <input
                                        value={editQuantity}
                                        onChange={handleQuantityChange}
                                        placeholder="quantity"
                                        maxLength={10}
                                        style={{ borderRadius: '5px', border: '1px solid #C9C9C9', padding: "5px 20px" }}
                                    />
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="px-3">
                            <div className="row">
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row-reverse',
                                }}>
                                    <div className="row" style={{ flexDirection: 'row-reverse' }}>
                                        <div style={{ width: '150px' }}>
                                            <input
                                                className="btn pfw-button-green rounded-pill align-self-center px-2 py-2 fs-6 fw-bold"
                                                type="button"
                                                name="submit"
                                                value="Yes"
                                                disabled={clickProcessBtn}
                                                onClick={() => {
                                                    handleEditDraw();
                                                }}
                                                style={{ lineHeight: 2, width: '120px' }} />
                                        </div>
                                        <div style={{ width: '150px' }}>
                                            <input
                                                className="btn pfw-button-green rounded-pill align-self-center px-2 py-2 fs-6 fw-bold"
                                                type="button"
                                                name="submit"
                                                value="Cancel"
                                                onClick={() => {
                                                    SetModalEditDraw(false)
                                                }}
                                                style={{ lineHeight: 2, width: '120px' }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>
            }

            <ToastContainer />

            <Sitefooter />
        </>
    )
}