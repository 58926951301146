import React from "react";
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Slider from "react-slick";

export default function EntriesSlider(props) {
  const userDetail = secureLocalStorage.getItem('LogObject');

  const userData = userDetail;
  let siteID = process.env.REACT_APP_SITE_ID
  const [name, SetName] = useState('');
  const [isLoaded, setLoadComplete] = useState('');
  const navigate = useNavigate();
  const [entryData, SetEntryData] = useState([]);
  const [allData, SetAllData] = useState([]);
  const [merge, Setmerge] = useState([]);
  const [inc, SetInc] = useState(1);

  var settings = {
    dots: props.dots ? true : true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    arrows: props.arrows ? true : true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true
        }
      },
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  useEffect(() => {
    if (userDetail === null) {
      navigate('/login');
    } else {
      axios.get(`${process.env.REACT_APP_API_URL}/api/v1/users/${userDetail.userID}/data/${siteID}`,
        {
          withCredentials: true,
          credentials: "same-origin"
        }
      ).then(res => {

        if (res.data.status && res.data.content.transactions) {
          SetName(res.data.content.userFirstName)

          SetAllData(res.data.content ? res.data.content : [])
          SetEntryData(res.data.content.drawEntries ? res.data.content.drawEntries : []);

          let entry = res.data.content.drawEntries;

          let myMerge = [];
          myMerge = res.data.content.transactions
            .filter(item => (item?.transactionSiteID == process.env.REACT_APP_DRAW_ID || item?.transactionSiteID == process.env.REACT_APP_SITE_ID) && item.transactionStatus == 3).map(item => {
              let el = item;
              el.DrawEntries = res.data.content.drawEntries.find(ent => ent.drawEntryID == el.transactionDrawEntriesID)
              return el;
            })
          Setmerge(myMerge ? myMerge : []);
        }
        setLoadComplete(true);
      })
    }

    return () => {
      document.body.classList.remove('bg-branding-2')
    }
  }, [inc])

  return (

    <Slider {...settings} >
      {isLoaded ?
        merge.length === 0 ?
          <div className="card mb-4 content-card your-entries" style={{ maxWidth: 500, margin: 'auto', borderRadius: 10 }}>
            <div className="card-header text-center bg-white px-md-5 px-2 pt-5 border-0">
              <h2 className="text-center fs-5 fw-6"><span>You don't have any donation Entries</span></h2>
              <hr />
              <div className='my-5'>
                <Link to={"/Support"} className='select-donate-btn text-white text-decoration-none fs-6 px-3 font-regular site-button-primary py-3 px-md-5 px-4 text-decoration-none rounded-pill'>Select Donation Plan</Link>
              </div>
            </div>
          </div>
          :
          merge.map((item, i) => {
            var hel = new Date(item.DrawEntries?.drawEntryValidToDate);
            var dd = String(hel.getDate()).padStart(2, '0');
            var mm = String(hel.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = hel.getFullYear();
            return (
              <div className="col-12" ey={item.id}>
                <div className="card content-card your-entries">
                  <div className="card-header text-center bg-white px-5 pt-4 pb-3 border-0">
                    <div className="entries-box mb-4 d-inline-block bg-branding-4" style={{ padding: '15px 40px', borderRadius: 20 }}>
                      <h2 className='display-1 fw-7 text-light lh-1 mb-0'><span>{item.DrawEntries?.drawEntries}</span></h2>
                      <p className='text-light mb-0 fs-6'>Entries</p>
                    </div>
                    <h3 className="text-center fs-6 fw-6"><span>Draw date: <span className="not-bold">{`${dd}/${mm}/${yyyy}`}</span></span></h3>
                  </div>
                </div>
              </div>
            )
          }) :
        <div className='user-subscription-section font-face-sh pb-5'>
          <div className='no-subscription-box bg-white rounded-3 p-4 mx-auto' style={{ maxWidth: 327, boxShadow: '0 4px 28px rgba(0,0,0,.08)' }}>
            <p className='text-center fs-14 m-0' style={{ color: '#302d51' }}><strong>Loading...</strong></p>
          </div>
        </div>
      }
    </Slider>
  )
}
