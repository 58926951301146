import { React, useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import SiteHeader from '../components/SiteHeader';
import Sitefooter from '../components/footer';
import RecdonationAccountBanner from '../components/recdonation-account-banner';
import { AmountVar } from '../components/global';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreaters } from '../State';
import axios from 'axios';
import apiClient from '../api.config';
import useDocumentTitle from '../components/useDocumentTitle';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import RecdonationAccountSupport from '../components/recdonation-account-support';


export default function MyDonationChange() {
    useDocumentTitle('Change My Donation');
    const location = useLocation()
    const params = useParams()
    const navigate = useNavigate()
    const [isActive, setActive] = useState(true);
    const [ActiveTab, setActiveTab] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);
    const [transactions, setTransactions] = useState();
    const [confirmCancel, setConfirmCancel] = useState(false);
    const [AmountText, setAmountText] = useState(6);
    const [transactionID, setTransactionID] = useState(location.state?.transactionID ? location.state.transactionID : params.transactionID);
    const period = useSelector(state => state.period);
    const amount = useSelector(state => state.amount);
    AmountVar.period = period;
    AmountVar.amount = amount;
    const dispatch = useDispatch();
    const { SetAmount, SetPeriod } = bindActionCreators(actionCreaters, dispatch)
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/api/v1/transactions/${transactionID}`,
            {
                withCredentials: true,
                credentials: "same-origin"
            }
        ).then(res => {
            setIsLoaded(true)
            let data = res.data.content.filter(i=>i.transactionStatus == 3)
            setTransactions(data);
            setAmountText(res.data.content[0]?.transactionAmount);
            SetAmount(res.data.content[0]?.transactionAmount)
            console.log(res.data.content[0])
        })
    }, [])



    const SetOnline = (e) => {
        e.preventDefault();
        setActiveTab(true);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const SetPost = (e) => {
        e.preventDefault();
        setActiveTab(false);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        console.log(period, amount);
    };

    const SetMonthly = (e) => {
        e.preventDefault();
        setActive(true);
        SetPeriod('Monthly');
        AmountVar.period = 'Monthly';
    };
    const SetAnually = (e) => {
        e.preventDefault();
        setActive(false);
        SetPeriod('Anually');
        AmountVar.period = 'Anually';
    };

    function handleAmount(amount) {
        setAmountText(amount);
        AmountVar.amount = amount;
        SetAmount(amount);
    }
    function hndleInputAmount(event) {
        AmountVar.amount = event.target.value;
        setAmountText(event.target.value)
        if (event.target.value < 3) {
            event.preventDefault();
            setAmountText(3)
            alert("select Amount minimum £3")
            return false;
        }
    }
    function timeout(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
      }

    async function cancelSubsHandler(transactionID) {
        try{
            
        const cancelSubscription =  await toast.promise(
            apiClient.post(`${process.env.REACT_APP_API_URL}/api/v1/transactions/stripe/cancelSubscription`,
            { "transactionID": transactionID },
            {
                withCredentials: true,
                credentials: "same-origin"
            }
        ).then(async res => {
            if(res.data.content.deleted.status == "canceled"){
                toast.success("Subscription Cancelled Successfully");
                await timeout(2000);
                navigate('/account/my-donation-entries');
            }
        }),
            {
                pending: "Please wait...",}
        );
        console.log("cancelSubscription",cancelSubscription);
        
    }catch(err){
        if(err?.response?.data?.error?.msg != ''){
            toast.error(err.response?.data.error.msg);
            await timeout(2000);
            navigate('/account/my-donation-entries');
        }else{
            console.log(err);
            toast.error("Something Went Wrong")
            await timeout(2000);
            navigate('/account/my-donation-entries');
        }
        console.log(err);
    }

    }
    console.log("transactions",transactions)

    return (
        <>
            <SiteHeader />
            {isLoaded ?
                <section className='bg-branding-2 font-face-sh font-regular' style={{ marginTop: '-110px', padding: '160px 0 20px' }}>
                    <RecdonationAccountBanner />

                    <nav className="account-nav-recdonation">
                        <div className="container">
                            <div className="d-flex flex-row justify-content-center flex-wrap">
                                <div className="p-2">
                                    <Link to={'/account/my-donation-entries'} className="btn btn-100 px-2 py-0 border-0 text-light">My Entries</Link>
                                </div>
                                <div className="p-2">
                                    <Link to={'/account/my-donation-plan'} className="btn btn-100 px-2 py-0 border-0 active">My Donation Plan</Link>
                                </div>
                                <div className="p-2">
                                    <Link to={'/account/my-subscriptions'} className="btn btn-100 px-2 py-0 border-0 text-light">My Subscriptions</Link>
                                </div>
                                <div className="p-2">
                                    <Link to={'/account/my-payments'} className="btn btn-100 px-2 py-0 border-0 text-light">My Payments</Link>
                                </div>
                                <div className="p-2">
                                    <Link to={'/account/my-donation-details'} className="btn btn-100 px-2 py-0 border-0 text-light">My Details</Link>
                                </div>
                            </div>
                        </div>
                    </nav>
                    {transactions.length >= 1 ?
                    !confirmCancel ?

                        <div className="page account pt-4 mb-4">
                            <div className="container">
                                <div className="row text-center justify-content-center">
                                    <div className='col-lg-6 col-md-9 col-12 text-center'>
                                        <div className='card border-0 bg-white p-4' style={{ maxWidth: 500, margin: 'auto', borderRadius: 10 }}>
                                            <div className={ActiveTab ? 'tab-1' : 'd-none'}>
                                                <h2 className='font-semibold fs-4' >Select Donation Plan</h2>

                                                <ul className="nav nav-pills ml-0 d-inline-flex mb-4 donation my-3" role="tablist">
                                                    <li className="nav-item">
                                                        <a onClick={SetMonthly} id="monthly-tab" className={isActive ? "py-2 px-3 nav-link active" : "py-2 px-3 nav-link"} data-toggle="pill" href="#monthly" role="tab" aria-selected="true">Monthly</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a onClick={SetAnually} id="annually-tab" className={isActive ? "py-2 px-3 nav-link" : "py-2 px-3 nav-link active"} data-toggle="pill" href="#annually" role="tab" aria-selected="false">Annually</a>
                                                    </li>
                                                </ul>
                                                <div className="tab-content text-center px-md-4">


                                                    <h2 id="big-number " className='branding-4 font-regular'><span><span className="pound-sign position-relative" style={{ bottom: 30 }}>£</span><span id="number" className='display-1'>{AmountText}</span></span></h2>
                                                    <div className="tab-pane active show" id="monthly" role="tabpanel"><p><strong>Paid Monthly</strong></p></div>
                                                    <div className={isActive ? "tab-pane" : "tab"} id="annually" role="tabpanel" aria-expanded="true"><p className="mb-0 "><strong>Per Month, Paid Annually</strong></p>
                                                        <p style={{ position: 'inherit', marginBottom: '-7px!important' }} className="annually-breakdown mb-0 hidden-xl-down branding-4">This means paying £<span>{AmountText * 12}</span> now, for the whole year.</p>
                                                    </div>
                                                    <fieldset id="selected-amount">
                                                        <div className="row mb-3 mt-4">
                                                            <div className="col-4 pr-1">
                                                                <button id="ticket_option_3" className="w-100 border-0 ticket_option py-2 fs-3 font-regular text-center" style={amount == '3' ? { backgroundColor: '#FFA300', color: '#fff' } : {}} onClick={() => handleAmount(3)}><span>£</span>3</button>
                                                            </div>
                                                            <div className="col-4 px-2">
                                                                <button id="ticket_option_6" className="w-100 border-0 ticket_option py-2 fs-3 font-regular text-center" style={amount == '6' ? { backgroundColor: '#FFA300', color: '#fff' } : {}} onClick={() => handleAmount(6)}><span>£</span>6</button>
                                                            </div>
                                                            <div className="col-4 pl-1">
                                                                <button id="ticket_option_12" className="w-100 border-0 ticket_option py-2 fs-3 font-regular text-center" style={amount == '12' ? { backgroundColor: '#FFA300', color: '#fff' } : {}} onClick={() => handleAmount(12)}><span>£</span>12</button>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-5 justify-content-end">
                                                            <div className="col-4 pr-2">
                                                                <button id="ticket_option_10" className="w-100 border-0 ticket_option py-2 fs-3 font-regular text-center" style={amount == '20' ? { backgroundColor: '#FFA300', color: '#fff' } : {}} onClick={() => handleAmount(20)}><span>£</span>20</button>
                                                            </div>
                                                            <div className="col-8 dark-grey pl-2 position-relative " id="custom-input">
                                                                <input onChange={hndleInputAmount} type="number" min='3' id="amount-input" className='w-100 ps-5 py-1 fs-2 font-regular' name="custom-amount" value={AmountText} />
                                                                <p className='position-absolute fs-4 font-regular' style={{ top: 13, left: 33 }}>£</p>
                                                            </div>
                                                            <div className="col-12 pl-3 form-errors error d-none">
                                                                <p className="error-msg text-start">Please enter an amount between 3 and 100,000</p>
                                                            </div>
                                                        </div>
                                                        <h5 className="text-start fw-7 fs-6 font-bold">Joining parkrun forever means:</h5>
                                                        <ul className="text-start mb-4 mt-3 plan-benefits">
                                                            <li>keeping parkrun free, for everyone, forever</li>
                                                            <li>free entry into our monthly Thank You Draw</li>
                                                            <li>receiving a parkrun Forever supporter pack</li>
                                                            <li>regular updates from parkrun HQ</li>
                                                        </ul>
                                                        <Link id="select-donation-button" className='d-block w-100 text-white text-center text-decoration-none fs-5 font-regular fw-normal site-button-primary py-3 px-5 rounded-3 mt-4 mb-4'>Change your plan</Link>
                                                        <hr />
                                                        <p className="text-center mt-4"><strong>I want to cancel my donation plan</strong></p>
                                                        {/* <Link id="select-donation-button" to={"/account/my-donation-cancel"} className='btn-cancel d-block w-100 text-center text-decoration-none fs-5 font-regular fw-normal px-5 rounded-3 mt-4'>Cancel Plan</Link> */}
                                                        <button id="select-donation-button" onClick={() => setConfirmCancel(true)} className='btn-cancel d-block w-100 text-center text-decoration-none fs-5 font-regular fw-normal px-5 rounded-3  pt-0 mt-4 '>Cancel Plan</button>
                                                    </fieldset>
                                                </div>
                                            </div>
                                            <div className={ActiveTab ? 'd-none' : 'px-md-4 tab-2'}>
                                                <h2 className='font-semibold fs-4' >Alternatively, enter for free without making a donation by clicking 'Post' below.</h2>
                                                <hr />
                                                <p className='font-light text-start'>While we encourage donations to support our mission, we understand that some people may not be able to donate.</p>
                                                <h2 className='font-semibold fs-4 py-3'>For 3 entries to the Thank You Draw</h2>
                                                <h3 className='font-regular fs-4 text-start'>Please post the following information:</h3>
                                                <div className="tab-content text-center px-md-4">
                                                    <ul className="text-start mb-4 mt-3 px-4 p-md-0 plan-benefits">
                                                        <li>First Name</li>
                                                        <li>Last Name</li>
                                                        <li>Email</li>
                                                        <li>parkrun ID</li>
                                                    </ul>
                                                </div>
                                                <p className='font-light text-start'>to:</p>
                                                <h3 className='font-regular fs-4 text-start'>PFW Holdings Ltd <br />Suite #300, 4 Blenheim Court,<br />Peppercorn Close,<br />Peterborough, PE1 2DU</h3>
                                                <hr />
                                                <p className='font-light'>Postal entries received after 30th of every month, will be entered into next month’s Thank You Draw.</p>
                                                <Link to={"/terms-conditions"} className=' fs-6 font-semibold py-3 px-5 text-dark  rounded-3'>Full terms and conditions</Link>
                                            </div>
                                            <div className="my-5 mb-2 px-md-4 font-regular">
                                                <p className='fs-14'>Alternatively, enter for free without making a donation by clicking 'Post' below.</p>
                                                <ul className="nav font-regular nav-pills ml-0 d-inline-flex w-75 w-md-50 w-sm-75 mb-4 online-post-tab">
                                                    <li className="nav-item w-50">
                                                        <a onClick={SetOnline} className={ActiveTab ? "nav-link  active" : "nav-link"} href="#online-donation">Online</a>
                                                    </li>
                                                    <li className="nav-item w-50">
                                                        <a onClick={SetPost} className={ActiveTab ? "nav-link " : "nav-link  active"} href="#post-donation">Post</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : <>
                            <div className="page account pt-4">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                                            <div className="card mb-4 content-card your-entries" style={{ maxWidth: 500, margin: 'auto', borderRadius: 10 }}>
                                                <div className="card-header text-center bg-white px-5 pt-5 border-0">
                                                    <h2 className="text-center fs-4 fw-6"><span>Cancel your donation plan</span></h2>
                                                    <hr />
                                                </div>
                                                <div className="card-block px-5 pb-4">
                                                    <p className="text-center pb-3 dark-grey fs-14">Please confirm you wish to cancel your donation.</p>
                                                    <div className="text-center mt-4">
                                                        <button name="engine_confirm[confirm]" onClick={() => setConfirmCancel(false)} className="btn btn-details fs-14 fw-7 me-1">Cancel</button>
                                                        <button id="engine_confirm_confirm" name="engine_confirm[confirm]" onClick={() => cancelSubsHandler(transactionID)} className="btn btn-details fs-14 fw-7 ms-1">Confirm</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>:<div className="card mb-4 content-card your-entries" style={{ maxWidth: 500, margin: 'auto', borderRadius: 10 }}>
                                        <div className="card-header text-center bg-white px-5 pt-5 border-0">
                                            <h2 className="text-center fs-4 fw-6"><span>You dont have any donation Entries</span></h2>
                                            <hr />
                                        </div>
                                    </div>}
                        <RecdonationAccountSupport />
                </section> : <></>}

            <Sitefooter />
            <ToastContainer/>
        </>
    )
}
