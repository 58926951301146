import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import PageNotFound from "./pages/404";
import About from "./pages/about";
import Prizes from "./pages/prizes";
import Login from "./pages/login";
import Support from "./pages/support";
import TermsConditions from "./pages/terms-conditions";
import SupportRegistration from "./pages/SupportRegistration";
import PrivacyPolicy from "./pages/privacy-policy";
import ScrollToTop from "./components/Scroll-to-top";
import MyDonationEntries from "./pages/my-donation-entries";
import MyDonationPlan from "./pages/my-donation-plan";
import MyDonationChange from "./pages/my-donation-change";
import MyDonationCancel from "./pages/my-donation-cancel";
import MyDonationDetails from "./pages/my-donation-details";
import MySubscriptions from "./pages/my-subscriptions";
import MySubscriptionPlan from "./pages/my-subscription-plan";
import MySubscriptionRegister from "./pages/my-subscription-register";
import MyPayments from "./pages/my-payments";
import { useEffect } from "react";
import ResetPassword from "./pages/reset-passwod";
import UserResetPassword from "./pages/user-reset-password";
import ThankYou from "./pages/thankyou";
import MyPrizesWon from "./pages/my-prizes-won";
function SiteRouter() {
  
  
  // useEffect(()=>{
  //   window.addEventListener('contextmenu', event=> event.preventDefault()); 
  //   window.onkeydown = function(e) { 
  //   if(e.keyCode == 123) { 
  //   return false; 
  //   } 
  //   if(e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)){ 
  //   return false; 
  //   } 
  //   if(e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)){ 
  //   return false; 
  //   } 
  //   if(e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)){ 
  //   return false; 
  //   } 
  //   } 
  // },[])
  
  return (
    <div className="SiteRoutes" >
      
  <BrowserRouter >
    <ScrollToTop />
    <Routes>
      <Route exact path="/" element={<Home/>} />
      <Route exact path="/about" element={<About/>} />
      <Route exact path="/prizes" element={<Prizes/>} />
      <Route exact path="/login" element={<Login/>} />
      <Route exact path="/reset-password" element={<ResetPassword/>} />
      <Route exact path="/password-reset" element={<ResetPassword/>} />
      <Route exact path="/support" element={<Support/>} />
      <Route exact path="/donation-plan" element={<Support/>} />
      <Route exact path="/terms-conditions" element={<TermsConditions/>} />
      <Route exact path="/privacy-policy" element={<PrivacyPolicy/>} />
      <Route exact path="/donation-registration" element={<SupportRegistration/>} />
      <Route exact path="/account/my-donation-entries" element={<MyDonationEntries/>} />
      <Route exact path="/account/my-prizes-won" element={<MyPrizesWon/>} />
      <Route exact path="/account/my-donation-plan" element={<MyDonationPlan/>} />
      <Route exact path="/account/my-donation-change" element={<MyDonationChange/>} />
      <Route exact path="/account/my-donation-change/:transactionID" element={<MyDonationChange/>} />
      <Route exact path="/account/my-donation-cancel" element={<MyDonationCancel/>} />
      <Route exact path="/account/my-donation-cancel/:transactionID" element={<MyDonationCancel/>} />
      <Route exact path="/account/my-donation-details" element={<MyDonationDetails/>} />
      <Route exact path="/account/my-subscriptions" element={<MySubscriptions/>} />
      <Route exact path="/account/my-subscription-plan" element={<MySubscriptionPlan/>} />
      <Route exact path="/account/my-subscription-register" element={<MySubscriptionRegister/>} />
      <Route exact path="/account/my-payments" element={<MyPayments/>} />
      <Route exact path="/user-reset-password" element={<UserResetPassword/>} />
      <Route exact path="/payment-success/:transactionID" element={<ThankYou/>} />
      <Route exact path="*" element={<PageNotFound/>} />
    </Routes>
  </BrowserRouter>
  
    </div>

  );
}

export default SiteRouter;
