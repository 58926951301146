import React from 'react'
import LogoWhite from '../images/logo-white.svg'
import plane from '../images/prize/plane-new.svg'
import Trophy from '../images/trophy.svg'
import { Link } from 'react-router-dom'
import Prizeslider from '../components/prize-slider'
import '../App.css'
import MyAccordion from '../components/Accordian'
import Testimonials from '../components/testimonials'
import BottomSlider from '../components/bottom-slider'
import SiteHeader from '../components/SiteHeader'
import Sitefooter from '../components/footer'
import secureLocalStorage from 'react-secure-storage';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import axios from 'axios';
import useDocumentTitle from '../components/useDocumentTitle'
import logoutIcon from '../images/logout.svg'

export default function PageNotFound() {
    useDocumentTitle('Page Not Found');

const [scroll, setScroll] = React.useState(false);
    const [Sidebar, setSidebar] = React.useState(false);
    const navigate = useNavigate()
    let userDetail = secureLocalStorage.getItem('LogObject');

    React.useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 10);
        });

    }, []);
    const scrollClassActive = 'transation-ease-in-out site-header font-face-sh py-4 position-sticky top-0 bg-branding-1 z-index-9';
    const scrollClassNotActive = "site-header font-face-sh py-4 transation-ease-in-out";
    function logout(e){
        secureLocalStorage.clear();
       e.preventDefault()
          console.log("Hello")
            axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/auth/logout`).then(res=>{

            secureLocalStorage.removeItem("LogObject");
            navigate('/')

            })  

      }

  return (
    
    <>
    <header className="transation-ease-in-out site-header font-face-sh py-4 position-sticky top-0 bg-branding-1 z-index-9">
                <div className='container'>
                    <div className='inner-header row d-flex align-items-center'>
                        <div className='logo col-sm-6 col-md-6 col-lg-6 col-6'>
                            <Link to={'/'}>
                                <img className='start-0' src={LogoWhite} alt="logo-white" style={{ width: 62 }} />
                            </Link>
                        </div>
                        <div className='desktop-menu col-md-6 d-flex justify-content-end align-items-center'>
                            <Link to={"/about"} className='text-white text-decoration-none fs-6 px-3 font-regular'>About</Link>
                            <Link to={"/prizes"} className='text-white text-decoration-none fs-6 px-3 font-regular '>Thank You Draw</Link>
                            {userDetail===null?<Link to={"/login"} className='text-white text-decoration-none fs-6 px-3 font-regular '>Login</Link>:""}
                            {userDetail===null? <Link to={"/Support"} className='text-white text-decoration-none fs-6 px-3 font-regular site-button-primary py-2 px-4 text-decoration-none rounded-pill'>Support</Link>:""}
                            <div className='dashboard-menu d-flex justify-content-end align-items-center'>
            {/* <Link to={"/login"} className='text-white text-decoration-none fs-6 font-face-sh-bold'>Login</Link>
            <Link to={"/signup"} className='py-3 px-5 border border-2 border-white rounded-pill text-white text-decoration-none fs-6 ms-4 lh-1 font-face-sh-bold'>Join Now</Link> */}
            {userDetail?<DropdownButton
              align="end"
              title="Account"
              id="dropdown-menu-align-end"
            >
              <Dropdown.Item eventKey="1" href="/account/my-donation-entries">My Impact</Dropdown.Item>
              <Dropdown.Item eventKey="2" href="/account/my-donation-plan">My Donation Plan</Dropdown.Item>
              <Dropdown.Item eventKey="3" href="/account/my-donation-details">My Details</Dropdown.Item>
              <Dropdown.Item eventKey="6" href="/account/my-payments">My Payments</Dropdown.Item>
            
              <Dropdown.Divider />
              <Dropdown.Item eventKey="5" onClick={(e)=>logout(e)}><img src={logoutIcon} className={'m-r-3'} style={{marginRight:"1rem"}}/>Log out</Dropdown.Item>
            </DropdownButton>:""}
          </div>
                        
                        </div>
                        <div className='col-sm-6 col-md-6 col-6 mobile-menu justify-content-end d-flex align-items-center d-none'>
                            <div className={Sidebar ? "MenuButton_rootActive" : "MenuButton_root"} onClick={() => setSidebar(!Sidebar)}>
                                <div className={Sidebar ? "MenuButton_firstLineActive" : "MenuButton_firstLine"}></div>
                                <div className={Sidebar ? "MenuButton_secondLineActive" : "MenuButton_secondLine"}></div>
                                <div className={Sidebar ? "MenuButton_thirdLineActive" : "MenuButton_thirdLine"}></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="mySidebar" className={Sidebar ? " top-padding-sidebar open sidebar bg-light h-100 end-0 position-fixed d-flex flex-column ease-in-out overflow-hidden pb-3 pt-0 px-4" : "sidebar h-100 w-0 end-0 bg-light position-fixed pt-2 ease-in-out overflow-hidden top-padding-sidebar"}  >
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12 colsm-12 p-2 py-3' style={{ borderBottom: "1px solid #00000010" }}>
                                <Link to={'/about'} className="font-light text-decoration-none branding-1">About</Link>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-12 colsm-12 p-2 py-3' style={{ borderBottom: "1px solid #00000010" }}>
                                <Link to={'/prizes'} className="font-light text-decoration-none branding-1">Thank You Draw</Link>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-12 colsm-12 p-2 py-3' style={{ borderBottom: "1px solid #00000010" }}>
                            {userDetail?<a className="font-light text-decoration-none branding-1" onClick={(e)=>logout(e)} href="/">Logout</a>:<Link to={'/login'}  className="font-light text-decoration-none branding-1">Login</Link>
                            }
                                
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-12 colsm-12 p-2 py-3' style={{ borderBottom: "1px solid #00000010" }}>
                                <Link to={'/support'} className="font-light text-decoration-none branding-1">Support</Link>
                            </div>
                        </div>

                    </div>
                </div>
            </header>
    
    <section className="w-100 bg-f2  p-5 position-relative notfound-page">
        <div className="container h-100 p-5 notfound-page">
          <div className="d-flex align-items-center justify-content-center h-100 ">
            <div className="d-flex flex-column w-75 row notfound">
              <div className='align-items-center justify-content-center text-center'>
                <img src={require('../images/404park.png')} style={{width : "100%"}}/>
                <h1 className='display-4 mb-5 font-face-sh-bold'>Ohh... Shoot, this isn't the right place</h1>
                <h5 className='color-grey'>This page you have navigated doesn't exist or has been moved. Our apologies</h5>
                <div className='pt-0'>
                  <Link to={'/'} className="btn btn-link btn bg-branding-1 text-decoration-none shadow-lg text-white border-0 notfound-button w-25 m-3 rounded-pill py-3 mt-5 lh-1">Home</Link>
                  <Link to={'/login'} className="btn btn-link btn bg-branding-1 text-decoration-none shadow-lg text-white border-0 notfound-button w-25 m-3 rounded-pill py-3 mt-5 lh-1">Login</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Sitefooter/>
      </>
  )
}
