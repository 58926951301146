export const SetAmount = (amount)=>{
    return (dispatch)=>{
        dispatch({
            type:'setAmount',
            payload:amount,
        })
    }
}

export const SetPeriod = (Period)=>{
    return (dispatch)=>{
        dispatch({
            type:'setPeriod',
            payload:Period,
        })
    }
}  

export const SetSubscriptionAmount = (SAmount)=>{
    return (dispatch)=>{
        dispatch({
            type:'setSubscriptionAmount',
            payload:SAmount,
        })
    }
}

export const SetSubscriptionPeriod = (SPeriod)=>{
    return (dispatch)=>{
        dispatch({
            type:'setSubscriptionPeriod',
            payload:SPeriod,
        })
    }
}  

export const SetSubscriptionMode = (SMode)=>{
    return (dispatch)=>{
        dispatch({
            type:'setSubscriptionMode',
            payload:SMode,
        })
    }
}  

export const SetSubscriptionOAmount = (SOAmount)=>{
    return (dispatch)=>{
        dispatch({
            type:'setSubscriptionOAmount',
            payload:SOAmount,
        })
    }
}  

export const SetSubscriptionOSubItemID = (SOSubItemID)=>{
    return (dispatch)=>{
        dispatch({
            type:'setSubscriptionOSubItemID',
            payload:SOSubItemID,
        })
    }
}
