import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import SiteHeader from '../components/SiteHeader';
import Sitefooter from '../components/footer';
import RecdonationAccountBanner from '../components/recdonation-account-banner';
import useDocumentTitle from '../components/useDocumentTitle';

export default function MyDonationCancel(props) {
    useDocumentTitle('Cancel My Donation');
    const location = useLocation()
    console.log(props);
    const [transactionData, SetTransactionData] = useState(location.state ? location.state : '-')
  return (
    <>
        <SiteHeader />

        <section className='bg-branding-2 font-face-sh font-regular' style={{ marginTop: '-110px', padding: '160px 0 20px'}}>
            <RecdonationAccountBanner/>

            <nav className="account-nav-recdonation">
                <div className="container">
                    <div className="d-flex flex-row justify-content-center flex-wrap">
                        <div className="p-2">
                            <Link to={'/account/my-donation-entries'} className="btn btn-100 px-2 py-0 border-0 text-light">My Entries</Link>
                        </div>
                        <div className="p-2">
                            <Link to={'/account/my-donation-plan'} className="btn btn-100 px-2 py-0 border-0 active">My Donation Plan</Link>
                        </div>
                        <div className="p-2">
                            <Link to={'/account/my-subscrptions'} className="btn btn-100 px-2 py-0 border-0 text-light">My Subscriptions</Link>
                        </div>
                        <div className="p-2">
                            <Link to={'/account/my-payments'} className="btn btn-100 px-2 py-0 border-0 text-light">My Payments</Link>
                        </div>
                        <div className="p-2">
                            <Link to={'/account/my-donation-details'} className="btn btn-100 px-2 py-0 border-0 text-light">My Details</Link>
                        </div>
                    </div>
                </div>
            </nav>

            <div className="page account pt-4">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                            <div className="card mb-4 content-card your-entries" style={{ maxWidth: 500, margin: 'auto', borderRadius: 10 }}>
                                <div className="card-header text-center bg-white px-5 pt-5 border-0">
                                    <h2 className="text-center fs-4 fw-6"><span>Cancel your donation plan</span></h2>
                                    <hr />
                                </div>
                                <div className="card-block px-5 pb-4">
                                    <p className="text-center pb-3 dark-grey fs-14">Please confirm you wish to cancel your donation.</p>
                                    <form action="/account/my-donation-cancel/" method="post" className="text-center mt-4">
                                        <button type="submit" id="engine_confirm_confirm" name="engine_confirm[confirm]" className="btn btn-details fs-14 fw-7 me-1">Confirm</button>
                                        <Link to={'/account/my-donation-change'} id name="engine_confirm[confirm]" className="btn btn-details fs-14 fw-7 ms-1">Cancel</Link>
                                        <div style={{ display: 'hidden' }}>
                                            <div style={{ display: 'none' }}>
                                                <div><button type="submit" id="engine_confirm_cancel" name="engine_confirm[cancel]" className="btn btn--grey">Cancel</button></div><div><button type="submit" id="engine_confirm_confirm" name="engine_confirm[confirm]" className="btn btn--green">Confirm</button></div><input type="hidden" id="engine_confirm__token" name="engine_confirm[_token]" defaultValue="Q0Zlja0Q3rvReTXucLU7S-Q6zNpFtp17z_VckMdFsyw" />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>

        <Sitefooter/>
    </>
  )
}
