import React from 'react'
import { Link } from 'react-router-dom'
import '../App.css'
import Sitefooter from '../components/footer';
import Prizeslider from '../components/prize-slider';
import SiteHeader from '../components/SiteHeader';
import Trophy from '../images/trophy.svg'
import useDocumentTitle from '../components/useDocumentTitle';


export default function Prizes() {
    useDocumentTitle('Prizes');

    return (
        <>   
            <SiteHeader/>
            <section className="hero-section herosec-img-prizes font-face-sh" style={{ marginTop: '-110px', padding: '170px 0 200px' }}>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 text-center '>
                            <h1 className='text-light font-semibold display-5 '>Thank You Draw</h1>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className='container'>
                    <div className='row align-items-center pt-5'>
                        <div className='col-md-8 p-0 m-0 mx-auto'>
                            <p className='font-light fs-6 text-center px-5'>
                            We believe in showing gratitude for acts of kindness. As a thank you for your support, we’ll automatically enter you into our new monthly Thank You Draw, where you will have the chance to win some great parkrun prizes.
                            </p>
                            <h2 className='font-semibold display-5 text-center py-5'>How it works</h2>
                        </div>
                    </div>
                    <div className='row pb-5 d-flex justify-content-center align-items-start text-center'>
                        <div className='col-md-3 col-sm-12 px-1 d-flex flex-column text-center justify-content-center'>
                            <div style={{height:80,width:80}} className="rounded-pill mx-auto bg-branding-1 d-flex justify-content-center text-center align-items-center"><p className='text-light text-center font-semibold display-4 p-0 m-0'>1</p></div>
                            <h2 className='fs-3 pt-4 font-semibold'>Join</h2>
                            <p className='fs-6 px-2 font-light lh-0'>Sign up to parkrun forever by creating an account online and setting up a plan from as little £3 a month, or enter for free by post.</p>
                        </div>
                        <div className='col-md-3 col-sm-12 px-1 d-flex flex-column text-center justify-content-center'>
                            <div style={{height:80,width:80}} className="rounded-pill mx-auto bg-branding-1 d-flex justify-content-center text-center align-items-center"><p className='text-light text-center font-semibold display-4 p-0 m-0'>2</p></div>
                            <h2 className='fs-3 pt-4 font-semibold'>Enter</h2>
                            <p className='fs-6 px-2 font-light lh-0'>Each month, you will automatically be entered into the Thank You Draw, regardless of your contribution. You can find your lucky numbers in your account.</p>
                        </div>
                        <div className='col-md-3 col-sm-12 px-1 d-flex flex-column text-center justify-content-center'>
                            <div style={{height:80,width:80}} className="rounded-pill mx-auto bg-branding-1 d-flex justify-content-center text-center align-items-center"><p className='text-light text-center font-semibold display-4 p-0 m-0'>3</p></div>
                            <h2 className='fs-3 pt-4 font-semibold'>Win</h2>
                            <p className='fs-6 px-2 font-light lh-0'>Winners will be automatically notified via email, telling them what they’ve won and how to claim.</p>
                        </div>

                        <div className='col-md-12 mx-auto my-4'>
                        <Link to={"/Support"} className='text-white text-decoration-none fs-6 px-3 font-regular site-button-primary py-3 px-5 text-decoration-none rounded-pill'>Support Now</Link>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='yellow-strip text-center w-100 m-0 p-2'>
                    <div className='row container mx-auto text-center align-items-center'>
                        <div className='col-12 p-3'>
                            <h3 className='font-semibold display-5 text-center text-light'>
                            <img className='px-4 hide_responsive' src={Trophy} />
                            What you could win!
                            <img className='px-4 hide_responsive' src={Trophy} />
                            </h3>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='container'>
                    <div className='row p-5'>
                        <div className='col-md-8 col-sm-12 px-md-5 text-center mx-auto'>
                            <p className='fs-6 font-light'>Every month there will be new prizes added to the Thank You draw. As well as the Grand Prize (awarded twice a year), there will also be a mix of partner giveaways and parkrun goodies up for grabs.</p>
                            {/* <h3 className='font-semibold'>This month prizes:</h3> */}
                        </div>
                    </div>
                    {/* <div className='row mb-5'>
                    <Prizeslider arrows='true' dots='true' />
                    </div> */}
                    <div className='row text-center'>
                    <div className='col-md-12 mx-auto my-5'>
                        <Link to={"/Support"} className='text-white text-decoration-none fs-6 px-3 font-regular site-button-primary py-3 px-5 text-decoration-none rounded-pill'>Support Now</Link>
                        </div>
                    </div>
                </div>

            </section>


            <Sitefooter/>

        </>
    )
}

