import React from "react";
import Slider from "react-slick";


export default function BottomSlider() {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        arrows:false,
        slidesToScroll: 1,
        initialSlide: 0,
    };
    return (

        <Slider {...settings}>
            <div className="row mx-4 mb-4 slider-container">
                <div className="px-md-5 px-4 text-left mx-auto position-relative">
                    <div className="bg-light px-md-5 py-3 rounded-4 responsive-margin">
                        <p className="px-3 font-regular top-text">“I began running at parkrun for health reasons, to help manage a Bipolar Disorder mental health condition. I’m 44 now and received my diagnosis of this condition when I was 21 parkrun means a huge amount to me in managing my mental wellbeing, improving my overall fitness and the chance it provided to meet a great group of people at parkruns… and to enjoy the whole experience!”</p>
                        <p className="px-3 font-semibold">Paul Chatterton</p>
                        <div className="bg-light rounded-pill position-absolute image-container" style={{top:80,left:0}}>
                            <img className="rounded-pill p-1" style={{width:100,height:100}} src={require('../images/testimonial/1.jpeg')} alt="" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mx-4 mb-4 slider-container">
                <div className="px-md-5 px-4 text-left mx-auto position-relative">
                    <div className="bg-light px-md-5 py-3 rounded-4 responsive-margin">
                        <p className="px-3 font-regular top-text">“Running and parkrun have helped me lose weight and be healthier, and it’s helped me make friends. It’s something that is always worth the 8:00am alarm every Saturday morning. parkrun provides you with that sense of community in a time where people don’t know their neighbours like they used to. Even now I always get the same rush of adrenaline just before parkrun, because I know there are all these people here doing the same thing. It leaves you feeling warm and excited.”</p>
                        <p className="px-3 font-semibold">Erin Turner-Walton</p>
                        <div className="bg-light rounded-pill position-absolute image-container" style={{top:80,left:0}}>
                            <img className="rounded-pill p-1" style={{width:100,height:100}} src={require('../images/testimonial/2.jpeg')} alt="" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mx-4 mb-4 slider-container">
                <div className="px-md-5 px-4 text-left mx-auto position-relative">
                    <div className="bg-light px-md-5 py-3 rounded-4 responsive-margin">
                        <p className="px-3 font-regular top-text">“My first parkrun took me 38 minutes, I was thrilled getting my results text, and I was hooked straight away.<br/><br/>I have now lost 11.5 stone and gone from a sedentary lifestyle to running my first full marathon – my life has totally changed thanks to parkrun.”</p>
                        <p className="px-3 font-semibold">Gail Tennant</p>
                        <div className="bg-light rounded-pill position-absolute image-container" style={{top:80,left:0}}>
                            <img className="rounded-pill p-1" style={{width:100,height:100}} src={require('../images/testimonial/3.jpeg')} alt="" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mx-4 mb-4 slider-container">
                <div className="px-md-5 px-4 text-left mx-auto position-relative">
                    <div className="bg-light px-md-5 py-3 rounded-4 responsive-margin">
                        <p className="px-3 font-regular top-text">“In the past I have struggled with alcohol issues and post-traumatic stress disorder.<br/><br/>I’m now a Run Director at parkrun, and running and volunteering have played a big part in my recovery and my fitness.”</p>
                        <p className="px-3 font-semibold">Ian Young</p>
                        <div className="bg-light rounded-pill position-absolute image-container" style={{top:80,left:0}}>
                            <img className="rounded-pill p-1" style={{width:100,height:100}} src={require('../images/testimonial/4.jpeg')} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            

        </Slider>

    )
}
