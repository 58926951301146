import React from 'react'
import { Link } from 'react-router-dom'
import SiteHeader from '../components/SiteHeader';
import Sitefooter from '../components/footer';
import RecdonationAccountBanner from '../components/recdonation-account-banner';
import RecdonationAccountSupport from '../components/recdonation-account-support';
import AccountPrizeslider from '../components/account-prize-slider';
import { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import secureLocalStorage from 'react-secure-storage'
import axios from 'axios';
import apiClient from '../api.config';
import useDocumentTitle from '../components/useDocumentTitle';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';



export default function MyPrizesWon() {
    useDocumentTitle('My Donation Entries');
    const userDetail = secureLocalStorage.getItem('LogObject');

    const userData = userDetail;
    let siteID = process.env.REACT_APP_SITE_ID
    const [name, SetName] = useState('');
    const [isLoaded, setLoadComplete] = useState('');
    const navigate = useNavigate();
    const [PrizeData, setPrizeData] = useState([]);

    const [entryData, SetEntryData] = useState([]);
    const [allData, SetAllData] = useState([]);
    const [merge, Setmerge] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [modalShow2, setModalShow2] = useState(false);
    const [fullname, setFullName] = useState('');
    const [accountnumber, setAccountNumber] = useState('');
    const [sortcode, setSortCode] = useState('');
    const [paypalemail, setPaypalEmail] = useState('');
    const [bankaccount, setBankAccount] = useState(true);
    const [paypal, setPaypal] = useState();
    const [prizeID, setPrizeID] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [postcode, setPostcode] = useState('');
    const [userSurname, setUserSurname] = useState('');
    const [userFirstName, setUserFirstName] = useState('');
    const [prizeCodeModal, SetPrizeCodeModal] = useState(false);
    const [prizeCode, SetPrizeCode] = useState([]);
    const [prizeCodeInput, SetPrizeCodeInput] = useState('');
    const [inc, SetInc] = useState(1);
    const [refresh, setRefresh] = useState(1);


    useEffect(() => {
        if (userDetail === null) {
            navigate('/login');
        } else {
            axios.get(`${process.env.REACT_APP_API_URL}/api/v1/users/${userDetail.userID}/data/${siteID}`,
                {
                    withCredentials: true,
                    credentials: "same-origin"
                }
            ).then(res => {

                if (res.data.status && res.data.content.transactions) {
                    SetName(res.data.content.userFirstName)

                    SetAllData(res.data.content)
                    SetEntryData(res.data.content.drawEntries);
                    setPrizeData(res.data.content.prizes ? res.data.content.prizes : []);

                    let myMerge = [];
                    myMerge = res.data.content.transactions
                        .filter(item => (item?.transactionSiteID == process.env.REACT_APP_DRAW_ID || item?.transactionSiteID == process.env.REACT_APP_SITE_ID) && item.transactionStatus == 3).map(item => {
                            let el = item;
                            el.DrawEntries = res.data.content.drawEntries.find(ent => ent.drawEntryID == el.transactionDrawEntriesID)
                            return el;
                        })
                    Setmerge(myMerge)
                }
                setLoadComplete(true);
            })
        }

        return () => {
            document.body.classList.remove('bg-branding-2')
        }
    }, [inc, refresh])


    async function ClaimPrize() {

        if (!bankaccount && paypalemail === '') {
            toast.error(`Please Enter Your Proper paypal email`);
            return;
        } else if ((bankaccount) && fullname === '' && accountnumber === '' && sortcode === '') {
            toast.error(`Please Enter Bank Details`);
        } else if ((bankaccount) && fullname === '') {
            toast.error(`Please Enter Full Name`);
        } else if ((bankaccount) && accountnumber === '') {
            toast.error(`Please Enter Account Number`);
        } else if ((bankaccount) && sortcode === '') {
            toast.error(`Please Enter Sortcode`);
        } else {
            try {
                const response = await toast.promise(apiClient.post(
                    `${process.env.REACT_APP_API_URL}/api/v1/prizes/claim`,
                    {
                        userID: userData.userID,
                        prize_id: prizeID,
                        PaymentMethod: bankaccount ? "bank" : "paypal",
                        full_name: fullname != '' ? fullname : "paypal",
                        Sortcode: sortcode != '' ? sortcode : "paypal",
                        account_no: accountnumber != '' ? accountnumber : "paypal",
                        userEmail: paypalemail != '' ? paypalemail : "bank",
                        detail_meta: { siteID: siteID },
                        pageType: "Cash prize"
                    },
                    {
                        withCredentials: true,
                        credentials: "same-origin"
                    }
                ),
                    {
                        pending: 'Please wait...',
                    }
                );

                if (response.data.status === true && response.data.error === null && response.data.content === 'null') {
                    setModalShow(false)
                    toast("Thank you for submitting, Your prize will be processed in the next 14 - 21 days", { type: "success" });
                } else if (response.data.status === true && response.data.content.message === "Already CLaimed") {
                    setModalShow(false)
                    toast("Prize already claimed!", { type: "info" });
                } else {
                    setModalShow(false)
                    toast("Something went wrong While ", { type: "error" });
                }
                setRefresh((prev) => prev + 1);
            } catch (error) {
                if (error?.response?.data?.error?.reason) {
                    toast.warn(error?.response?.data?.error?.reason);
                } else if (error.response.status === 403) {
                    toast.error(`${error.response.data?.content?.message}`);
                } else {
                    toast.error(`${error.message}`);
                }
                setRefresh((prev) => prev + 1);
            }
        }
    }

    async function ClaimPrize2() {

        if (address1 === null || address2 === null || postcode === null) {
            toast.error(`Please Enter Your full Address`);
            return;
        } else {

            try {
                const response = await toast.promise(apiClient.post(
                    `${process.env.REACT_APP_API_URL}/api/v1/prizes/claim`,
                    {
                        userID: userData.userID,
                        prize_id: prizeID,
                        PaymentMethod: "prize",
                        full_name: userFirstName + " " + userSurname,
                        Sortcode: "-",
                        account_no: "-",
                        userEmail: "-",
                        detail_meta: { siteID: siteID, address1: address1, address2: address2, postcode: postcode, email: paypalemail },
                        pageType: "Prize"
                    },
                    {
                        withCredentials: true,
                        credentials: "same-origin"
                    }
                ),
                    {
                        pending: 'Please wait...',
                    }
                );

                if (response.data.status === true && response.data.error === null && response.data.content === null) {
                    setModalShow2(false)
                    toast("Thank you for submitting, Your prize will be processed in the next 14 - 21 days", { type: "success" });
                } else if (response.data.status === true && response.data.content.message === "Already CLaimed") {
                    setModalShow2(false)
                    toast("Prize already claimed!", { type: "info" });
                } else {
                    setModalShow2(false)
                    toast("Something went wrong While ", { type: "error" });
                }
                setRefresh((prev) => prev + 1);
            } catch (error) {

                if (error?.response?.data?.content?.message) {
                    toast.error(`${error.response.data.content.message}`);
                } else {
                    toast.error(`${error.message}`);
                }
                setRefresh((prev) => prev + 1);
            }
        }
    }

    async function ClaimPrizePrizeCode() {

        let filter = prizeCode.filter(item => item.prizeCode == prizeCodeInput);
        if (filter.length > 0) {
            try {
                const response = await toast.promise(apiClient.post(
                    `${process.env.REACT_APP_API_URL}/api/v1/prizes/claim`,
                    {
                        userID: userData.userID,
                        prize_id: prizeID,
                        PaymentMethod: "prizecode",
                        full_name: userFirstName + " " + userSurname,
                        Sortcode: "-",
                        account_no: "-",
                        userEmail: paypalemail,
                        detail_meta: { siteID: siteID, address1: address1, address2: address2, postcode: postcode, email: paypalemail },
                        datameta: { siteID: siteID, address1: address1, address2: address2, postcode: postcode, email: paypalemail },
                        drawPrizeClaimedDate: new Date()
                    },
                    {
                        withCredentials: true,
                        credentials: "same-origin"
                    }
                ),
                    {
                        pending: 'Please wait...',
                    }
                );

                if (response.data.status === true && response.data.error === null && response.data.content === null) {
                    setModalShow2(false)
                    SetInc((prev) => prev + 1);
                    setModalShow(false);
                    toast("Thank you for submitting, Your prize will be processed in the next 14 - 21 days", { type: "success" });
                } else if (response.data.status === true && response.data.content.message === "Already CLaimed") {
                    setModalShow2(false)
                    setModalShow(false);
                    SetInc((prev) => prev + 1);
                    toast("Prize already claimed!", { type: "info" });
                } else {
                    setModalShow2(false)
                    setModalShow(false);
                    SetPrizeCodeModal(false);
                    SetInc((prev) => prev + 1);
                    toast("Something went wrong While ", { type: "error" });
                }
            } catch (error) {
                if (error.response.status === 403) {
                    toast.error(`${error.response.data.content.message}`);
                } else {
                    toast.error(`${error.message}`);
                }
            }

        } else {
            const resolveAfter3Sec = new Promise(resolve => setTimeout(function () {

                toast.dismiss();
                //toast.dismiss();
                toast.error('Prize Code is invalid')

            }, 1000));
            toast.promise(
                resolveAfter3Sec,
                {
                    pending: 'Please Wait ....',
                    success: '',
                    error: 'Prize Code is invalid'
                }
            )
        }
    }

    function modalClaimPrize(item) {

        axios.get(
            `${process.env.REACT_APP_API_URL}/api/v1/prizes/${item.prizeID}/prizecode`,
            {
                withCredentials: true,
                credentials: "same-orifgin"
            }
        ).then(res => {

            if (res.data.content.length > 0) {
                SetPrizeCode(res.data.content);
                SetPrizeCodeModal(true);
                setModalShow(true);
                setPrizeID(item.prizeID)

            }
            else if (item.prizeTypeID == 1 || item.prizeTypeID === 2) {

                setModalShow(true); setPrizeID(item.prizeID)
            } else {

                setModalShow2(true); setPrizeID(item.prizeID)
            }
        })
    }


    return (
        <>
            <SiteHeader />

            <section className='bg-branding-2 font-face-sh font-regular' style={{ marginTop: '-110px', padding: '160px 0 20px' }}>
                <RecdonationAccountBanner name={name} />

                <nav className="account-nav-recdonation">
                    <div className="container">
                        <div className="d-flex flex-row justify-content-center flex-wrap">
                            <div className="p-2">
                                <Link to={'/account/my-donation-entries'} state={{ name: name, entrydata: entryData, allData: allData }} className="btn btn-100 px-2 py-0 border-0 text-light">My Entries</Link>
                            </div>
                            <div className="p-2">
                                <Link to={'/account/my-donation-plan'} state={{ name: name, entrydata: entryData, allData: allData }} className="btn btn-100 px-2 py-0 border-0 text-light">My Donation Plan</Link>
                            </div>
                            <div className="p-2">
                                <Link to={'/account/my-prizes-won'} state={{ name: name, entrydata: entryData, allData: allData }} className="btn btn-100 px-2 py-0 border-0 active">Prizes Won</Link>
                            </div>
                            <div className="p-2">
                                <Link to={'/account/my-subscriptions'} state={{ name: name, entrydata: entryData, allData: allData }} className="btn btn-100 px-2 py-0 border-0 text-light">My Subscriptions</Link>
                            </div>
                            <div className="p-2">
                                <Link to={'/account/my-payments'} state={{ name: name, entrydata: entryData, allData: allData }} className="btn btn-100 px-2 py-0 border-0 text-light">My Payments</Link>
                            </div>
                            <div className="p-2">
                                <Link to={'/account/my-donation-details'} state={{ name: name, entrydata: entryData, allData: allData }} className="btn btn-100 px-2 py-0 border-0 text-light">My Details</Link>
                            </div>
                        </div>
                    </div>
                </nav>

                <div className="page account pt-4">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 noSpaceLeftResp">

                                <div className="card mb-4 content-card px-md-5 px-3 py-4" style={{ maxWidth: 500, margin: 'auto', borderRadius: 10 }}>
                                    <h2 className="mt-4 mb-4 fs-4 fw-6 text-center"><span>Prizes won</span></h2>

                                    {/* CLAIM PRIZE */}

                                    {isLoaded ?
                                        PrizeData.length === 0 ?
                                            <h2 className="text-center fs-5 fw-6"><span>You have no prizes to claim</span></h2>
                                            :
                                            PrizeData.map((item, index) => {

                                                return (
                                                    <div className='py-4 px-md-4 px-3 mb-3 border border-2 shadow-lg bg-white position-relative text-center' style={{ borderRadius: '20px' }}>
                                                        <h4 className="mb-4 fs-6 fw-6">{item.prizeName}</h4>
                                                        {
                                                            item.drawPrizeState === 1 ?
                                                                item.prizeTypeID == 1 || item.prizeTypeID === 2 ?
                                                                    <Button variant="primary" onClick={() => { modalClaimPrize(item) }} className='py-3 px-5 border border-2 border-white rounded-pill bg-branding-1 bg-branding-1-hover text-white text-decoration-none fs-6 font-face-sh' style={{ lineHeight: '10px' }} >
                                                                        Claim Prize
                                                                    </Button> :
                                                                    <Button variant="primary" onClick={() => { modalClaimPrize(item); }} className='py-3 px-5 border border-2 border-white rounded-pill bg-branding-1 bg-branding-1-hover text-white text-decoration-none fs-6 font-face-sh' style={{ lineHeight: '10px' }}>
                                                                        Claim Prize
                                                                    </Button>
                                                                : item.drawPrizeState === 2 ? <span className='bg-branding-1 text-white px-4 rounded-pill fs-6 fw-bold d-inline-block' style={{ padding: '0.6rem 0' }}>Prize Already Claimed</span>
                                                                    : item.drawPrizeState === 3 ? <span className='bg-branding-1 text-white px-4 rounded-pill fs-6 fw-bold d--inline-block' style={{ padding: '0.6rem 0' }}>Prize Already Dispatched</span> : ""
                                                        }
                                                    </div>
                                                )
                                            })
                                        :
                                        <div className='user-subscription-section font-face-sh pb-5'>
                                            <div className='no-subscription-box bg-white rounded-3 p-4 mx-auto' style={{ maxWidth: 327, boxShadow: '0 4px 28px rgba(0,0,0,.08)' }}>
                                                <p className='text-center fs-14 m-0' style={{ color: '#302d51' }}><strong>Loading...</strong></p>
                                            </div>
                                        </div>
                                    }

                                    <hr />
                                    <p className="mb-3 fs-14 text-center">Thank you for supporting parkrun forever!</p>
                                </div>

                                <div className="card mb-4 content-card card-block text-center px-5 py-4" style={{ maxWidth: 500, margin: 'auto', borderRadius: 10 }}>
                                    <div id="entries-prizes">
                                        <h2 className="mb-3 fs-4 fw-6"><span>This month's prizes</span></h2>
                                        <hr />
                                        <div className="row">
                                            <div className="col-12 my-entries-carousel">
                                                <AccountPrizeslider />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <RecdonationAccountSupport />
            </section>
            <Modal
                show={modalShow} onHide={() => setModalShow(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                {prizeCodeModal ? <>
                    <Modal.Header closeButton className='d-flex flex-column justify-content-start'>
                        <Modal.Title id="contained-modal-title-vcenter" className='w-100 text-left'>
                            Claim Prize
                        </Modal.Title>
                        <p className='w-100 text-left m-0'>Please Enter Prize Code recieved on registered email for claim prize</p>
                    </Modal.Header>
                    <Modal.Body>

                        <div className={'d-block'}>
                            <input type="text" id="accountnumber" onChange={(event) => SetPrizeCodeInput(event.target.value)} placeholder="Please Enter Prize Code" className="p-3 my-3 form-control" />
                            <button type="submit" id="btn-signup" onClick={ClaimPrizePrizeCode} className="button btn bg-branding-green border-0 w-100 fs-5 font-face-sh-bold rounded-3 py-3 mt-3 mb-2 text-light" style={{ backgroundColor: 'rgb(5, 179, 4)', lineHeight: '10px' }}>Submit</button>
                        </div>



                        <div className='trusted-secure text-center my-2'>
                            <img src={require('../images/padlock.png')} className="d-inline-block" style={{ width: 20, height: 'auto' }} />
                            <span className="d-inline-block fw-bold branding-2 ms-1">Trusted Secure</span>
                        </div>
                    </Modal.Body>
                </> : <>

                    <Modal.Header closeButton className='d-flex flex-column justify-content-start'>
                        <Modal.Title id="contained-modal-title-vcenter" className='w-100 text-left'>
                            Claim Prize
                        </Modal.Title>
                        <p className='w-100 text-left m-0'>Please fill the below bank details</p>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='claim-methods d-flex gap-2 mb-3'>
                            <Button onClick={() => { setBankAccount(true); setPaypal(false) }} className={bankaccount ? 'w-50 py-3 px-5 border border-2 border-white rounded-3 bg-branding-1 bg-branding-1-hover border border-branding-1 text-white text-decoration-none fs-6 font-face-sh' : 'w-50 py-3 px-5 border border-2 border-white rounded-3 border border-branding-1 bg-transparent branding-1 branding-1-hover text-decoration-none fs-6 font-face-sh'} style={{ lineHeight: '10px' }}>
                                Bank Account
                            </Button>
                            <Button onClick={() => { setPaypal(true); setBankAccount(false) }} className={paypal ? 'w-50 py-3 px-5 border border-2 border-white rounded-3 bg-branding-1 bg-branding-1-hover border border-branding-1 text-white text-decoration-none fs-6 font-face-sh' : 'w-50 py-3 px-5 border border-2 border-white rounded-3 border border-branding-1 bg-transparent branding-1 branding-1-hover text-decoration-none fs-6 font-face-sh'} style={{ lineHeight: '10px' }}>
                                PayPal
                            </Button>
                        </div>
                        <div className={bankaccount ? 'd-block' : 'd-none'}>
                            <input type="text" id="fullname" onChange={(event) => setFullName(event.target.value)} placeholder="Full Name" className="p-3 my-3 form-control" />
                            <input type="text" id="accountnumber" onChange={(event) => setAccountNumber(event.target.value)} placeholder="Account Number" className="p-3 my-3 form-control" />
                            <input type="text" id="sortcode" onChange={(event) => setSortCode(event.target.value)} placeholder="Sort Code" className="p-3 my-3 form-control" />

                            <button type="submit" id="btn-signup" onClick={ClaimPrize} className="button btn bg-branding-green border-0 w-100 fs-5 font-face-sh-bold rounded-3 py-3 mt-3 mb-2 text-light" style={{ backgroundColor: 'rgb(5, 179, 4)', lineHeight: '10px' }}>Submit</button>
                        </div>

                        <div className={paypal ? 'd-block' : 'd-none'}>
                            <input type="email" id="paypalemail" onChange={(event) => setPaypalEmail(event.target.value)} placeholder="Email Address" className="p-3 my-3 form-control" />

                            <button type="submit" id="btn-signup" onClick={ClaimPrize} className="button btn bg-branding-green border-0 w-100 fs-5 font-face-sh-bold rounded-3 py-3 mt-3 mb-2 text-light" style={{ backgroundColor: 'rgb(5, 179, 4)', lineHeight: '10px' }}>Submit</button>
                        </div>

                        <div className='trusted-secure text-center my-2'>
                            <img src={require('../images/padlock.png')} className="d-inline-block" style={{ width: 20, height: 'auto' }} />
                            <span className="d-inline-block fw-bold branding-2 ms-1">Trusted Secure</span>
                        </div>
                    </Modal.Body></>}

            </Modal>
            <Modal
                show={modalShow2} onHide={() => setModalShow2(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton className='d-flex flex-column justify-content-start'>
                    <Modal.Title id="contained-modal-title-vcenter" className='w-100 text-left'>
                        Claim Prize
                    </Modal.Title>
                    <p className='w-100 text-left m-0'>Please confirm your address below for delivery</p>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <input type="text" id="address1" onChange={(event) => setAddress1(event.target.value)} placeholder="Address Line 1" className="p-3 my-3 form-control" />
                        <input type="text" id="address2" onChange={(event) => setAddress2(event.target.value)} placeholder="Address Line 2" className="p-3 my-3 form-control" />
                        <input type="text" id="postcode" onChange={(event) => setPostcode(event.target.value)} placeholder="Postcode" className="p-3 my-3 form-control" />

                        <button type="submit" id="btn-signup" onClick={ClaimPrize2} className="button btn bg-branding-green border-0 w-100 fs-5 font-face-sh-bold rounded-3 py-3 mt-3 mb-2 text-light" style={{ backgroundColor: 'rgb(5, 179, 4)', lineHeight: '5px' }}>Submit</button>
                    </div>

                    <div className='trusted-secure text-center my-2'>
                        <img src={require('../images/padlock.png')} className="d-inline-block" style={{ width: 20, height: 'auto' }} />
                        <span className="d-inline-block fw-bold branding-2 ms-1">Trusted Secure</span>
                    </div>
                </Modal.Body>
            </Modal>
            <ToastContainer />

            <Sitefooter />
        </>
    )
}