import {combineReducers} from "redux";
import amountReducer from "./amountReducer";
import periodReducer from "./periodReducer";
import subscriptionRducer from "./subscriptionRducer";

const Reducers = combineReducers({
    amount : amountReducer,
    period : periodReducer,
    subscription: subscriptionRducer
})

export default Reducers;